import cookies from "vue-cookies";
import formatUtilModule from "@/utils/format-util.js";
import organizationConfigModule from "@/config/organization-config.js";

function initialState() {
  return {
    inlineBanner: true,
    appNavigationBanner: false,
    infoBanner: true,
    showDailyActivity: false,
    dailyImportSchedule: null,
    latestActivity: null,
    timeLeft: null,
    intervalId: null,
    intervalDuration: 1000,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setAppNavigationBanner(state, value) {
      state.appNavigationBanner = value;
    },
    setInlineBanner(state, value) {
      state.inlineBanner = value;
    },
    setInfoBanner(state, value) {
      state.infoBanner = value;
    },
    setShowDailyActivity(state, value) {
      state.showDailyActivity = value;
    },
    setDailyImportSchedule(state, value) {
      state.dailyImportSchedule = value;
    },
    setLatestActivity(state, value) {
      state.latestActivity = value;
    },
    setTimeLeft(state, value) {
      state.timeLeft = value;
    },
    setIntervalId(state, intervalId) {
      state.intervalId = intervalId;
    },
    clearInterval(state) {
      if (state.intervalId) {
        clearInterval(state.intervalId);
        state.intervalId = null;
      }
    },
    setIntervalDuration(state, intervalDuration) {
      state.intervalDuration = intervalDuration;
    },
  },
  actions: {
    async getDailyImportSchedule({ rootState, state, commit, rootGetters, dispatch }) {
      if (state.dailyImportSchedule != null || !organizationConfigModule.useBackgroundActivity(rootState.auth.user.organization)) {
        return;
      }
      const TOKEN = cookies.get("token");
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/BackgroundActivities/GetSchedulesForBackgroundActivity?";

      try {
        let response = await fetch(
          URL + new URLSearchParams({ activityType: "Daily_Import" }),
          {
            headers: {
              Authorization: TOKEN
            }
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (result && result.length > 0) {
            commit("setDailyImportSchedule", result[0]);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getLatestActivity({ commit, rootGetters, dispatch }) {
      const TOKEN = cookies.get("token");
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/BackgroundActivities/GetLatestBackgroundActivity";

      try {
        let response = await fetch(URL, {
          headers: {
            Authorization: TOKEN
          }
        });

        if (response.ok) {
          let result = await response.json();
          commit("setLatestActivity", result);
          if (result && result.status === "Active") {
            commit("setShowDailyActivity", true);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    startBackgroundActivityTimer({ rootState, commit, dispatch, state }) {
      if (!organizationConfigModule.useBackgroundActivity(rootState.auth.user.organization)) {
        return;
      }
      commit("clearInterval");

      // Create a function that sets the interval dynamically
      const updateInterval = () => {
        const intervalId = setInterval(async () => {
          await dispatch("getLatestActivity");
          dispatch("calculateTimeLeft");
          dispatch("adjustInterval"); // Check and adjust the interval if necessary
        }, state.intervalDuration);

        commit("setIntervalId", intervalId);
      };

      updateInterval(); // Start with the initial interval
    },
    adjustInterval({ commit, dispatch, state }) {
      if (state.timeLeft === null) {
        return;
      }
      if (state.timeLeft === "COMPLETED") {
        if (!formatUtilModule.finishedWithinLastXSeconds(state.latestActivity.finishedAt, 600) &&
          state.intervalDuration !== 7200000
        ) {
          commit("clearInterval");
          commit("setIntervalDuration", 7200000); // 2 hours
          dispatch("startBackgroundActivityTimer"); // Restart timer with new interval
        }
        return;
      }

      const totalMinutesLeft = state.timeLeft.hours * 60 + state.timeLeft.minutes;

      if (totalMinutesLeft <= 15 && state.intervalDuration !== 30000) {
        commit("clearInterval");
        commit("setIntervalDuration", 30000); // 30 seconds
        dispatch("startBackgroundActivityTimer"); // Restart timer with new interval
      } else if (totalMinutesLeft > 15 && totalMinutesLeft <= 120 && state.intervalDuration !== 60000) {
        commit("clearInterval");
        commit("setIntervalDuration", 60000); // 60 seconds
        dispatch("startBackgroundActivityTimer"); // Restart timer with new interval
      } else if (totalMinutesLeft > 120 && state.intervalDuration !== 3600000) {
        commit("clearInterval");
        commit("setIntervalDuration", 3600000); // 1 hour
        dispatch("startBackgroundActivityTimer"); // Restart timer with new interval
      }
    },
    calculateTimeLeft({ state, commit }) {
      if (!state.dailyImportSchedule) {
        return;
      }

      try {
        let currentTime = new Date();
        let currentYear = currentTime.getUTCFullYear();
        let currentMonth = currentTime.getUTCMonth();
        let currentDay = currentTime.getUTCDate();

        const options = { weekday: "long" }; // "long" gives the full name of the day
        let currentDayString = currentTime.toLocaleString("en-US", options);

        if (!state.dailyImportSchedule.daysOfWeek.includes(currentDayString)) {
          commit("setTimeLeft", "COMPLETED");
          return;
        }

        if (state.latestActivity && state.latestActivity.status === "Succeeded") { // Import completed today
          let finishedDate = new Date(state.latestActivity.finishedAt);
          let finishedYear = finishedDate.getUTCFullYear();
          let finishedMonth = finishedDate.getUTCMonth();
          let finishedDay = finishedDate.getUTCDate();
          if (
            currentYear == finishedYear &&
            currentMonth == finishedMonth &&
            currentDay == finishedDay
          ) {
            commit("setTimeLeft", "COMPLETED");
            return;
          }
        }

        let targetHours = state.dailyImportSchedule.defaultStartTime.hours;
        let targetMinutes = state.dailyImportSchedule.defaultStartTime.minutes;
        let targetSeconds = state.dailyImportSchedule.defaultStartTime.seconds;
        let targetTime = new Date(
          Date.UTC(
            currentTime.getUTCFullYear(),
            currentTime.getUTCMonth(),
            currentTime.getUTCDate(),
            targetHours,
            targetMinutes,
            targetSeconds,
            0
          )
        );

        let diff = targetTime - currentTime; // Difference in milliseconds
        if (diff < 0) {
          commit("setTimeLeft", {
            hours: 0,
            minutes: 0
          });
        } else {
          const hoursLeft = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutesLeft = Math.ceil((diff % (1000 * 60 * 60)) / (1000 * 60));
          //const secondsLeft = Math.floor((diff % (1000 * 60)) / 1000);

          commit("setTimeLeft", {
            hours: hoursLeft,
            minutes: minutesLeft
          });
        }
      } catch (err) {
        console.error("calculateTimeLeft error: ", err);
        commit("setTimeLeft", null);
      }
    },
    stopBackgroundActivityTimer({ commit }) {
      commit("clearInterval");
    },
  }
};
export default module;
