<template>
  <v-dialog v-model="show" persistent width="unset">
    <v-card :color="colorTheme.info" dark>
      <v-card-text class="text-center pt-2 mr-2">
        <div>You were logged in with different user in this tab</div>
        <div>Please reload</div>
      </v-card-text>
      <v-btn
        block
        :style="textColor"
        :color="colorTheme.buttonWhite"
        @click="reload"
      >
        RELOAD
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("auth", ["user", "userChanged"]),
    show: {
      get() {
        return this.userChanged;
      },
      set(newValue) {
        this.setUserChanged(newValue);
      }
    },
    textColor() {
      return {
        color: this.colorTheme.buttonText
      };
    }
  },
  watch: {
    show(val) {
      if (!this.$cookies.isKey("token")) {
        return;
      }
      if (val) {
        this.resetAllLoadings();
      }
    }
  },
  methods: {
    ...mapMutations("auth", ["setUserChanged"]),
    ...mapMutations("directoryNavigation", ["setNavigation"]),
    ...mapActions("loading", ["resetAllLoadings"]),
    reload() {
      this.setUserChanged(false);
      this.setNavigation([]);
      this.$router.go();
    }
  }
};
</script>
