import cookies from "vue-cookies";

function initialState() {
  return {
    billingEntity: {
      id: null,
      name: null
    },
    parentBillingEntity: null,

    billingDiscountTypes: [],
    billingAumBasisTypes: null,
    billingFeeTypes: [],
    billingTierTypes: [],
    billingBillingTypes: [], //not used here anymore
    selectedBillingFeeType: "",
    selectedFeeType: {
      value: "",
      displayName: ""
    },

    fees: [], //all fees
    visibleFees: [], //fees for selectedBillingFeeType
    inherited: false,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setBillingEntity(state, newEntity) {
      state.billingEntity = Object.assign({}, newEntity);
    },
    setParentBillingEntity(state, newEntity) {
      state.parentBillingEntity = Object.assign({}, newEntity);
    },

    setBillingDiscountTypes(state, billingDiscountTypes) {
      state.billingDiscountTypes = billingDiscountTypes;
    },
    setBillingAumBasisTypes(state, billingAumBasisTypes) {
      state.billingAumBasisTypes = billingAumBasisTypes;
    },
    setBillingFeeTypes(state, billingFeeTypes) {
      state.billingFeeTypes = billingFeeTypes.sort(function(a, b) {
        return a.displayName.localeCompare(b.displayName);
      });
    },
    setBillingTierTypes(state, billingTierTypes) {
      state.billingTierTypes = billingTierTypes;
    },
    setBillingBillingTypes(state, billingBillingTypes) {
      state.billingBillingTypes = billingBillingTypes;
    },
    setSelectedBillingFeeType(state, type) {
      state.selectedBillingFeeType = type;
    },
    setSelectedFeeType(state, value) {
      state.selectedFeeType = value;
    },

    setFees(state, newFees) {
      state.fees = newFees;
    },
    setVisibleFees(state, visibleFees) {
      state.visibleFees = visibleFees;
    },
    setInherited(state, value) {
      state.inherited = value;
    }
  },
  actions: {
    async goToInherited({ dispatch }, payload) {
      try {
        let entity = payload.entity;
        let hierarchies = payload.hierarchies;
        let changeHierarchy = await dispatch("switchHierarchy", hierarchies);
        if (changeHierarchy) {
          entity.switchHierarchy = true;
        }
        await dispatch("directoryNavigation/jumpToNavigationItem", entity, {
          root: true
        });
        await dispatch("feeSchedule/refreshSchedules", entity.id, {
          root: true
        });
        return true;
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async switchHierarchy({ rootState, commit }, hierarchies) {
      let hierarchy = null;
      let tag = null;
      let changeHierarchy = true;
      let currentHierarchy = rootState.hierarchyMode.hierarchyTag.val;
      let hierarchyTags = rootState.hierarchyMode.hierarchyTags;
      if (
        hierarchies !== null &&
        hierarchies !== undefined &&
        hierarchies.length > 0
      ) {
        hierarchies.forEach(h => {
          if (h === currentHierarchy) {
            changeHierarchy = false;
          }
        });
        if (changeHierarchy) {
          hierarchy = hierarchies[0];
          hierarchyTags.forEach(ht => {
            if (ht.val === hierarchy) {
              tag = ht;
            }
          });
          commit("hierarchyMode/setHierarchyTag", tag, { root: true });
        }
      } else {
        changeHierarchy = false;
      }
      return changeHierarchy;
    },
    async refreshSchedules({ state, commit, dispatch }, entityId) {
      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      try {
        let id;
        if (entityId === "root") {
          id = await dispatch("browseEntities/getRootEntityId", null, {
            root: true
          });
        } else {
          id = entityId;
        }
        await dispatch("loadOptions");
        const fee = state.billingFeeTypes;

        let len = fee.length;
        if (len > 0) {
          await dispatch("getBillingEntity", id);
          await dispatch("findEffectiveFeeSchedule", {
            id: id,
            feeType: null,
            completeList: true
          });
          commit("setSelectedFeeType", fee[0]);
          dispatch("selectBillingFeeType", { type: fee[0] });
          dispatch("feeTiers/selectFee", fee[0], { root: true });
          commit("exclusions/setResetExclusions", true, { root: true });
        } else {
          throw "No billing fee types";
        }
      } catch (err) {
        console.error("refreshSchedules error - " + err);
        commit("dialogs/setFeeSchedulesError", true, { root: true });
      } finally {
        dispatch("loading/endLoading", null, { root: true });
        finished = true;
      }
    },
    /*async computeDemoFeeForSchedule({ state, rootGetters }, payload) {
      const BASE_URL = rootGetters["endpoints/BaseUrl"]
      const URL = BASE_URL + "/api/v1/ComputeEngine/ComputeDemoFeeForSchedule?";
      let response = await fetch(url + new URLSearchParams({
        entityId: state.billingEntity.id,
        feeScheduleId: rootState.feeTiers.selectedFee.id,
        demoAUMBasisAmount: payload.amount
      }), {
        headers: {
          "Authorization": TOKEN
        }
      });
      let result = await response.json();
      return result;
    },*/
    selectBillingFeeType({ commit, state }, payload) {
      commit("setSelectedBillingFeeType", payload.type);
      //let visibleFees = state.fees.filter(fee => fee.billingFeeType === state.selectedBillingFeeType);
      //commit("setVisibleFees", visibleFees);
      var i;
      for (i = 0; i < state.fees.length; i++) {
        const type = state.billingFeeTypes.filter(
          fee => state.fees[i].billingFeeType == fee.value
        );
        if (type !== undefined || type !== null) {
          state.fees[i]["fee"] = type[0].displayName;
        }
      }
      commit("setVisibleFees", state.fees);
    },
    async findEffectiveFeeSchedule({ commit, rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/FindEffectiveFeeSchedule?";

      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      try {
        if (
          payload !== null &&
          payload !== undefined &&
          payload.id !== null &&
          payload.id !== "" &&
          payload.id !== undefined
        ) {
          let response = await fetch(
            URL +
              new URLSearchParams({
                entity: payload.id,
                feeType: payload.feeType == null ? "" : payload.feeType,
                completeList: payload.completeList == null ? "" : payload.completeList
              }),
            {
              headers: {
                Authorization: TOKEN
              }
            }
          );
          if (response.ok) {
            let result = await response.json();
            commit("setFees", result);
          } else {
            throw "Response error";
          }
        } else {
          throw "Invalid id";
        }
      } catch (err) {
        throw "findEffectiveFeeSchedule error - " + err;
      } finally {
        dispatch("loading/endLoading", null, { root: true });
        finished = true;
      }
    },
    async getBillingEntity({ commit, dispatch, rootGetters }, feeId) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/billingEntity/" + feeId;

      if (feeId !== null && feeId !== "" && feeId !== undefined) {
        let response = await fetch(URL, {
          headers: {
            Authorization: TOKEN
          }
        });
        if (response.ok) {
          let result = await response.json();
          commit("setBillingEntity", result);

          if (result.type == "SLEEVE") {
            let parent = await dispatch(
              "browseEntities/getEntityParent",
              feeId,
              { root: true }
            );
            if (parent) {
              commit("setParentBillingEntity", parent);
            }
          }
        } else {
          throw "getBillingEntity error!";
        }
      } else {
        throw "Invalid billing entity id";
      }
    },
    async loadOptions({ dispatch }) {
      await dispatch("getBillingDiscountType");
      await dispatch("getBillingAumBasisType");
      await dispatch("getBillingFeeTypes");
      await dispatch("getBillingTierTypes");
    },
    async getBillingDiscountType({ state, commit, rootGetters, dispatch }) {
      if (state.billingDiscountTypes.length > 0) {
        return;
      }
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/billingDiscountType";

      let response = await fetch(URL, {
        headers: {
          Authorization: TOKEN
        },
        method: "GET"
      });
      if (response.ok) {
        let result = await response.json();
        commit("setBillingDiscountTypes", result);
      } else {
        throw "getBillingDiscountType error!";
      }
    },
    async getBillingAumBasisType({ state, commit, rootGetters, dispatch }) {
      if (state.billingAumBasisTypes) {
        return;
      }
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/billingAumBasisType";

      let response = await fetch(URL, {
        headers: {
          Authorization: TOKEN
        },
        method: "GET"
      });
      if (response.ok) {
        let result = await response.json();
        commit("setBillingAumBasisTypes", result);
      } else {
        throw "getBillingAumBasisType error!";
      }
    },
    async getBillingFeeTypes({ state, commit, rootGetters, dispatch }) {
      if (state.billingFeeTypes.length > 0) {
        return;
      }
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/billingFeeType";

      let response = await fetch(URL, {
        headers: {
          Authorization: TOKEN
        },
        method: "GET"
      });
      if (response.ok) {
        let result = await response.json();
        commit("setBillingFeeTypes", result);
      } else {
        throw "getBillingFeeTypes error!";
      }
    },
    async getBillingTierTypes({ state, commit, rootGetters, dispatch }) {
      if (state.billingTierTypes.length > 0) {
        return;
      }
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/billingTierType";

      let response = await fetch(URL, {
        headers: {
          Authorization: TOKEN
        },
        method: "GET"
      });
      if (response.ok) {
        let result = await response.json();
        commit("setBillingTierTypes", result);
      } else {
        throw "getBillingTierTypes error!";
      }
    },
    async getBillingBillingTypes({ commit, rootGetters, dispatch }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/billingBillingType";

      let response = await fetch(URL, {
        headers: {
          Authorization: TOKEN
        },
        method: "GET"
      });
      let result = await response.json();
      commit("setBillingBillingTypes", result);
      return result;
    }
  }
};

export default module;
