function initialState() {
  return {
    hierarchyTags: [
      {
        tag: "Firm/Account/Sleeve",
        val: "ACCOUNT_FIRM",
        address: "account-firm"
      },
      {
        tag: "Manager/Model",
        val: "MODEL_MANAGER",
        address: "model-manager"
      },
      {
        tag: "Group/Account",
        val: "ACCOUNT_GROUP",
        address: "account-group"
      }
    ],
    hierarchyTag: {
      tag: "Firm/Account/Sleeve",
      val: "ACCOUNT_FIRM",
      address: "account-firm"
    }
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setHierarchyTag(state, tag) {
      state.hierarchyTag = tag;
    }
  },
  actions: {
    async setHierarchyTag({ state, commit, dispatch }, tag) {
      if (state.hierarchyTag.val != tag.val) {
        commit("databaseSearch/incrementDatabaseSearchComponentKey", null, {
          root: true
        });
        commit("setHierarchyTag", tag);
        await dispatch("browseEntities/getRootEntityId", null, {
          root: true
        });
      }
    }
  }
};
export default module;
