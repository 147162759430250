import router from "@/router";
import amplitude from "amplitude-js";
function initialState() {
  return {
    showInception: false,
    pagination: {
      page: 1,
      itemsPerPage: 25,
      sortBy: [],
      sortDesc: []
    },
    inceptionHeaders: [
      {
        text: "ID",
        value: "id",
        align: "center",
        sortable: false,
        selected: true,
      },
      {
        text: "Account Number",
        value: "name",
        selected: true,
      },
      {
        text: "Status",
        value: "status",
        selected: true,
      },
      {
        text: "Rep Code",
        value: "repCode",
        selected: true,
      },
      {
        text: "AUM",
        value: "aum",
        sortable: false,
        align: "end",
        selected: true,
      },
      {
        text: "AUM Date",
        value: "aumDate",
        sortable: false,
        selected: true,
      },
      {
        text: "Billable",
        value: "billableStatus",
        selected: true,
      },
      {
        text: "Fee Frequency",
        value: "billingType",
        selected: true,
      },
      {
        text: "Start Date",
        value: "billingStartDate",
        selected: true,
      },
      {
        text: "End Date",
        value: "billingEndDate",
        selected: true,
      },
      {
        text: "Paid By",
        value: "paidBy",
        selected: true,
      },
      {
        text: "Custodian",
        value: "custodian",
        selected: true,
      },
      {
        text: "Account Status",
        value: "accountStatus",
        selected: true,
      },
    ],
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setShowInception(state, value) {
      state.showInception = value;
    },
    setPagination(state, value) {
      state.pagination = value;
    },
    setPage(state, value) {
      let temp = state.pagination;
      temp.page = value;
      state.pagination = temp;
    },
    setPageSize(state, value) {
      let temp = state.pagination;
      temp.itemsPerPage = value;
      state.pagination = temp;
    },
    resetPagination(state) {
      state.pagination = initialState().pagination;
    },
    resetPage(state) {
      let temp = state.pagination;
      temp.page = initialState().pagination.page;
      state.pagination = temp;
    },
    resetPageSize(state) {
      let temp = state.pagination;
      temp.itemsPerPage = initialState().pagination.itemsPerPage;
      state.pagination = temp;
    },
  },
  actions: {
    showInceptionWizard({ commit, rootState }) {
      router.push({ name: "InceptionWizard" });
      commit("setShowInception", true);
      rootState.appNavigation.mainMenuActive = false;
      amplitude.getInstance().logEvent("Tab Opened [Inception]");
    },
    closeInceptionWizard({ commit }, newAddress) {
      let options = { root: true };
      commit("resetState", null);
      commit("inceptionAccounts/resetState", null, options);
      commit("inceptionInAdvanceAccounts/resetState", null, options);
      commit("inceptionRecords/resetState", null, options);
      commit("inceptionGroupPicker/resetState", null, options);
      commit("filters/resetInceptionFilters", null, options);
      commit("filters/resetQuickSearchInception", null, options);
      router.push({ name: newAddress });
    }
  }
};
export default module;
