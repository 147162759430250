import cookies from "vue-cookies";

function initialState() {
  return {
    abortController: null,
    hierarchyTreeAbortController: null,
    feeReviewAbortController: null,
    inceptionAbortController: null,
    terminationAbortController: null,
    missingAccountsAbortController: null,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setAbortController(state, value) {
      state.abortController = value;
    },
    cancelSearch(state) {
      if (state.abortController) {
        state.abortController.abort();
      }
    },
    setHierarchyTreeAbortController(state, value) {
      state.hierarchyTreeAbortController = value;
    },
    cancelHierarchyTreeSearch(state) {
      if (state.hierarchyTreeAbortController) {
        state.hierarchyTreeAbortController.abort();
      }
    },
    setFeeReviewAbortController(state, value) {
      state.feeReviewAbortController = value;
    },
    cancelFeeReviewSearch(state) {
      if (state.feeReviewAbortController) {
        state.feeReviewAbortController.abort();
      }
    },
    setInceptionAbortController(state, value) {
      state.inceptionAbortController = value;
    },
    cancelInceptionSearch(state) {
      if (state.inceptionAbortController) {
        state.inceptionAbortController.abort();
      }
    },
    setTerminationAbortController(state, value) {
      state.terminationAbortController = value;
    },
    cancelTerminationSearch(state) {
      if (state.terminationAbortController) {
        state.terminationAbortController.abort();
      }
    },
    setMissingAccountsAbortController(state, value) {
      state.missingAccountsAbortController = value;
    },
    cancelMissingAccountsSearch(state) {
      if (state.missingAccountsAbortController) {
        state.missingAccountsAbortController.abort();
      }
    },
  },
  actions: {
    async searchEntities({ state, commit, rootGetters, dispatch }, payload) {
      commit("cancelSearch");
      commit("setAbortController", new AbortController());
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/SearchEntities?";
      const signal = state.abortController.signal;

      let response = await fetch(
        URL +
          new URLSearchParams({
            filter: payload.filter,
            relationshipType: payload.hierarchyMode,
            root: true,
            oneLevelDown: true,
            twoLevelsDown: true,
            threeLevelsDown: true
          }),
        {
          headers: {
            Authorization: TOKEN
          },
          signal: signal
        }
      );
      if (response.ok) {
        let result = await response.json();
        return result;
      } else {
        return false;
      }
    },

    async searchEntitiesByListOfTypes({ state, commit, rootGetters, dispatch }, payload) {
      commit("cancelSearch");
      commit("setAbortController", new AbortController());

      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/SearchEntitiesByListOfTypes?";

      const signal = state.abortController.signal;
      let response = await fetch(
        URL +
          new URLSearchParams({
            filter: payload.filter
          }),
        {
          headers: {
            Authorization: TOKEN,
            "Content-Type": "application/json"
          },
          signal: signal,
          method: "PATCH",
          body: JSON.stringify({
            Types: payload.types
          })
        },
      );
      if (response.ok) {
        let result = await response.json();
        return result;
      } else {
        return false;
      }
    },

    // delete
    async searchAccounts({ state, commit, rootGetters, dispatch }, filter) {
      commit("cancelSearch");
      commit("setAbortController", new AbortController());
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/SearchAccounts?";
      const signal = state.abortController.signal;
      let response = await fetch(
        URL +
          new URLSearchParams({
            filter: filter
          }),
        {
          headers: {
            Authorization: TOKEN
          },
          signal: signal
        }
      );
      if (response.ok) {
        let result = await response.json();
        return result;
      } else {
        return false;
      }
    }
  }
};

export default module;
