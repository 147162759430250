function getCurrentPeriod() {
  let today = new Date();
  let currentPeriod =
    today.getFullYear() + "-" + String(today.getMonth() + 1).padStart(2, "0");
  return currentPeriod;
}

function getBillingPeriodIntFromString(period) {
  if (period) {
    let [year, month] = period.split("-");

    year = parseInt(year);
    month = parseInt(month);

    return year * 100 + month;
  }
}

function getBillingPeriodIntFromDate(date) {
  if (date) {
    let periodString = date.slice(0, 7);
    let periodInt = getBillingPeriodIntFromString(periodString);
    return periodInt;
  }
}

function getBillingRunStringFromInt(billingRunInt) {
  let year = parseInt(billingRunInt / 100);
  let month = billingRunInt % 100;
  let newYearMonth = new Date(year, month);
  let newChosenDate = newYearMonth.toISOString().slice(0, 7);
  return newChosenDate;
}

function getBillingRunStringPlusOneFromInt(billingRunInt) {
  let year = parseInt(billingRunInt / 100);
  let month = billingRunInt % 100;
  let newYearMonth = new Date(year, month);
  newYearMonth.setMonth(newYearMonth.getMonth(month) + 1);
  let newChosenDate = newYearMonth.toISOString().slice(0, 7);
  return newChosenDate;
}

function getPreviousRunInt(billingRun) {
  let year = parseInt(billingRun / 100);
  let month = billingRun % 100;
  let prevMonth = (month - 1) % 12;
  if (prevMonth === 0) {
    prevMonth = 12;
    year = year - 1;
  }
  return year * 100 + prevMonth;
}

function getNextRunInt(billingRun) {
  let year = parseInt(billingRun / 100);
  let month = billingRun % 100;
  let nextMonth = (month + 1) % 12;
  if (nextMonth === 0) {
    nextMonth = 12;
  } else if (nextMonth === 1) {
    year = year + 1;
  }
  return year * 100 + nextMonth;
}

function getPreviousRunIntFromString(currentPeriodString) {
  let currentPeriodInt = getBillingPeriodIntFromString(currentPeriodString);
  let previousPeriodInt = getPreviousRunInt(currentPeriodInt);
  return previousPeriodInt;
}

module.exports = {
  getCurrentPeriod,
  getBillingPeriodIntFromString,
  getBillingPeriodIntFromDate,
  getBillingRunStringFromInt,
  getBillingRunStringPlusOneFromInt,
  getPreviousRunInt,
  getNextRunInt,
  getPreviousRunIntFromString,
};
