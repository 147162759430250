function initialState() {
  return {
    selectedEntities: [],
    pagination: {
      page: 1,
      itemsPerPage: 25,
      sortBy: [],
      sortDesc: [],
    },
    allHeaders: [
      {
        text: "ID",
        value: "id",
        align: "center",
        sortable: false,
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Type",
        value: "type",
      },
      {
        text: "Title",
        value: "accountTitle",
      },
      {
        text: "Rep Code",
        value: "repCode",
      },
      {
        text: "Frequency",
        value: "billingType",
      },
      {
        text: "Start Date",
        value: "billingStartDate",
      },
      {
        text: "End Date",
        value: "billingEndDate",
      },
      {
        text: "Model ID",
        value: "modelId",
        align: "center",
        sortable: false,
      },
      {
        text: "Model Name",
        value: "modelName",
        sortable: false,
      },
      {
        text: "Manager ID",
        value: "managerId",
        align: "center",
        sortable: false,
      },
      {
        text: "Manager Name",
        value: "managerName",
      },
      {
        text: "Rep Code",
        value: "repCodeForSleeve",
      },
      {
        text: "Broker Acct",
        value: "brokerAcctForSleeve",
      },
      {
        text: "Firm",
        value: "firmForSleeve",
      },
      {
        text: "Type",
        value: "sleeveType",
      },
    ],
    headersBasedOnEntityType: [
      {
        typesWithRelationship: [
          { entityType: "DEFAULT", relType: "ACCOUNT_FIRM" },
          { entityType: "DEFAULT", relType: "ACCOUNT_GROUP" },
          { entityType: "ADVISORY_MANAGEMENT_FIRM", relType: "ACCOUNT_FIRM" },
          { entityType: "ADVISORY_FIRM" },
          { entityType: "AP_GROUP" },
          { entityType: "OFFICERANGE_GROUP" },
          { entityType: "ACCOUNT_GROUP" },
          { entityType: "GROUP" },
        ],
        headerValues: [
          "id",
          "name",
          "accountTitle",
          "repCode",
          "type",
          "billingType",
          "billingStartDate",
          "billingEndDate",
        ],
      },
      {
        typesWithRelationship: [{ entityType: "ACCOUNT" }],
        headerValues: [
          "id",
          "modelName",
          "sleeveType",
          "modelId",
          "managerName",
          "managerId",
        ],
      },
      {
        typesWithRelationship: [{ entityType: "MODEL" }],
        headerValues: [
          "id",
          "sleeveType",
          "repCodeForSleeve",
          "brokerAcctForSleeve",
          "firmForSleeve",
        ],
      },
      {
        typesWithRelationship: [
          { entityType: "DEFAULT", relType: "MODEL_MANAGER" },
          { entityType: "ADVISORY_MANAGEMENT_FIRM", relType: "MODEL_MANAGER" },
          { entityType: "MANAGEMENT_FIRM" },
        ],
        headerValues: ["id", "name", "type"],
      },
    ],
    selectedHeaders: []
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setSelectedEntities(state, value) {
      state.selectedEntities = value;
    },
    setPagination(state, value) {
      state.pagination = value;
    },
    setPage(state, value) {
      let temp = state.pagination;
      temp.page = value;
      state.pagination = temp;
    },
    setPageSize(state, value) {
      let temp = state.pagination;
      temp.itemsPerPage = value;
      state.pagination = temp;
    },
    resetPagination(state) {
      state.pagination = initialState().pagination;
    },
    resetPage(state) {
      let temp = state.pagination;
      temp.page = initialState().pagination.page;
      state.pagination = temp;
    },
    resetPageSize(state) {
      let temp = state.pagination;
      temp.itemsPerPage = initialState().pagination.itemsPerPage;
      state.pagination = temp;
    },
    setHeaders(state, value) {
      state.selectedHeaders = value;
    },
    resetHeaders(state) {
      state.selectedHeaders = [];
    }
  },
  actions: {
    resetAll({ commit, dispatch }) {
      commit("filters/resetHierarchyTreeFilters", null, { root: true });
      commit("filters/resetQuickSearchHierarchyTree", null, { root: true });
      commit("resetPagination");
      dispatch("resetSelected");
      commit("filters/closeHierarchyTreeFilters", null, { root: true });
    },
    resetSelected({ commit }) {
      commit("setSelectedEntities", []);
    },
    async setHeaders({ rootState, state, commit, dispatch }) {
      let numberOfChildren = rootState.browseEntities.numberOfChildren;
      if (numberOfChildren == 0) {
        commit("resetHeaders");
        return;
      }

      let relType = rootState.hierarchyMode.hierarchyTag.val;
      let parentEntity = rootState.browseEntities.parentEntity;
      let i = 0;
      while (i < state.headersBasedOnEntityType.length) {
        let headersWithTypes = state.headersBasedOnEntityType[i];
        let typesWithRelationship = headersWithTypes.typesWithRelationship;
        let j = 0;
        while (j < typesWithRelationship.length) {
          let entityType = typesWithRelationship[j].entityType;
          let relationshipType = typesWithRelationship[j].relType;
          if (
            entityType == parentEntity.type &&
            (!relationshipType || relationshipType == relType)
          ) {
            let selectedHeaders = await dispatch(
              "mapHeaderValuesToHeaders",
              headersWithTypes.headerValues
            );
            commit("setHeaders", selectedHeaders);
            return;
          }
          j++;
        }
        i++;
      }

      commit("resetHeaders");
    },
    async mapHeaderValuesToHeaders({ state }, headerValues) {
      let selectedHeaders = [];
      headerValues.forEach(headerValue => {
        let i = 0;
        let found = false;
        while (i < state.allHeaders.length && !found) {
          let header = state.allHeaders[i];
          if (headerValue == header.value) {
            selectedHeaders.push(header);
            found = true;
          }
          i++;
        }
      });
      return selectedHeaders;
    }
  }
};
export default module;
