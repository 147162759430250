<template>
  <v-row class="mr-2  mt-2 app-version_style">
    <p class="text-style mr-1">{{ text }}</p>
  </v-row>
</template>

<script>
import Version from "@/store/version.js";
export default {
  data: () => ({}),
  computed: {
    text() {
      return Version.releaseDateTime + " - Version " + Version.version;
    }
  }
};
</script>

<style scoped>
.text-style {
  font-size: 12px;
  color: #c3c5c5;
  font-weight: 600;
  margin-top: 2px;
}
.app-version_style {
  height: 100%;
  justify-content: end;
  align-items: flex-end;
}
</style>
