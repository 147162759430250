function initialState() {
  return {
    terminationAccountsForCalculation: [],
    terminationCalculationResults: [],
    terminationAccountsForReRun: [],
    totalTerminationCalculationResults: 0,
    defaultTimeout: 25000,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setTerminationAccountsForCalculation(state, value) {
      state.terminationAccountsForCalculation = value;
    },
    setTerminationCalculationResults(state, value) {
      value.forEach(element => {
        element.runTypeKey = element.accountId + element.billingRunType;
      });
      state.terminationCalculationResults = value;
    },
    setTerminationAccountsForReRun(state, value) {
      state.terminationAccountsForReRun = value;
    },
    setTotalTerminationCalculationResults(state, value) {
      state.totalTerminationCalculationResults = value;
    }
  },
  actions: {
    async getTerminationAccountRecords({  commit, dispatch, state }, payload) {
      let account = {
        id: payload.modifiedRecord.accountId,
        billingRunType: payload.modifiedRecord.billingRunType
      };

      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: [account],
          processType: "TerminationResume",
          page: 1,
          pageSize: 1,
        },
        { root: true }
      );

      if (result.responseData) {
        let newResults = [...state.terminationCalculationResults];
        result.responseData.forEach(item => {
          newResults.splice(
            newResults.findIndex(e => e.accountId === item.accountId),
            1,
            item
          );
        });
        commit("setTerminationCalculationResults", newResults);
        return true;
      } else {
        return false;
      }
    },
    async createAndViewTerminationBillingRecords({ rootState, commit, dispatch, state }, payload) {
      let filterOptions = await dispatch(
        "filters/getFilterOptions",
        {
          predicates: rootState.filters.terminationFilters,
          searchParam: null,
          quickSearchParam: rootState.filters.quickSearchTermination
        },
        { root: true }
      );
      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: state.terminationAccountsForCalculation,
          processType: "TerminationResume",
          page: payload.page,
          pageSize: payload.pageSize,
          all: payload.all,
          filterOptions: filterOptions,
          timeout: state.defaultTimeout
        },
        { root: true }
      );

      if (result.responseData) {
        commit("setTerminationCalculationResults", result.responseData);
        commit("setTotalTerminationCalculationResults", result.metaData.totalRecordCount);
      } else {
        commit("setTerminationCalculationResults", []);
        commit("setTotalTerminationCalculationResults", 0);
      }
      return result;
    },
    async getAllTerminationBillingRecordsPaged({ dispatch }, payload) {
      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: payload.accounts,
          processType: "TerminationResume",
          page: payload.page,
          pageSize: payload.pageSize
        },
        { root: true }
      );

      return result;
    },
    async reRunTermination({ rootState, commit, dispatch, state }, payload) {
      let filterOptions = await dispatch(
        "filters/getFilterOptions",
        {
          predicates: rootState.filters.terminationFilters,
          searchParam: null,
          quickSearchParam: rootState.filters.quickSearchTermination
        },
        { root: true }
      );

      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: payload.accounts,
          processType: "TerminationStartOver",
          page: payload.page,
          pageSize: payload.pageSize,
          all: payload.all,
          filterOptions: filterOptions,
          timeout: state.defaultTimeout
        },
        { root: true }
      );

      if (result.responseData) {
        let newResults = [...state.terminationCalculationResults];
        result.responseData.forEach(item => {
          newResults.splice(
            newResults.findIndex(e => e.accountId === item.accountId),
            1,
            item
          );
        });
        commit("setTerminationCalculationResults", newResults);
        commit("setTotalTerminationCalculationResults", newResults.length);
      } else {
        await dispatch(
          "billingRecordsCalculations/getLatestCalculationStatus",
          null,
          { root: true }
        );
      }
      return result;
    }
  }
};
export default module;
