import BASE from "./api-endpoint";

function initialState() {
  return {
    apiVersion: "/api/v1",
    baseUrl: null,
    baseUrlCalc: null
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setBaseUrl: ((state, value) => {
      state.baseUrl = value;
    }),
    setBaseUrlCalc: ((state, value) => {
      state.baseUrlCalc = value;
    })
  },
  actions: {
    async setBaseUrl({ commit }, org) {
      const organization = BASE.organization;
      let baseUrl = organization[org] ? organization[org].urlBase : null;
      let calcUrl = organization[org] ? organization[org].urlCalc : null;
      commit("setBaseUrl", baseUrl);
      commit("setBaseUrlCalc", calcUrl);
    },
  },
  getters: {
    BaseUrl(state) {
      return state.baseUrl;
    },
    BaseUrlCalc(state) {
      return state.baseUrlCalc;
    },
    ApiVersion(state) {
      return state.apiVersion;
    }
  },
}

export default module;
