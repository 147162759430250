function initialState() {
  return {
    cardTitle: "Fee Review"
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    }
  },
  actions: {}
};
export default module;
