function equalObjects(obj1, obj2) {
  if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
    return true;
  }
  return false;
}

function deepCopy(obj) {
  let temp = JSON.stringify(obj);
  return JSON.parse(temp);
}

module.exports = {
  equalObjects,
  deepCopy,
};
