import store from "@/store";
import cookies from "vue-cookies";

function initialState() {
  return {
    defaultTitle: "fee-api-ui",
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    changePageTitle({ state }, route) {
      if (route.meta.title) {
        document.title = route.meta.title;
        switch (route.name) {
          case "Profile":
            {
              let username = cookies.get("username");
              if (username) {
                document.title += " - " + username;
              }
            }
            break;
          case "FeeResultsAccounts":
            {
              let date = store.getters["feeResultsAccounts/getSelectedDate"];
              if (date) {
                document.title += " (" + date + ")";
              }
            }
            break;
          case "HierarchyTree":
          case "ModifyEntity":
          case "FeeSchedules":
            {
              var entity = store.getters["directoryNavigation/getLastEntityInNavigation"];
              if (entity) {
                document.title = entity.text + " - " + document.title;
              } else {
                document.title = state.defaultTitle;
              }
            }
            break;
          default:
            break;
        }
      } else {
        document.title = state.defaultTitle;
      }
    }
  },
};
export default module;
