function initialState() {
  return {
    selectedAllAccounts: false
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setSelectedAllAccounts(state, value) {
      state.selectedAllAccounts = value;
    },
    resetSelectedAllAccounts(state) {
      state.selectedAllAccounts = false;
    },
  },
  actions: {}
};
export default module;
