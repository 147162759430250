import cookies from "vue-cookies";
import periodUtilModule from "@/utils/period-util.js";

function initialState() {
  return {
    runCalculationLimit: Number.MAX_SAFE_INTEGER,
    latestRunStatus: null,
    disallowedStatusesForRun: ["Paid Out", "PaidOut", "Pending", "Collected"],
    disallowedStatusMessage:
      "Selected bill instance is in a Pending, Collected or Paid Out status and cannot be re-calculated",

    billingRecordsPagination: {
      page: 1,
      itemsPerPage: 25
    },
    defaultPagination: {
      page: 1,
      itemsPerPage: 20
    }
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setLatestRunStatus(state, value) {
      state.latestRunStatus = value;
    },

    setBillingRecordsPagination(state, value) {
      state.billingRecordsPagination = value;
    },
    setPage(state, value) {
      let temp = state.billingRecordsPagination;
      temp.page = value;
      state.billingRecordsPagination = temp;
    },
    setPageSize(state, value) {
      let temp = state.billingRecordsPagination;
      temp.itemsPerPage = value;
      state.billingRecordsPagination = temp;
    },
    resetBillingRecordsPagination(state) {
      state.billingRecordsPagination = initialState().billingRecordsPagination;
    },
    resetPage(state) {
      let temp = state.billingRecordsPagination;
      temp.page = initialState().billingRecordsPagination.page;
      state.billingRecordsPagination = temp;
    },
    resetPageSize(state) {
      let temp = state.billingRecordsPagination;
      temp.itemsPerPage = initialState().billingRecordsPagination.itemsPerPage;
      state.billingRecordsPagination = temp;
    }
  },
  actions: {
    async getLatestCalculationStatus({ dispatch, commit, rootGetters }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL_CALC = rootGetters["endpoints/BaseUrlCalc"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL_CALC + VERSION + "/ComputeEngine/GetLatestCalculationStatus";

      try {
        let response = await fetch(URL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
        });

        if (response.ok) {
          if (response.status == 204) {
            commit("dialogs/setCalcStatusWarning", true, { root: true });
            return false;
          }
          let res = await response.json();
          commit("setLatestRunStatus", res);
          return res;
        } else {
          commit("dialogs/setCalcStatusError", true, { root: true });
          return false;
        }
      } catch (err) {
        console.error(err);
        commit("dialogs/setCalcStatusError", true, { root: true });
        return false;
      }
    },
    async getBillingRecordsByBillingRunType({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL_CALC = rootGetters["endpoints/BaseUrlCalc"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL_CALC +
        VERSION +
        "/ComputeEngine/batch/ReviewOrCalculateBillingRecordsByRunType?";

      let returnObject = {
        error: false,
        warningMessage: false,
        timeout: false,
        responseData: null,
        metaData: null,
        newBillingRecordsCreated: false
      };

      try {
        let signal = null;
        let id = null;
        const controller = new AbortController();
        if (payload.timeout >= 0) {
          id = setTimeout(() => controller.abort(), payload.timeout);
          signal = controller.signal;
        }

        let urlParams = {
          page: payload.page,
          pageSize: payload.pageSize
        };
        let bodyPayload = {
          processType: payload.processType,
          billingPeriod: payload.billingPeriod
        };
        if (payload.all) {
          urlParams = { ...urlParams, all: true };
          bodyPayload = {
            ...bodyPayload,
            ...payload.filterOptions
          };
        } else {
          urlParams = { ...urlParams, all: false };
          bodyPayload = { ...bodyPayload, accounts: payload.accounts };
        }

        let response = await fetch(URL + new URLSearchParams(urlParams), {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          body: JSON.stringify(bodyPayload),
          signal: signal
        });
        if (id) {
          clearTimeout(id);
        }

        if (response.ok) {
          let res = await response.json();
          returnObject.responseData = [...res.data.responseData];
          returnObject.metaData = { ...res.data.metaData };
          returnObject.newBillingRecordsCreated = res.newBillingRecordsCreated;
        } else if (response.status == 400) { // Another calculation in progress
          returnObject.warningMessage = await response.text();
        } else { // API Error
          console.error(response.statusText);
          returnObject.error = true;
        }
      } catch (err) {
        if (err instanceof DOMException && payload.timeout >= 0) { // Timeout
          returnObject.timeout = true;
        } else { // UI Error
          console.error("error: " + err);
          returnObject.error = true;
        }
      }
      return returnObject;
    },
    async viewBillingRecordsForMultiplePeriods({ rootGetters, rootState, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL_CALC = rootGetters["endpoints/BaseUrlCalc"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL_CALC +
        VERSION +
        "/ComputeEngine/batch/ViewBillingRecordsForMultiplePeriods?";

      let returnObject = {
        error: false,
        warningMessage: false,
        timeout: false,
        responseData: null,
        metaData: null,
        newBillingRecordsCreated: false
      };

      try {
        let feeFrom = periodUtilModule.getPreviousRunInt(payload.feeFrom);
        let feeTo = periodUtilModule.getPreviousRunInt(payload.feeTo);
        let urlParams = {
          page: payload.page,
          pageSize: payload.pageSize
        };
        let bodyPayload = {
          processType: payload.processType,
          feeFrom: feeFrom,
          feeTo: feeTo
        };
        if (payload.all) {
          urlParams = { ...urlParams, all: true };
          let filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: rootState.filters.feeReviewFilters,
              searchParam: payload.searchParam,
              quickSearchParam: rootState.filters.quickSearchFeeReview
            },
            { root: true }
          );
          bodyPayload = {
            ...bodyPayload,
            ...filterOptions,
            billingPeriodForFiltering: payload.billingPeriod,
            runTypeForFiltering: payload.runType
          };
        } else {
          urlParams = { ...urlParams, all: false };
          bodyPayload = { ...bodyPayload, accounts: payload.accounts };
        }

        let response = await fetch(URL + new URLSearchParams(urlParams), {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          body: JSON.stringify(bodyPayload)
        });

        if (response.ok) {
          let res = await response.json();
          returnObject.responseData = [...res.data.responseData];
          returnObject.metaData = { ...res.data.metaData };
          returnObject.newBillingRecordsCreated = res.newBillingRecordsCreated;
        } else if (response.status == 400) {
          returnObject.warningMessage = await response.text();
        } else {
          console.error(response.statusText);
          returnObject.error = true;
        }
      } catch (err) {
        if (err instanceof DOMException && payload.timeout >= 0) {
          returnObject.timeout = true;
        } else {
          console.error("error: " + err);
          returnObject.error = true;
        }
      }
      return returnObject;
    },
    async createBillingRecordsForMultiplePeriods({ rootState, rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL_CALC = rootGetters["endpoints/BaseUrlCalc"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL_CALC +
        VERSION +
        "/ComputeEngine/batch/CreateBillingRecordsForMultiplePeriods?";

      let returnObject = {
        error: false,
        warningMessage: false,
        timeout: false,
        responseData: null,
        metaData: null,
        newBillingRecordsCreated: false
      };

      try {
        let signal = null;
        let id = null;
        const controller = new AbortController();
        if (payload.timeout >= 0) {
          id = setTimeout(() => controller.abort(), payload.timeout);
          signal = controller.signal;
        }

        let urlParams;
        let bodyPayload = { processType: payload.processType };

        if (payload.all) {
          urlParams = { all: true };
          let filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: rootState.filters.feeReviewFilters,
              searchParam: payload.searchParam,
              quickSearchParam: rootState.filters.quickSearchFeeReview
            },
            { root: true }
          );
          let feeFrom = periodUtilModule.getPreviousRunInt(payload.feeFrom);
          let feeTo = periodUtilModule.getPreviousRunInt(payload.feeTo);
          bodyPayload = {
            ...bodyPayload,
            ...filterOptions,
            feeFrom: feeFrom,
            feeTo: feeTo,
            billingPeriodForFiltering: payload.billingPeriod,
            runTypeForFiltering: payload.runType
          };
        } else {
          urlParams = { all: false };
          bodyPayload = { ...bodyPayload, accounts: payload.accounts };
        }

        let response = await fetch(URL + new URLSearchParams(urlParams), {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          body: JSON.stringify(bodyPayload),
          signal: signal
        });
        if (id) {
          clearTimeout(id);
        }

        if (response.ok) {
          let res = await response.json();
          returnObject.responseData = [...res.data.responseData];
          returnObject.metaData = { ...res.data.metaData };
          returnObject.newBillingRecordsCreated = res.newBillingRecordsCreated;
        } else if (response.status == 400) {
          returnObject.warningMessage = await response.text();
        } else {
          console.error(response.statusText);
          returnObject.error = true;
        }
      } catch (err) {
        if (err instanceof DOMException && payload.timeout >= 0) {
          returnObject.timeout = true;
        } else {
          console.error("error: " + err);
          returnObject.error = true;
        }
      }
      return returnObject;
    },
    async getLastBillingRunInt({ rootGetters, dispatch }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/ComputeEngine/GetLastBillingRun";

      try {
        let response = await fetch(URL, {
          headers: {
            Authorization: TOKEN
          },
          method: "GET"
        });
        if (response.ok) {
          let result = await response.json();
          return result;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async getFirstAndLastBillingRunForAccount({ rootGetters, dispatch }, accountId) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/ComputeEngine/GetFirstAndLastBillingRunForAccount?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              accountId: accountId
            }),
          {
            headers: {
              Authorization: TOKEN
            },
            method: "GET"
          }
        );
        if (response.ok) {
          let result = await response.json();
          return result;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async getFirstAndLastSleeveDateForAccount({ rootGetters, dispatch }, accountId) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/ComputeEngine/GetFirstAndLastSleeveDateForAccount?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              accountId: accountId
            }),
          {
            headers: {
              Authorization: TOKEN
            },
            method: "GET"
          }
        );
        if (response.ok) {
          let result = await response.json();
          return result;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    async getAccountAumData({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/ComputeEngine/GetAccountAumData?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              accountId: payload.accountId,
              billingRun: payload.billingPeriod
            }),
          {
            method: "GET",
            headers: {
              Authorization: TOKEN,
              "Content-Type": "application/json"
            }
          }
        );
        if (response.ok) {
          let result = await response.json();
          if (result.message) {
            return {
              notFound: true,
              message: result.message
            };
          } else {
            result.accountId = payload.accountId;
            result.billingPeriod = payload.billingPeriod;
            return result;
          }
        } else {
          return false;
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    async updateBillingRecords({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/ComputeEngine/UpdateBillingRecords?";

      try {
        let response = await fetch(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "PUT",
          body: JSON.stringify(payload)
        });

        if (response.ok) {
          let result = await response.json();
          return result;
        } else {
          return false;
        }
      } catch (err) {
        console.log("updateBillingRecords error: " + err);
        return false;
      }
    },

    /*async getLastDayOfLastBillingRun({ rootGetters, dispatch }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL + VERSION + "/ComputeEngine/GetLastDayOfLastBillingRun";

      try {
        let response = await fetch(URL, {
          headers: {
            Authorization: TOKEN
          },
          method: "GET"
        });
        if (response.ok) {
          let result = await response.json();
          return result !== null ? result.slice(0, 10) : null;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },*/

    /*async calculateBackBilling({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/ComputeEngine/CalculateBackBillingFeesForAccounts?";

      try {
        let accounts = [];
        payload.forEach(acc => {
          accounts.push(acc);
        });
        let response = await fetch(
          URL +
            new URLSearchParams({
              processType: "BackBillingStartOver"
            }),
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            body: JSON.stringify({
              accounts: accounts
            })
          }
        );

        if (response.ok) {
          let result = await response.json();
          return result;
        } else if (response.status == 400) {
          return null;
        } else {
          return false;
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    }*/
  }
};

export default module;
