import router from "@/router";

function initialState() {
  return {
    navigation: [],
    navigationSelected: null,
    lastIdInNavigation: "root",
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setNavigation(state, navigation) {
      state.navigation = navigation;
    },
    setNavigationSelected(state, selected) {
      if (selected == null) {
        state.navigationSelected = null;
      } else {
        state.navigationSelected = {
          id: selected.id,
          text: selected.name,
          disabled: false
        };
      }
    },
    removeLastFromNavigation(state) {
      state.navigationSelected = null;
      if (state.navigation.length > 1) {
        state.navigation.pop();
      }
    },
    setLastIdInNavigation(state, value) {
      state.lastIdInNavigation = value;
    },
    addNavItem(state, navItem) {
      state.navigationSelected = null;
      state.navigation.push(navItem);
    },
  },
  actions: {
    async addNavigationItem({ state, commit }, item) {
      const indexOfLast = state.navigation.length - 1;
      if (state.navigation[indexOfLast].id != item.id)
        commit("addNavItem", item);
    },
    async cutNavigation({ state, commit }, newId) {
      let newNav = [];
      let found = false;

      for (let i = 0; i < state.navigation.length; i++) {
        const elem = state.navigation[i];

        if (found) {
          break;
        }

        newNav.push(elem);

        if (elem.id == newId) {
          found = true;
        }
      }
      commit("setNavigation", newNav);
    },
    async setNewNavigation({ commit, dispatch }, { navItem, rootId }) {
      let navArr = [];
      navArr.push({
        id: navItem.id,
        text: navItem.name,
        type: navItem.type,
        disabled: false
      });
      let entityId = navItem.id;
      if (entityId != rootId) {
        let moreParents = true;
        while (moreParents) {
          let currentParent = null;
          currentParent = await dispatch(
            "browseEntities/getEntityParent",
            entityId,
            { root: true }
          );
          if (
            currentParent === null ||
            currentParent === "" ||
            currentParent.id === rootId
          ) {
            moreParents = false;
          }
          if (currentParent !== null && currentParent !== "") {
            navArr.push({
              id: currentParent.id,
              text: currentParent.name,
              type: currentParent.type,
              disabled: false
            });
            entityId = currentParent.id;
          } else {
            return (
              "Entity with ID '" +
              entityId +
              "' doesn't belong to this hierarchy."
            );
          }
        }
      }
      let newNav = navArr.reverse();
      commit("setNavigation", newNav);
      return "";
    },
    async jumpToNavigationItem({ commit, dispatch, state, rootState }, navItem) {
      let navigationError = "";
      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      try {
        state.navigationSelected = null;
        commit("setNavigationSelected", null);
        dispatch("hierarchyTree/resetAll", null, { root: true });
        let rootId = await dispatch("browseEntities/getRootEntityId", null, {
          root: true
        });
        //selected item is root
        var currentHierarchy = rootState.hierarchyMode.hierarchyTag.address;
        var currentName = router.history.current.name;
        if (navItem === null || navItem.id === "" || navItem.id === rootId) {
          if (router.history.current.params.id != "root") {
            //change route to root
            router.push({
              name: currentName,
              params: { hierarchy: currentHierarchy, id: "root" }
            });
          }
          // set navigation to root and set root children
          await dispatch("browseEntities/getRootEntity", null, { root: true });
        } else {
          // change route to new id
          let newId = navItem.id;

          //check if there is an item in the navigation array
          let index = state.navigation.findIndex(
            elem => elem.id.toLowerCase() === newId.toLowerCase()
          );
          let inNavigation = index !== -1;

          //item is selected from search or no item in the navigation array, set new navigation
          if (navItem.selectedFromSearch || navItem.switchHierarchy === true || !inNavigation) {
            navigationError = await dispatch("setNewNavigation", {
              navItem,
              rootId
            });
          }
          //item is already in navigation, cut navigation
          else {
            await dispatch("cutNavigation", newId);
          }

          if (navigationError !== "") {
            throw "Navigation Error!";
          }

          if (router.history.current.params.id != newId) {
            router.push({
              name: currentName,
              params: { hierarchy: currentHierarchy, id: newId }
            });
          }

          //set new children
          let payload = {
            entityId: newId,
            page: rootState.hierarchyTree.pagination.page, // page is already reseted
            pageSize: rootState.hierarchyTree.pagination.itemsPerPage
          };
          await dispatch("browseEntities/hierarchyTreeSearch", payload, {
            root: true
          });
        }
        return true;
      } catch (err) {
        console.error(err);
        return false;
      } finally {
        dispatch("loading/endLoading", null, { root: true });
        finished = true;
      }
    },
    async getSelectionId({ state, rootState, dispatch }) {
      if (state.navigationSelected != null && state.navigationSelected !== "") {
        return state.navigationSelected.id;
      }
      if (state.navigation.length === 0) {
        if (
          rootState.browseEntities.rootId === undefined ||
          rootState.browseEntities.rootId === null ||
          rootState.browseEntities.rootId === ""
        ) {
          await dispatch("browseEntities/getRootEntityId", null, {
            root: true
          });
        }
        return rootState.browseEntities.rootId;
      }
      return state.navigation[state.navigation.length - 1].id;
    }
  },
  getters: {
    getLastEntityInNavigation(state) {
      if (state.navigation && state.navigation.length > 0) {
        return state.navigation[state.navigation.length - 1];
      }
    },
  },
};
export default module;
