import cookies from "vue-cookies";

function initialState() {
  return {
    mainMenuActive: false,
    currentApiEnvironment: null,
  };
}
const module = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setMainMenuActive(state, value) {
      state.mainMenuActive = value;
    },
    setCurrentApiEnvironment(state, value) {
      state.currentApiEnvironment = value;
    },
  },
  actions: {
    setCurrentApiEnvironment({ commit }) {
      let org = cookies.get("organization");
      commit("setCurrentApiEnvironment", org);
    },
  }
};
export default module;
