<template>
  <div class="warning-component-style">
    <v-icon class="pr-4" style="color: #00000099">mdi-information</v-icon>
    <p class="mb-0 warning-banner-text">
      {{ timeLeftText }}
    </p>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { formatUtcDateTimeToLocalDate } from "@/utils/format-util.js";

export default {
  computed: {
    ...mapState("backgroundMessages", ["timeLeft"]),
    timeLeftText() {
      if (!this.timeLeft || this.timeLeft == "COMPLETED") {
        return "";
      }
      let currentTime = new Date();
      let currentDateString = formatUtcDateTimeToLocalDate(currentTime);
      let minutesLeft = this.timeLeft.minutes;
      if (minutesLeft == 0) {
        return `Daily import for ${currentDateString} begins soon.`;
      } else if (minutesLeft == 1) {
        return `Daily import for ${currentDateString} begins in 1 minute. Site will be temporarily offline.`;
      } else {
        return `Daily import for ${currentDateString} begins in ${minutesLeft} minutes. Site will be temporarily offline.`;
      }
    }
  }
};
</script>
<style>
.warning-banner-text {
  font-size: 14px;
  color: #00000099;
}
.banner-message_bold {
  font-weight: bold;
}
.warning-component-style {
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>
