const productionUIHost = "www.billing-ui.dev-ideacompiler.com";
const stagingUIHost = "stg.billing-ui.dev-ideacompiler.com";
const qaUIHost = "qa.billing-ui.dev-ideacompiler.com";
const localhostUIHost = "localhost:8080";
const BASE = {
  organization: {
    "AmericanPortfolios": {
      urlBase: "https://billing-ap-api.dev-ideacompiler.com",
      urlCalc: "https://billing-ap-cal-api.dev-ideacompiler.com",
      uiUrlHost: [productionUIHost]
    },
    "Staging": {
      urlBase: "https://billing-api-stg.dev-ideacompiler.com",
      urlCalc: "https://billing-api-calc-stg.dev-ideacompiler.com",
      uiUrlHost: [productionUIHost, stagingUIHost, localhostUIHost]
    },
    "SmartX": {
      urlBase: "https://billing-api.dev-ideacompiler.com",
      urlCalc: "https://billing-api.dev-ideacompiler.com", // The same as urlBase
      uiUrlHost: [productionUIHost]
    },
    "Apollon": {
      urlBase: "https://billing-apollon-api.dev-ideacompiler.com",
      urlCalc: "https://billing-apollon-calc-api.dev-ideacompiler.com",
      uiUrlHost: [productionUIHost]
    },
    "ApollonStaging": {
      urlBase: "https://billing-apollon-stg-api.dev-ideacompiler.com",
      urlCalc: "https://billing-apollon-stg-api.dev-ideacompiler.com", // The same as urlBase
      uiUrlHost: [productionUIHost, stagingUIHost, localhostUIHost]
    },
    "QA": {
      urlBase: "https://billing-api-qa.dev-ideacompiler.com",
      urlCalc: "https://billing-api-qa.dev-ideacompiler.com", // The same as urlBase
      uiUrlHost: [qaUIHost, localhostUIHost]
    },
    "Dev": {
      urlBase: "http://localhost:55423",
      urlCalc: "http://localhost:55423", // The same as urlBase
      uiUrlHost: [productionUIHost, stagingUIHost, localhostUIHost]
    },
  },
  CookieExpiration: "1D"
};

export default BASE;
