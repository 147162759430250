import cookies from "vue-cookies";

function initialState() {
  return {
    propertiesFilters: [],
    quickSearchParam: null,
    updatingProperties: false,
    updateError: false
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setPropertiesFilters(state, value) {
      state.propertiesFilters = value;
    },
    resetPropertiesFilters(state) {
      state.propertiesFilters = [];
    },
    setQuickSearchParam(state, value) {
      state.quickSearchParam = value;
    },
    resetQuickSearchParam(state) {
      state.quickSearchParam = null;
    },
    setUpdatingProperties(state, value) {
      state.updatingProperties = value;
    },
    setUpdateError(state, value) {
      state.updateError = value;
    }
  },
  actions: {
    async updateMultipleAccounts({ state, rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/EntityAttributes/UpdateManyBillingEntityAttributes?";

      var returnObject = {
        totalUpdated: 0,
        totalFailed: 0,
        attributes: []
      };
      try {
        let bodyPayload = null;
        let urlParams = {
          attributeName: payload.attributeName,
          newAttributeValue: payload.newValue
        };
        if (payload.all) {
          let filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: state.propertiesFilters,
              searchParam: payload.searchParam,
              quickSearchParam: state.quickSearchParam
            },
            { root: true }
          );
          bodyPayload = {
            ...filterOptions,
            billingPeriod: payload.period,
            billingRunType: payload.runType
          };
          urlParams = { ...urlParams, all: true };
        } else {
          bodyPayload = { accountIds: payload.accountIds };
          urlParams = { ...urlParams, all: false };
        }
        let response = await fetch(URL + new URLSearchParams(urlParams), {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "PATCH",
          body: JSON.stringify(bodyPayload)
        });
        if (response.ok) {
          let result = await response.json();
          returnObject = result;
        } else {
          returnObject.totalFailed = payload.all
            ? false
            : payload.accountIds.length;
        }
      } catch (err) {
        console.log(err);
        returnObject.totalFailed = payload.all
          ? false
          : payload.accountIds.length;
      }
      return returnObject;
    },
    async updateAccountProperties({ commit, dispatch }, payload) {
      commit("setUpdatingProperties", true);
      var returnObject = {
        error: false,
        totalFailed: 0,
        allFailed: false,
        all: payload.all
      };
      var accountIds = [];
      var all = false;
      var feeFreqFailed = 0;
      var paidByFailed = 0;
      var startDateFailed = 0;
      var endDateFailed = 0;
      var billableStatusFailed = 0;
      var attributesCount = 0;
      var failedCount = 0;

      if (payload.all) {
        all = true;
      } else {
        accountIds = payload.accounts.map(a => a.id);
      }

      try {
        if (
          payload.feeFrequency !== undefined &&
          payload.feeFrequency !== null &&
          payload.feeFrequency !== ""
        ) {
          attributesCount += 1;
          let feeFreqResult = await dispatch("updateMultipleAccounts", {
            runType: payload.runType,
            period: payload.period,
            all: all === true ? true : false,
            accountIds: accountIds,
            attributeName: "Billing Type",
            newValue: payload.feeFrequency
          });
          if (feeFreqResult.totalFailed !== false) {
            feeFreqFailed = feeFreqResult.totalFailed;
          } else {
            failedCount += 1;
          }
        }
        if (
          payload.paidBy !== undefined &&
          payload.paidBy !== null &&
          payload.paidBy !== ""
        ) {
          attributesCount += 1;
          let paidByResult = await dispatch("updateMultipleAccounts", {
            runType: payload.runType,
            period: payload.period,
            all: all === true ? true : false,
            accountIds: accountIds,
            attributeName: "Paid By",
            newValue: payload.paidBy
          });
          if (paidByResult.totalFailed !== false) {
            paidByFailed = paidByResult.totalFailed;
          } else {
            failedCount += 1;
          }
        }
        if (payload.startDate !== undefined && payload.startDate !== null) {
          attributesCount += 1;
          let startDateResult = await dispatch("updateMultipleAccounts", {
            runType: payload.runType,
            period: payload.period,
            all: all === true ? true : false,
            accountIds: accountIds,
            attributeName: "Billing Start Date",
            newValue: payload.startDate
          });
          if (startDateResult.totalFailed !== false) {
            startDateFailed = startDateResult.totalFailed;
          } else {
            failedCount += 1;
          }
        }
        if (payload.endDate !== undefined && payload.endDate !== null) {
          attributesCount += 1;
          let endDateResult = await dispatch("updateMultipleAccounts", {
            runType: payload.runType,
            period: payload.period,
            all: all === true ? true : false,
            accountIds: accountIds,
            attributeName: "Billing End Date",
            newValue: payload.endDate
          });
          if (endDateResult.totalFailed !== false) {
            endDateFailed = endDateResult.totalFailed;
          } else {
            failedCount += 1;
          }
        }
        if (
          payload.billableStatus !== undefined &&
          payload.billableStatus !== null &&
          payload.billableStatus !== ""
        ) {
          attributesCount += 1;
          let billableStatusResult = await dispatch("updateMultipleAccounts", {
            runType: payload.runType,
            period: payload.period,
            all: all === true ? true : false,
            accountIds: accountIds,
            attributeName: "Billable Status",
            newValue: payload.billableStatus
          });
          if (billableStatusResult.totalFailed !== false) {
            billableStatusFailed = billableStatusResult.totalFailed;
          } else {
            failedCount += 1;
          }
        }

        if (payload.all) {
          returnObject.error = false;
          returnObject.totalFailed =
            failedCount +
            feeFreqFailed +
            paidByFailed +
            startDateFailed +
            endDateFailed +
            billableStatusFailed;
          returnObject.allFailed = attributesCount == failedCount ? true : false;
        } else {
          returnObject.error = false;
          returnObject.totalFailed =
            feeFreqFailed +
            paidByFailed +
            startDateFailed +
            endDateFailed +
            billableStatusFailed;
        }
      } catch (err) {
        console.log(err);
        returnObject.error = true;
        commit("setUpdateError", true);
      }
      commit("setUpdatingProperties", false);
      return returnObject;
    },
  }
};
export default module;
