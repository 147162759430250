function initialState() {
  return {
    calculationResults: [],
    totalCalculationResults: 0,
    calculationResultsMultiPeriod: [],
    totalCalculationResultsMultiPeriod: 0,
    periodRangeFilter: {
      feeFrom: null,
      feeTo: null
    },
    accountsForCalculation: [],
    accountsForReRun: [],
    accountsForReRunMultiPeriod: [],
    defaultTimeout: 25000,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setCalculationResults(state, value) {
      value.forEach(element => {
        element.runTypeKey = element.accountId + element.billingRunType;
      });
      state.calculationResults = value;
    },
    setTotalCalculationResults(state, value) {
      state.totalCalculationResults = value;
    },
    setCalculationResultsMultiPeriod(state, accounts) {
      state.calculationResultsMultiPeriod = accounts;
    },
    setTotalCalculationResultsMultiPeriod(state, value) {
      state.totalCalculationResultsMultiPeriod = value;
    },
    setPeriodRangeFilter(state, filter) {
      state.periodRangeFilter = filter;
    },
    resetPeriodRangeFilter(state) {
      state.periodRangeFilter = {
        feeFrom: null,
        feeTo: null
      };
    },
    setAccountsForCalculation(state, value) {
      state.accountsForCalculation = value;
    },
    setAccountsForReRun(state, value) {
      state.accountsForReRun = value;
    },
    setAccountsForReRunMultiPeriod(state, value) {
      state.accountsForReRunMultiPeriod = value;
    },
  },
  actions: {
    async runCalculation({ rootGetters, rootState, state, dispatch }, payload) {
      const billingPeriod = rootGetters["feeResultsAccounts/getPreviousBillingPeriod"];
      let filterOptions = await dispatch(
        "filters/getFilterOptions",
        {
          predicates: rootState.filters.feeReviewFilters,
          searchParam: null,
          quickSearchParam: rootState.filters.quickSearchFeeReview
        },
        { root: true }
      );
      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: payload.accounts,
          processType: "StartOver",
          billingPeriod: billingPeriod,
          page: payload.page,
          pageSize: payload.pageSize,
          timeout: state.defaultTimeout,
          all: payload.all,
          filterOptions: filterOptions
        },
        { root: true }
      );

      if (result.responseData) {
        let res = await dispatch("getBillingRecordsPaged", payload);
        if (!res) {
          result.error = true;
        } else {
          result.responseData = true;
        }
      } else {
        await dispatch(
          "billingRecordsCalculations/getLatestCalculationStatus",
          null,
          { root: true }
        );
      }
      return result;
    },
    async reRunCalculation({ rootState, rootGetters, commit, dispatch, state }, payload) {
      const billingPeriod = rootGetters["feeResultsAccounts/getPreviousBillingPeriod"];
      let filterOptions = await dispatch(
        "filters/getFilterOptions",
        {
          predicates: rootState.filters.feeReviewFilters,
          searchParam: null,
          quickSearchParam: rootState.filters.quickSearchFeeReview
        },
        { root: true }
      );
      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: payload.accounts,
          processType: "StartOver",
          billingPeriod: billingPeriod,
          page: payload.page,
          pageSize: payload.pageSize,
          all: payload.all,
          filterOptions: filterOptions,
          timeout: state.defaultTimeout
        },
        { root: true }
      );

      if (result.responseData) {
        let page = rootState.billingRecordsCalculations.billingRecordsPagination.page;
        if (page === 1) {
          let res = await dispatch("getBillingRecordsPaged", payload);
          if (!res) {
            result.error = true;
          } else {
            result.responseData = true;
          }
        } else {
          commit("billingRecordsCalculations/resetPage", null, { root: true });
        }
      } else {
        await dispatch(
          "billingRecordsCalculations/getLatestCalculationStatus",
          null,
          { root: true }
        );
      }
      return result;
    },
    async runCalculationMultiPeriod({ rootState, commit, dispatch, state }, payload) {
      const result = await dispatch(
        "billingRecordsCalculations/createBillingRecordsForMultiplePeriods",
        {
          accounts: payload.accounts,
          processType: "StartOver",
          timeout: state.defaultTimeout,
          page: payload.page,
          pageSize: payload.pageSize,
          feeFrom: state.periodRangeFilter.feeFrom,
          feeTo: state.periodRangeFilter.feeTo,
          all: payload.all,
          runType: "Regular",
          billingPeriod: payload.billingPeriod
        },
        { root: true }
      );

      if (result.responseData) {
        let page = rootState.billingRecordsCalculations.billingRecordsPagination.page;
        if (page === 1) {
          let res = await dispatch("getBillingRecordsForMultiplePeriods", payload);
          if (!res) {
            result.error = true;
          } else {
            result.responseData = true;
          }
        } else {
          // This will trigger call from watcher in ReviewFeesAndSchedules.vue
          commit("billingRecordsCalculations/resetPage", null, { root: true });
        }
      } else {
        await dispatch(
          "billingRecordsCalculations/getLatestCalculationStatus",
          null,
          { root: true }
        );
      }
      return result;
    },
    async getAccountRecords({ rootGetters, state, commit, dispatch }, payload) {
      const billingPeriod = rootGetters["feeResultsAccounts/getPreviousBillingPeriod"];

      let account = {
        id: payload.modifiedRecord.accountId,
        billingRunType: payload.modifiedRecord.billingRunType
      };

      let result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: [account],
          processType: "Resume",
          billingPeriod: billingPeriod,
          page: 1,
          pageSize: 1,
        },
        { root: true }
      );

      let newAccount = result.responseData[0];
      if (newAccount) {
        if (payload.multiPeriodActive) {
          let newResults = [...state.calculationResultsMultiPeriod];
          let index = newResults.findIndex(
            acc =>
              acc.accountId === newAccount.accountId &&
              acc.billingRunType === newAccount.billingRunType
          );
          let newAccountsInPeriod = [...newResults[index].accountsInPeriod];
          newAccountsInPeriod.splice(
            newAccountsInPeriod.findIndex(
              e =>
                e.accountId === newAccount.accountId &&
                e.billingRunType === newAccount.billingRunType &&
                e.billingPeriod === newAccount.billingPeriod
            ),
            1,
            newAccount
          );
          newResults[index].accountsInPeriod = newAccountsInPeriod;
          commit("setCalculationResultsMultiPeriod", newResults);
        } else {
          let newResults = [...state.calculationResults];
          newResults.splice(
            newResults.findIndex(acc => acc.accountId === newAccount.accountId),
            1,
            newAccount
          );
          commit("setCalculationResults", newResults);
        }
        return true;
      } else {
        return false;
      }
    },
    async getBillingRecordsPaged({ rootGetters, rootState, commit, dispatch }, payload) {
      const billingPeriod = rootGetters["feeResultsAccounts/getPreviousBillingPeriod"];
      let filterOptions = await dispatch(
        "filters/getFilterOptions",
        {
          predicates: rootState.filters.feeReviewFilters,
          searchParam: null,
          quickSearchParam: rootState.filters.quickSearchFeeReview
        },
        { root: true }
      );
      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: payload.accounts,
          processType: "Resume",
          billingPeriod: billingPeriod,
          page: payload.page,
          pageSize: payload.pageSize,
          all: payload.all,
          filterOptions: filterOptions
        },
        { root: true }
      );

      if (result.responseData) {
        commit("setCalculationResults", result.responseData);
        commit("setTotalCalculationResults", result.metaData.totalRecordCount);
        return true;
      } else {
        return false;
      }
    },
    async getBillingRecordsForMultiplePeriods({ commit, state, dispatch }, payload) {
      const result = await dispatch(
        "billingRecordsCalculations/viewBillingRecordsForMultiplePeriods",
        {
          accounts: payload.accounts,
          processType: "Resume",
          billingPeriod: payload.billingPeriod,
          runType: "Regular",
          page: payload.page,
          pageSize: payload.pageSize,
          feeFrom: state.periodRangeFilter.feeFrom,
          feeTo: state.periodRangeFilter.feeTo,
          all: payload.all
        },
        { root: true }
      );

      if (result.responseData) {
        for (var i = 0; i < result.responseData.length; i++) {
          let account = result.responseData[i];
          account.runTypeKey = account.accountId + account.billingRunType;
          account.accountsInPeriod.forEach(accInPeriod => {
            accInPeriod.periodKey = account.runTypeKey + accInPeriod.billingPeriod;
          });
        }
        commit("setCalculationResultsMultiPeriod", result.responseData);
        commit("setTotalCalculationResultsMultiPeriod", result.metaData.totalRecordCount);
        return true;
      } else {
        commit("setCalculationResultsMultiPeriod", []);
        commit("setTotalCalculationResultsMultiPeriod", 0);
        return false;
      }
    },
    async getAllBillingRecordsPaged({ rootState, rootGetters, dispatch }, payload) {
      const billingPeriod = rootGetters["feeResultsAccounts/getPreviousBillingPeriod"];
      let filterOptions = await dispatch(
        "filters/getFilterOptions",
        {
          predicates: rootState.filters.feeReviewFilters,
          searchParam: null,
          quickSearchParam: rootState.filters.quickSearchFeeReview
        },
        { root: true }
      );
      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: payload.accounts,
          processType: "Resume",
          billingPeriod: billingPeriod,
          page: payload.page,
          pageSize: payload.pageSize,
          all: payload.all,
          filterOptions: filterOptions
        },
        { root: true }
      );

      if (result.responseData) {
        result.responseData.forEach(element => {
          element.runTypeKey = element.accountId + element.billingRunType;
        });
      }
      return result;
    },
    async getAllMultiPeriod({ state, dispatch }, payload) {
      const result = await dispatch(
        "billingRecordsCalculations/viewBillingRecordsForMultiplePeriods",
        {
          accounts: payload.accounts,
          processType: "Resume",
          billingPeriod: payload.billingPeriod,
          runType: "Regular",
          page: payload.page,
          pageSize: payload.pageSize,
          feeFrom: state.periodRangeFilter.feeFrom,
          feeTo: state.periodRangeFilter.feeTo,
          all: payload.all
        },
        { root: true }
      );

      if (result.responseData) {
        for (var i = 0; i < result.responseData.length; i++) {
          let account = result.responseData[i];
          account.runTypeKey = account.accountId + account.billingRunType;
          account.accountsInPeriod.forEach(accInPeriod => {
            accInPeriod.periodKey = account.runTypeKey + accInPeriod.billingPeriod;
          });
        }
      }
      return result;
    },
    async getBillingRecordsForInvoice({ commit, dispatch }, payload) {
      const billingPeriod = payload.billingPeriod;
      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: payload.accounts,
          processType: "Resume",
          billingPeriod: billingPeriod,
          page: payload.page,
          pageSize: payload.pageSize
        },
        { root: true }
      );

      if (result.responseData) {
        commit("setCalculationResults", result.responseData);
        commit("setTotalCalculationResults", result.metaData.totalRecordCount);
        return true;
      } else {
        return false;
      }
    },
  }
};

export default module;
