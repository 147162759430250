import cookies from "vue-cookies";

function initialState() {
  return {
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    }
  },
  actions: {
    async addAndAssignFeeSchedule({ rootState, dispatch }, payload) {
      // Create new Fee Schedule
      const newFee = await dispatch("addFeeSchedule", {
        userName: payload.userName,
        name: payload.name,
        billingDiscountType: payload.billingDiscountType,
        minimum: payload.monthlyMinimumFee,
        maximum: 0,
        billingAumBasisType: payload.billingAumBasisType,
        details: ""
      });

      // Assign Fee Schedule
      await dispatch("assignFeeSchedule", {
        userName: payload.userName,
        billingEntityId: rootState.feeSchedule.billingEntity.id,
        billingFeeDefinitionsId: newFee.id,
        billingFeeType: payload.billingFeeType,
        feeGroup: "",
        alwaysEvaluate: null
      });

      // Reload
      await dispatch(
        "feeSchedule/findEffectiveFeeSchedule",
        {
          id: rootState.feeSchedule.billingEntity.id,
          feeType: null,
          completeList: true
        },
        { root: true }
      );
      await dispatch(
        "feeSchedule/selectBillingFeeType",
        {
          type: rootState.feeSchedule.selectedBillingFeeType
        },
        { root: true }
      );

      return newFee;
    },
    async addFeeSchedule({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/AddFeeSchedule?";

      let response = await fetch(
        URL +
          new URLSearchParams({
            userName: payload.userName
          }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "POST",
          body: JSON.stringify({
            name: payload.name,
            billingDiscountType: payload.billingDiscountType,
            minimum: payload.minimum,
            maximum: payload.maximum,
            billingAumBasisType: payload.billingAumBasisType,
            details: payload.details
          })
        }
      );
      let result = await response.json();
      return result;
    },
    async assignFeeSchedule({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/AssignFeeSchedule?";

      let response = await fetch(
        URL +
          new URLSearchParams({
            userName: payload.userName
          }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "POST",
          body: JSON.stringify({
            billingEntityId: payload.billingEntityId,
            billingFeeDefinitionsId: payload.billingFeeDefinitionsId,
            billingFeeType: payload.billingFeeType,
            feeGroup: payload.feeGroup,
            alwaysEvaluate: payload.alwaysEvaluate
          })
        }
      );
      let result = await response.json();
      return result;
    },
    async createDealFeeSchedule({ dispatch, rootState }, payload) {
      // Create new Fee Schedule
      const newFee = await dispatch("addFeeSchedule", {
        userName: payload.userName,
        name: payload.name,
        billingDiscountType: payload.billingDiscountType,
        minimum: payload.monthlyMinimumFee,
        maximum: 0,
        billingAumBasisType: payload.billingAumBasisType,
        details: ""
      });

      // Assign Fee Schedule to a Deal
      await dispatch("assignDealFeeSchedule", {
        userName: payload.userName,
        billingEntityIdOne: rootState.feeSchedule.billingEntity.id,
        billingEntityIdTwo: payload.billingEntityIdTwo,
        billingFeeDefinitionsId: newFee.id,
        billingFeeType: payload.billingFeeType,
        feeGroup: "",
        alwaysEvaluate: null
      });

      // Reload
      await dispatch(
        "feeSchedule/findEffectiveFeeSchedule",
        {
          id: rootState.feeSchedule.billingEntity.id,
          feeType: null,
          completeList: true
        },
        { root: true }
      );
      await dispatch(
        "feeSchedule/selectBillingFeeType",
        { type: rootState.feeSchedule.selectedBillingFeeType },
        { root: true }
      );

      return newFee;
    },
    async assignDealFeeSchedule({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/AssignDealFeeSchedule?";

      let response = await fetch(
        URL +
          new URLSearchParams({
            userName: payload.userName
          }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "POST",
          body: JSON.stringify({
            billingEntityIdOne: payload.billingEntityIdOne,
            billingEntityIdTwo: payload.billingEntityIdTwo,
            billingFeeDefinitionsId: payload.billingFeeDefinitionsId,
            billingFeeType: payload.billingFeeType,
            feeGroup: payload.feeGroup,
            alwaysEvaluate: payload.alwaysEvaluate
          })
        }
      );
      let result = await response.json();
      return result;
    },
    async modifyFeeSchedule({ commit, dispatch, rootGetters, rootState }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/ModifyFeeSchedule?";
      const scheduleId = rootState.feeTiers.selectedFee.id;
      let response = await fetch(
        URL +
          new URLSearchParams({
            id: scheduleId,
            userName: payload.userName,
            newFeeType: payload.billingFeeType,
          }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "PUT",
          body: JSON.stringify({
            name: payload.name,
            billingDiscountType: payload.billingDiscountType,
            minimum: payload.monthlyMinimumFee,
            maximum: 0,
            billingAumBasisType: payload.billingAumBasisType,
            details: ""
          })
        }
      );
      let result = await response.json();

      const billingType = rootState.feeSchedule.selectedBillingFeeType;
      await dispatch(
        "feeSchedule/findEffectiveFeeSchedule",
        {
          id: rootState.feeSchedule.billingEntity.id,
          feeType: null,
          completeList: true
        },
        { root: true }
      );
      await dispatch(
        "feeSchedule/selectBillingFeeType",
        { type: billingType },
        { root: true }
      );
      let i = 0;
      let fees = rootState.feeSchedule.fees;
      while (i < fees.length) {
        if (scheduleId === fees[i].id) {
          break;
        }
        i++;
      }
      commit("feeTiers/setSelectedFee", fees[i], { root: true });
      return result;
    },
    async deleteFeeSchedule({ commit, dispatch, rootState, rootGetters }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/DeleteFeeSchedule?";

      let response = await fetch(
        URL +
          new URLSearchParams({
            id: payload.id
          }),
        {
          headers: {
            Authorization: TOKEN
          },
          method: "DELETE"
        }
      );
      let result = await response.json();

      const removedScheduleId = payload.id;

      let i = 0;
      let fees = rootState.feeSchedule.fees;
      while (i < fees.length) {
        const feeItem = fees[i];
        if (feeItem.id === removedScheduleId) {
          break;
        }
        i++;
      }

      commit("feeTiers/setSelectedFee", null, { root: true });
      commit("feeSchedule/setInherited", false, { root: true });
      fees.splice(i, 1);
      dispatch(
        "feeSchedule/selectBillingFeeType",
        { type: rootState.feeSchedule.selectedBillingFeeType },
        { root: true }
      );

      return result;
    }
  }
};

export default module;
