import router from "@/router";

function initialState() {
  return {
    tabs: 0,
    defaultTab: "HierarchyTree",
    defaultHierarchyAddress: "account-firm",
    defaultId: "root"
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setTabs(state, value) {
      state.tabs = value;
    }
  },
  actions: {
    async setDefaultRoute({ state }) {
      router.replace({
        name: state.defaultTab,
        params: {
          hierarchy: state.defaultHierarchyAddress,
          id: state.defaultId
        }
      });
    },
    async returnTabName({ state }) {
      if (state.tabs == 0) {
        return "HierarchyTree";
      }
      if (state.tabs == 1) {
        return "FeeSchedules";
      }
      if (state.tabs == 2) {
        return "ModifyEntity";
      }
      if (state.tabs == 3) {
        return "AddGroup";
      }
      return null;
    },
    async setTab({ commit, dispatch, rootState }, tabName) {
      let tab = 0;
      if (tabName == "HierarchyTree") {
        tab = 0;
      } else if (tabName == "FeeSchedules") {
        tab = 1;
      } else if (tabName == "ModifyEntity") {
        tab = 2;
      } else if (tabName == "AddGroup") {
        tab = 3;
      }
      if (router.history.current.name != tabName) {
        const id = await dispatch("directoryNavigation/getSelectionId", null, {
          root: true
        });
        router.push({
          name: tabName,
          params: {
            hierarchy: rootState.hierarchyMode.hierarchyTag.address,
            id: id
          }
        });
        commit("directoryNavigation/setNavigationSelected", null, {
          root: true
        });
      }
      commit("setTabs", tab);
    }
  }
};
export default module;
