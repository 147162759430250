<template>
  <v-dialog v-model="elementsLoading" persistent max-width="250">
    <v-card :color="colorTheme.card" dark>
      <v-card-text class="text-center">
        Loading. Please wait.
        <v-progress-linear
          indeterminate
          rounded
          height="6"
          :color="colorTheme.progressWhite"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({}),
  computed: {
    ...mapState("loading", ["elementsLoading"]),
    ...mapState("userConfig", ["colorTheme"])
  }
};
</script>
