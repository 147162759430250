import cookies from "vue-cookies";

function initialState() {
  return {
    modifiedEntityAttributes: [],
    allowedValuesForAttributes: [],
    advisoryFirmProperties: ["Default Billing Type"],
    defaultBillingType: null,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setModifiedEntityAttributes(state, modifiedEntityAttributes) {
      state.modifiedEntityAttributes = modifiedEntityAttributes;
    },
    setAllowedValuesForAttributes(state, value) {
      let sortedValues = value.sort((a, b) => (a.order > b.order ? 1 : -1));
      state.allowedValuesForAttributes = sortedValues;
    },
    setDefaultBillingType(state, value) {
      state.defaultBillingType = value;
    }
  },
  actions: {
    async getBillingEntityAttributes({ commit, rootGetters, dispatch }, billingEntityId) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/EntityAttributes/billingEntityAttributes/" +
        billingEntityId;

      try {
        if (
          billingEntityId !== null &&
          billingEntityId !== "" &&
          billingEntityId !== undefined
        ) {
          let response = await fetch(URL, {
            headers: {
              "Content-Type": "application/json",
              Authorization: TOKEN
            }
          });
          let result = await response.json();
          commit("setModifiedEntityAttributes", result);
          await dispatch("getDefaultBillingType", billingEntityId);
        }
      } catch (err) {
        console.error(err);
        commit("setModifiedEntityAttributes", []);
      }
    },
    async getDefaultBillingType({ rootState, commit, dispatch, rootGetters }, firmId) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL + VERSION + "/EntityAttributes/GetDefaultBillingType?";

      commit("setDefaultBillingType", null);
      try {
        let entityId;
        if (firmId) {
          entityId = firmId;
        } else {
          entityId = rootState.modifyEntity.modifiedEntity.id;
        }
        if (!entityId) {
          throw "No Firm ID!";
        }
        let response = await fetch(
          URL +
            new URLSearchParams({
              firmId: entityId
            }),
          {
            method: "GET",
            headers: {
              Authorization: TOKEN,
              "Content-Type": "application/json"
            },
          }
        );
        if (response.ok && response.status !== 204) {
          let result = await response.json();
          commit("setDefaultBillingType", result);
          return result;
        } else {
          commit("setDefaultBillingType", null);
          return false;
        }
      } catch (err) {
        console.error("GetDefaultBillingType error: " + err);
        commit("setDefaultBillingType", null);
        return false;
      }
    },
    async getAllowedValuesForAttributes({ commit, rootGetters, dispatch }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL + VERSION + "/EntityAttributes/GetAllowedValuesForAttributes";

      try {
        let response = await fetch(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          }
        });
        if (response.ok) {
          let result = await response.json();
          commit("setAllowedValuesForAttributes", result);
        } else {
          commit("dialogs/setAllowedValuesError", true, { root: true });
        }
      } catch (err) {
        console.log(err);
        commit("dialogs/setAllowedValuesError", true, { root: true });
      }
    },
    async addEntityAttribute({ state, rootGetters, rootState, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL + VERSION + "/EntityAttributes/billingEntityAttribute?";

      const entityId = rootState.modifyEntity.modifiedEntity.id;
      if (entityId) {
        try {
          let response = await fetch(URL, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "POST",
            body: JSON.stringify({
              billingEntityId: entityId,
              attributeName: payload.attributeName,
              attributeValue: payload.attributeValue,
              isUpdateable: payload.isUpdateable
            })
          });
          if (response.ok) {
            let result = await response.json();
            state.modifiedEntityAttributes.push(result);
            return "OK";
          } else if (response.status == 400) {
            let result = await response.json();
            return result.message.split("\n")[0];
          } else {
            return "ERROR";
          }
        } catch (err) {
          console.log(err);
          return "ERROR";
        }
      } else {
        return "ERROR";
      }
    },
    async updateEntityAttribute({ state, rootGetters, rootState, commit, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/EntityAttributes/billingEntityAttribute/" +
        payload.id +
        "/update?";

      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      const entityId = rootState.modifyEntity.modifiedEntity.id;
      if (entityId) {
        try {
          let response = await fetch(URL, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "PUT",
            body: JSON.stringify({
              billingEntityId: entityId,
              attributeName: payload.attributeName,
              attributeValue: payload.attributeValue,
              isUpdateable: payload.isUpdateable
            })
          });
          if (response.ok) {
            let result = await response.json();
            let i = 0;
            if (state.modifiedEntityAttributes.length > 0) {
              while (payload.id != state.modifiedEntityAttributes[i].id) {
                i++;
              }
            }
            state.modifiedEntityAttributes.splice(i, 1);
            state.modifiedEntityAttributes.splice(i, 0, result);
            return "OK";
          } else if (response.status == 400) {
            let result = await response.json();
            return result.message.split("\n")[0];
          } else {
            return "ERROR";
          }
        } catch (err) {
          console.log(err);
          return "ERROR";
        } finally {
          dispatch("loading/endLoading", null, { root: true });
          finished = true;
        }
      } else {
        return "ERROR";
      }
    },
    async deleteEntityAttribute({ state, rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/EntityAttributes/billingEntityAttribute/" +
        payload.id;

      try {
        let response = await fetch(URL, {
          headers: {
            Authorization: TOKEN
          },
          method: "DELETE"
        });
        let result = await response.json();
        if (result) {
          let i = 0;
          if (state.modifiedEntityAttributes.length > 0) {
            while (payload.id != state.modifiedEntityAttributes[i].id) {
              i++;
            }
          }
          state.modifiedEntityAttributes.splice(i, 1);
        }
        return result;
      } catch (err) {
        console.log(err);
        return "ERROR";
      }
    },
    async getMinStartDateForAccount({ dispatch }, accountId) {
      let result = await dispatch("getMinAllowedStartDatesForAccounts", {
        accountIds: [accountId]
      });
      if (result && result.length > 0) {
        return result[0];
      } else {
        return false;
      }
    },
    async getMinStartDatesForAccounts({ rootState, dispatch }, payload) {
      let filterOptions = {};
      if (payload.runType === "Regular") {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.feeReviewFilters,
            searchParam: null,
            quickSearchParam: rootState.filters.quickSearchFeeReview
          },
          { root: true }
        );
      } else if (payload.runType === "Inception") {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.inceptionFilters,
            searchParam: null,
            quickSearchParam: rootState.filters.quickSearchInception
          },
          { root: true }
        );
      } else if (payload.runType === "Termination") {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.terminationFilters,
            searchParam: null,
            quickSearchParam: rootState.filters.quickSearchTermination
          },
          { root: true }
        );
      }
      payload = {
        ...payload,
        filterOptions: filterOptions
      };

      let result = await dispatch(
        "getMinAllowedStartDatesForAccounts",
        payload
      );
      if (result && result.length > 0) {
        return result;
      } else {
        return false;
      }
    },
    async getMinAllowedStartDatesForAccounts({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/EntityAttributes/GetMinAllowedStartDatesForAccounts?";
      try {
        let urlParams = {};
        let bodyPayload = { accountIds: payload.accountIds };
        if (payload.all) {
          urlParams = { all: true };
          bodyPayload = {
            ...bodyPayload,
            ...payload.filterOptions,
            billingRunType: payload.runType,
            billingPeriod: payload.billingPeriod
          };
        } else {
          urlParams = { all: false };
        }
        let response = await fetch(URL + new URLSearchParams(urlParams), {
          headers: {
            Authorization: TOKEN,
            "Content-Type": "application/json"
          },
          method: "PATCH",
          body: JSON.stringify(bodyPayload)
        });
        if (response.ok) {
          let result = await response.json();
          return result;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },
    /*async getMinAndMaxStartDateForAccount({ rootGetters, dispatch }, accountId) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL + VERSION + "/EntityAttributes/GetMinAndMaxAllowedStartDateForAccounts";

      try {
        let accountIds = [accountId];
        let response = await fetch(URL, {
          headers: {
            Authorization: TOKEN,
            "Content-Type": "application/json"
          },
          method: "PATCH",
          body: JSON.stringify(accountIds)
        });
        if (response.ok) {
          let result = await response.json();
          let res = result[0];
          return {
            minDate: res.minDate !== null ? res.minDate.slice(0, 10) : null,
            maxDate: res.maxDate !== null ? res.maxDate.slice(0, 10) : null
          };
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    },*/
  }
};
export default module;
