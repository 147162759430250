import cookies from "vue-cookies";

function initialState() {
  return {
    lastCall: null,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setLastCall(state, value) {
      state.lastCall = value;
    },
  },
  actions: {
    async getMissingAccounts({ commit, state, dispatch, rootState, rootGetters }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/GetMissingAccounts?";
      const signal = rootState.searchEntities.missingAccountsAbortController.signal;
      commit("setLastCall", payload);

      try {
        let response = await fetch(
          URL + new URLSearchParams(payload.urlParams),
          {
            method: "PATCH",
            headers: {
              Authorization: TOKEN,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(payload.filterOptions),
            signal: signal,
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (payload == state.lastCall) {
            return result;
          }
          return null;
        } else {
          throw "Response not ok.";
        }
      } catch (err) {
        if (!(err instanceof DOMException)) {
          console.error("getMissingAccounts error: " + err);
          return false;
        } else {
          return null;
        }
      }
    },
  }
};

export default module;
