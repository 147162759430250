import cookies from "vue-cookies";

function initialState() {
  return {
    statuses: [],
    feeFrequencies: [],
    accountStatuses: [],
    custodians: [],
    billables: [],
    entityTypes: [],
    isNewValues: [],
    billingFeeTypes: [],
    timeOfLastAdvancedSearch: null,
    timeOfLastEntityDataLoad: null,
  };
}

function timeDiff(date1, date2) {
  return date1.getTime() - date2.getTime();
}

const module = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setStatuses(state, value) {
      state.statuses = value;
    },
    setFeeFrequencies(state, frequencies) {
      frequencies.push("Not Set");
      state.feeFrequencies = frequencies;
    },
    setAccountStatuses(state, value) {
      state.accountStatuses = value;
    },
    setCustodians(state, value) {
      state.custodians = value;
    },
    setBillables(state, value) {
      state.billables = value;
    },
    setEntityTypes(state, values) {
      state.entityTypes = values;
    },
    setBillingFeeTypes(state, values) {
      state.billingFeeTypes = values;
    },
    setIsNewValues(state, values) {
      state.isNewValues = values;
    },
    setTimeOfLastAdvancedSearch(state) {
      state.timeOfLastAdvancedSearch = new Date();
    },
    setTimeOfLastEntityDataLoad(state) {
      state.timeOfLastEntityDataLoad = new Date();
    },
  },
  actions: {
    async loadEntitySearchPrepopulatedData({ commit, state, rootGetters, dispatch }) {
      const TOKEN = cookies.get("token");
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/Prepopulation/GetBillingEntitySearchPrepopulatedData";

      if (state.timeOfLastEntityDataLoad) {
        const now = new Date();
        const lastRun = new Date(state.timeOfLastEntityDataLoad);

        if (timeDiff(now, lastRun) < 600 * 1000) {
          return;
        }
      }

      try {
        let response = await fetch(URL, {
          headers: {
            Authorization: TOKEN
          }
        });

        if (response.ok) {
          let result = await response.json();

          commit("setEntityTypes", result.types);
          commit("setIsNewValues", result.isNewList);

          commit("setTimeOfLastEntityDataLoad");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async loadFeeReviewPrepopulatedData({ commit, state, rootGetters, dispatch }) {
      const TOKEN = cookies.get("token");
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Prepopulation/AdvancedSearch";

      if (state.timeOfLastAdvancedSearch) {
        const now = new Date();
        const lastRun = new Date(state.timeOfLastAdvancedSearch);

        if (timeDiff(now, lastRun) < 600 * 1000) {
          // If last prepopulation of AdvancedSearch fields was called less than 10 minutes ago, don't re-run it.
          // 600 s (* 1000 ms)
          return;
        }
      }

      try {
        let response = await fetch(URL, {
          headers: {
            Authorization: TOKEN
          }
        });

        if (response.ok) {
          let result = await response.json();
          commit("setStatuses", result.statuses);
          commit("setFeeFrequencies", result.feeFrequencies);
          commit("setAccountStatuses", result.accountStatuses);
          commit("setCustodians", result.custodians);
          commit("setBillingFeeTypes", result.billingFeeTypes);
          commit("setBillables", result.billableStatuses);

          commit("setTimeOfLastAdvancedSearch");
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};

export default module;