import cookies from "vue-cookies";

function initialState() {
  return {
    feeReviewTableHeadersSetting: null,
    feeReviewTableSettingName: "FeeReviewTableHeaders",
    inceptionTableHeadersSetting: null,
    inceptionTableSettingName: "InceptionTableHeaders",
    terminationTableHeadersSetting: null,
    terminationTableSettingName: "TerminationTableHeaders",
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setFeeReviewTableHeadersSetting(state, value) {
      state.feeReviewTableHeadersSetting = value;
    },
    setInceptionTableHeadersSetting(state, value) {
      state.inceptionTableHeadersSetting = value;
    },
    setTerminationTableHeadersSetting(state, value) {
      state.terminationTableHeadersSetting = value;
    },
  },
  actions: {
    async setHeaders({ state, commit, dispatch }, nameOfSetting) {
      let commitName;
      switch (nameOfSetting) {
        case state.feeReviewTableSettingName:
          commitName = "setFeeReviewTableHeadersSetting";
          break;
        case state.inceptionTableSettingName:
          commitName = "setInceptionTableHeadersSetting";
          break;
        case state.terminationTableSettingName:
          commitName = "setTerminationTableHeadersSetting";
          break;
        default:
          console.log("setHeaders error: Wrong setting name!");
          return false;
      }
      let result = await dispatch("getUserSetting", nameOfSetting);
      commit(commitName, result);
      return result;
    },
    async getUserSetting({ rootGetters, dispatch }, settingName) {
      const TOKEN = cookies.get("token");
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/UserSettings/GetUserSetting?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              settingName: settingName
            }),
          {
            headers: {
              Authorization: TOKEN
            },
            method: "GET"
          }
        );

        if (response.ok && response.status !== 204) {
          let result = await response.json();
          return result;
        } else if (response.status == 204) {
          return null;
        } else if (response.status == 400) {
          console.warn(await response.text());
          return null;
        } else {
          console.error(response.statusText);
          return null;
        }
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    async saveHeaders({ state, commit, dispatch }, payload) {
      let result = await dispatch("saveUserSetting", {
        settingName: payload.nameOfSetting,
        settingValue: JSON.stringify(payload.settingValue)
      });
      if (result) {
        switch (payload.nameOfSetting) {
          case state.feeReviewTableSettingName:
            commit("setFeeReviewTableHeadersSetting", result);
            break;
          case state.inceptionTableSettingName:
            commit("setInceptionTableHeadersSetting", result);
            break;
          case state.terminationTableSettingName:
            commit("setTerminationTableHeadersSetting", result);
            break;
        }
      }
    },
    async saveUserSetting({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/UserSettings/AddOrUpdateUserSetting?";

      try {
        let response = await fetch(URL, {
          headers: {
            Authorization: TOKEN,
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          method: "PUT",
          body: JSON.stringify({
            settingName: payload.settingName,
            settingValue: payload.settingValue
          })
        });

        if (response.ok) {
          let result = await response.json();
          return result;
        } else {
          console.error(response.statusText);
          return false;
        }
      } catch (err) {
        console.error(err);
        return false;
      }
    },
  }
};

export default module;
