import cookies from "vue-cookies";

function initialState() {
  return {
    accounts: [],
    totalAccounts: 0,
    totalDistinctAccounts: 0,
    selectedAccounts: [],
    feeResultsAllSelected: false,
    totalSelectedAccounts: 0,
    pagination: {
      page: 1,
      itemsPerPage: 25,
      sortBy: [],
      sortDesc: []
    },
    selectedDate: null,
    maxItems: 2000,
    lastCall: null,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setAccounts(state, value) {
      value.forEach(element => {
        element.tableId = element.id + element.billingRunType;
      });
      state.accounts = value;
    },
    setTotalAccounts(state, value) {
      state.totalAccounts = value;
    },
    setTotalDistinctAccounts(state, value) {
      state.totalDistinctAccounts = value;
    },
    setSelectedAccounts(state, value) {
      state.selectedAccounts = value;
    },
    resetSelectedAccounts(state) {
      state.selectedAccounts = initialState().selectedAccounts;
    },
    setFeeResultsAllSelected(state, value) {
      state.feeResultsAllSelected = value;
    },
    setTotalSelectedAccounts(state, value) {
      state.totalSelectedAccounts = value;
    },
    setPagination(state, value) {
      state.pagination = value;
    },
    setPage(state, value) {
      let temp = state.pagination;
      temp.page = value;
      state.pagination = temp;
    },
    setPageSize(state, value) {
      let temp = state.pagination;
      temp.itemsPerPage = value;
      state.pagination = temp;
    },
    resetPagination(state) {
      state.pagination = initialState().pagination;
    },
    resetPage(state) {
      let temp = state.pagination;
      temp.page = initialState().pagination.page;
      state.pagination = temp;
    },
    resetPageSize(state) {
      let temp = state.pagination;
      temp.itemsPerPage = initialState().pagination.itemsPerPage;
      state.pagination = temp;
    },
    setSelectedDate(state, value) {
      state.selectedDate = value;
    },
    setLastCall(state, value) {
      state.lastCall = value;
    },
  },
  getters: {
    getSelectedDate(state) {
      return state.selectedDate;
    },
    getCurrentBillingPeriod(state) {
      if (state.selectedDate) {
        let [year, month] = state.selectedDate.split("-");

        year = parseInt(year);
        month = parseInt(month);

        return year * 100 + month;
      }
    },
    getPreviousBillingPeriod(state) {
      if (state.selectedDate) {
        let [year, month] = state.selectedDate.split("-");

        year = parseInt(year);
        month = parseInt(month);

        let prevMonth = (month - 1) % 12;
        if (prevMonth === 0) {
          prevMonth = 12;
        }

        if (prevMonth > month) {
          return (year - 1) * 100 + prevMonth;
        } else {
          return year * 100 + prevMonth;
        }
      }
    },
  },
  actions: {
    async feeReviewSearch({ rootState, commit, dispatch, getters }, payload) {
      commit("searchEntities/cancelFeeReviewSearch", null, { root: true });
      commit(
        "searchEntities/setFeeReviewAbortController",
        new AbortController(),
        { root: true }
      );

      const billingPeriod = getters.getPreviousBillingPeriod;
      let filterOptions = [];
      try {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.feeReviewFilters,
            searchParam: payload.searchParam,
            quickSearchParam: rootState.filters.quickSearchFeeReview
          },
          { root: true }
        );
      } catch (err) {
        console.error("Error: feeReviewSearch -> getFilterOptions: " + err);
        return false;
      }

      let urlParams = {
        page: payload.page,
        pageSize: payload.pageSize,
        billingPeriod: billingPeriod
      };
      if (payload.sortBy !== null && payload.sortBy !== undefined) {
        urlParams = { ...urlParams, sortKey: payload.sortBy };
      }
      if (
        payload.sortDirection !== null &&
        payload.sortDirection !== undefined
      ) {
        urlParams = { ...urlParams, sortDirection: payload.sortDirection };
      }

      let result = await dispatch("advancedBillingAccountSearch", {
        urlParams: urlParams,
        filterOptions: filterOptions
      });
      return result;
    },
    async advancedBillingAccountSearch({ commit, state, dispatch, rootState, rootGetters }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/AdvancedBillingAccountSearch?";
      const signal = rootState.searchEntities.feeReviewAbortController.signal;
      commit("setLastCall", payload);

      try {
        let response = await fetch(
          URL + new URLSearchParams(payload.urlParams),
          {
            method: "PATCH",
            headers: {
              Authorization: TOKEN,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(payload.filterOptions),
            signal: signal,
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (payload == state.lastCall) {
            await commit("setAccounts", result.data.responseData);
            await commit(
              "setTotalAccounts",
              result.data.metaData.totalRecordCount
            );
            await commit(
              "setTotalDistinctAccounts",
              result.data.metaData.totalDistinctRecordsCount
            );
          }
          return true;
        } else if (response.status == 409) {
          await commit("setAccounts", []);
          await commit("setTotalAccounts", 0);
          await commit("setTotalDistinctAccounts", 0);
          return { errorMessage: await response.text() };
        } else {
          throw "Response not ok.";
        }
      } catch (err) {
        if (!(err instanceof DOMException)) {
          console.error("advancedBillingAccountSearch error: " + err);
          await commit("setAccounts", []);
          await commit("setTotalAccounts", 0);
          await commit("setTotalDistinctAccounts", 0);
          return false;
        } else {
          return true;
        }
      }
    },
    async getFeeReviewMissingAccounts({ rootState, getters, commit, dispatch }) {
      commit("searchEntities/cancelMissingAccountsSearch", null, {
        root: true
      });
      commit(
        "searchEntities/setMissingAccountsAbortController",
        new AbortController(),
        { root: true }
      );

      const billingPeriod = getters.getPreviousBillingPeriod;
      let filterOptions = [];
      try {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.feeReviewFilters,
            quickSearchParam: rootState.filters.quickSearchFeeReview
          },
          { root: true }
        );
      } catch (err) {
        console.error("Error: getFeeReviewMissingAccounts -> getFilterOptions: " + err);
        return false;
      }

      let urlParams = {
        billingPeriod: billingPeriod,
        billingRunType: "Regular"
      };

      let result = await dispatch(
        "accountsBilling/getMissingAccounts",
        { urlParams: urlParams, filterOptions: filterOptions },
        { root: true }
      );
      return result;
    },
  }
};

export default module;
