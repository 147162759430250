<template>
  <v-img
    :src="logoImg"
    alt="Logo"
    contain
    max-height="50"
    max-width="200"
  ></v-img>
</template>

<script>
export default {
  data: () => ({
    isFestive: null,
  }),
  computed: {
    logoImg() {
      let img = "BillPro_v1";

      /*if (this.isFestive) {
        switch (this.isFestive) {
          case "xmas":
            img = "smartx_logo_xmas";
            break;
        }
      }*/

      var images = require.context("@/assets/", false, /\.png$/);
      return images("./" + img + ".png");
    },
  },
  beforeMount() {
    const today = new Date();

    const xmasStart = new Date(today.getFullYear(), 11, 22);
    const xmasEnd = new Date(today.getUTCFullYear(), 0, 3);

    if (today >= xmasStart || today <= xmasEnd) {
      this.isFestive = "xmas";
    }
  },
};
</script>
