<template>
  <v-dialog persistent v-model="show" max-width="600px">
    <v-card>
      <v-card-title>
        Production Environment Notice
      </v-card-title>
      <v-card-text>
        This is the production environment with live customer data. Proceed with
        caution. Consider logging out to use the Staging Environment or QA
        Environment instead.
      </v-card-text>
      <v-card-actions class="pt-5">
        <v-btn
          class="mb-2 ml-2"
          dark
          :color="colorTheme.button"
          @click="logOut"
        >
          Log Out
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="mb-2 mr-2"
          dark
          :color="colorTheme.button"
          @click="proceed"
        >
          Proceed
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  data: () => ({}),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("dialogs", ["productionNotice"]),
    show: {
      get() {
        return this.productionNotice;
      },
      set(newValue) {
        this.setProductionNotice(newValue);
      }
    },
  },
  methods: {
    ...mapMutations("dialogs", ["setProductionNotice"]),
    ...mapActions("auth", ["signOut"]),
    async logOut() {
      await this.signOut();
      this.cancel();
    },
    proceed() {
      this.cancel();
    },
    cancel() {
      this.show = false;
    },
  }
};
</script>
