<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card>
      <div class="daily-activity_dialog">
        <v-card-text style="padding: 0">
          <p class="import-dialog-header">Background Activity in Progress</p>
          <p class="pt-4">
            {{ message }}
          </p>
          <v-progress-linear
            v-if="isCompleted"
            color="#006B5B"
            height="10"
            value="100"
          ></v-progress-linear>
          <v-progress-linear
            v-else
            color="#006B5B"
            height="10"
            model-value="10"
            indeterminate
          ></v-progress-linear>
          <div class="pt-4  import-progress">
            <p class="mb-2">Import progress</p>
            <p class="import-progress_text">
              {{ dailyImportCompletePercentage }}%
            </p>
          </div>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { finishedWithinLastXSeconds } from "@/utils/format-util.js";

export default {
  data: () => ({
    dailyImportCompletePercentage: 0,
    intervalId: null,
    infoMessage: `Oops, looks like you’ve caught us in the middle of a Daily Import. 
                  There is nothing you need to do. You can even close the software or navigate 
                  to another tab in your browser. We’ll let you know when SmartX Bill Pro is ready to use.`,
    completedMessage: `The Daily import is now in it's final phase and it will be completed in a few seconds.
                      Thank you for waiting and for your understanding during this process.`
  }),
  computed: {
    ...mapState("backgroundMessages", [
      "showDailyActivity",
      "latestActivity",
      "dailyImportSchedule"
    ]),
    isCompleted() {
      return this.dailyImportCompletePercentage === 100;
    },
    message() {
      if (this.isCompleted) {
        return this.completedMessage;
      }
      return this.infoMessage;
    },
    show: {
      get() {
        return this.showDailyActivity;
      },
      set(newValue) {
        this.setShowDailyActivity(newValue);
      },
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.startCalculatingPercentage();
      } else {
        this.stopCalculatingPercentage();
      }
    }
  },
  methods: {
    ...mapMutations("backgroundMessages", ["setShowDailyActivity"]),
    startCalculatingPercentage() {
      clearInterval(this.intervalId);
      this.calculate();
      this.intervalId = setInterval(this.calculate, 1000);
    },
    calculate() {
      if (!this.$cookies.isKey("token") || !this.dailyImportSchedule || !this.latestActivity) {
        return;
      }
      if (this.latestActivity.status === "Succeeded") {
        this.stopCalculatingPercentage();
        if (finishedWithinLastXSeconds(this.latestActivity.finishedAt, 60)) {
          this.dailyImportCompletePercentage = 100;
          setTimeout(() => {
            this.show = false;
          }, 10000);
        } else {
          this.show = false;
        }
        return;
      }
      const expectedDuration = this.dailyImportSchedule.expectedDurationInSeconds * 1000; //Expected duration in ms
      const currentTime = new Date();
      const startTime = new Date(this.latestActivity.startedAt + "Z"); // "Z" means it's treated as UTC
      let spentTime = currentTime - startTime;

      let percentage = Math.floor((spentTime / expectedDuration) * 100);
      if (percentage > 99) {
        percentage = 99;
      }
      this.dailyImportCompletePercentage = percentage;
    },
    stopCalculatingPercentage() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
  },
  mounted() {
    if (this.show) {
      this.startCalculatingPercentage();
    }
  }
};
</script>
<style>
.daily-activity_dialog {
  height: 300px;
  border-radius: 8px;
  padding: 24px;
}
.import-progress {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.import-progress_text {
  font-size: 32px;
  color: #006B5B;
}
.import-dialog-header {
  font-size: 24px;
  font-weight: 400;
}
</style>
