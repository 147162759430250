let organizationRules = {
  "AmericanPortfolios": {
    isProduction: true,
    defaultBillingTypeEnabled: false,
    exportFileExtension: "txt",
    s3BucketPrefix: "ap",
    useBackgroundActivity: true,
  },
  "Staging": {
    isProduction: false,
    defaultBillingTypeEnabled: true,
    exportFileExtension: "txt",
    s3BucketPrefix: "dev",
    useBackgroundActivity: true,
  },
  "SmartX": {
    isProduction: true,
    defaultBillingTypeEnabled: true,
    exportFileExtension: "csv",
    s3BucketPrefix: "smartx",
    useBackgroundActivity: true,
  },
  "Apollon": {
    isProduction: true,
    defaultBillingTypeEnabled: true,
    exportFileExtension: "csv",
    s3BucketPrefix: "apollon",
    useBackgroundActivity: true,
  },
  "ApollonStaging": {
    isProduction: false,
    defaultBillingTypeEnabled: true,
    exportFileExtension: "csv",
    s3BucketPrefix: "apollon-stg",
    useBackgroundActivity: true,
  },
  "QA": {
    isProduction: false,
    defaultBillingTypeEnabled: true,
    exportFileExtension: "csv",
    s3BucketPrefix: "qa",
    useBackgroundActivity: false,
  },
  "Dev": {
    isProduction: false,
    defaultBillingTypeEnabled: true,
    exportFileExtension: "csv",
    s3BucketPrefix: "dev",
    useBackgroundActivity: true,
  },
};

function defaultBillingTypeEnabled(organization) {
  if (organizationRules[organization].defaultBillingTypeEnabled) {
    return true;
  } else {
    return false;
  }
}

function exportFileExtension(organization) {
  return organizationRules[organization].exportFileExtension;
}

function isProduction(organization) {
  if (!organization) {
    return false;
  }
  return organizationRules[organization].isProduction;
}

function getS3BucketPrefix(organization) {
  return organizationRules[organization].s3BucketPrefix;
}

function useBackgroundActivity(organization) {
  if (!organization) {
    return false;
  }
  return organizationRules[organization].useBackgroundActivity;
}

module.exports = {
  defaultBillingTypeEnabled,
  exportFileExtension,
  isProduction,
  getS3BucketPrefix,
  useBackgroundActivity,
};
