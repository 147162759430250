function initialState() {
  return {
    elementsLoading: false,
    numberOfLoadings: 0
  };
}
const module = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setElementsLoading(state, value) {
      state.elementsLoading = value;
    },
    setNumberOfLoadings(state, value) {
      state.numberOfLoadings = value;
    },
    resetNumberOfLoadings(state) {
      state.numberOfLoadings = 0;
    },
    incrementNumberOfLoadings(state) {
      state.numberOfLoadings += 1;
    },
    decrementNumberOfLoadings(state) {
      state.numberOfLoadings -= 1;
    }
  },
  actions: {
    endLoading({ state, commit }) {
      commit("decrementNumberOfLoadings");
      if (state.numberOfLoadings == 0) {
        commit("setElementsLoading", false);
      }
    },
    resetAllLoadings({ commit }) {
      commit("setNumberOfLoadings", 0);
      commit("setElementsLoading", false);
    },
  }
};
export default module;
