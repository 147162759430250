import cookies from "vue-cookies";

function initialState() {
  return {};
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async getInvoiceHistory({ commit, rootGetters, rootState, dispatch }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/BillingStatus/GetFullInvoiceHistoryWithBillingStatusNotes?";

      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              invoiceId: rootState.invoiceScreen.selectedInvoices[0].id
            }),
          {
            headers: {
              Authorization: TOKEN
            }
          }
        );

        let result = await response.json();
        return result;
      } catch (error) {
        commit("accountStatus/setStatusesError", true, { root: true });
        console.log(error);
      } finally {
        dispatch("loading/endLoading", null, { root: true });
        finished = true;
      }
    }
  }
};
export default module;
