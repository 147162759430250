import cookies from "vue-cookies";
import router from "@/router";
import { equalObjects } from "@/utils/object-operations.js";

function initialState() {
  return {
    entities: [],
    rootId: "",
    numberOfChildren: 0,
    parentEntity: null,
    lastCall: null,
  };
}
const module = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setEntities(state, entities) {
      state.entities = entities;
    },
    setRootId(state, value) {
      state.rootId = value;
    },
    setNumberOfChildren(state, value) {
      state.numberOfChildren = value;
    },
    setParentEntity(state, parentEntity) {
      state.parentEntity = parentEntity;
    },
    setLastCall(state, value) {
      state.lastCall = value;
    },
  },
  actions: {
    async setDefaultHierarchy({ dispatch, rootState }, address) {
      let tags = rootState.hierarchyMode.hierarchyTags;
      let i = 0;
      while (i < tags.length && tags[i].address != address) {
        i++;
      }
      if (i == tags.length) {
        return "Error with setting hierarchy";
      } else {
        await dispatch("hierarchyMode/setHierarchyTag", tags[i], {
          root: true
        });
        return "";
      }
    },
    async setDefaultNavigation({ rootGetters, dispatch }, entityId) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];

      let id;
      let rootId;
      try {
        rootId = await dispatch("getRootEntityId");
      } catch (err) {
        return "ROOT_ERROR";
      }
      if (entityId !== "root" && entityId != rootId) {
        id = entityId;
      } else {
        id = rootId;
      }
      try {
        const entityurl = BASE_URL + VERSION + "/Entity/billingEntity/" + id;
        var response = await fetch(entityurl, {
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          }
        });

        if (!response.ok) {
          return "Entity with ID '" + entityId + "' not found!";
        }
        var navItem = await response.json();
        let error = await dispatch(
          "directoryNavigation/setNewNavigation",
          { navItem, rootId },
          { root: true }
        );
        if (error !== "") {
          return error;
        }
      } catch (err) {
        return err;
      }
      return "";
    },
    async setDefaultValues({ commit, dispatch }, payload) {
      let result = true;
      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      commit("directoryNavigation/setLastIdInNavigation", payload.id, {
        root: true
      });
      await dispatch("resetValues", payload);
      try {
        let error;
        await dispatch("entityActions/setTab", payload.tabName, { root: true });
        error = await dispatch("setDefaultHierarchy", payload.hierarchy);
        if (error !== "") {
          result = error;
        } else {
          error = await dispatch("setDefaultNavigation", payload.id);
          if (error !== "") {
            result = error;
          }
        }
      } catch (err) {
        result = err;
      }

      dispatch("loading/endLoading", null, { root: true });
      finished = true;

      if (result === true) {
        return true;
      } else {
        console.log(result);
        if (result === "ROOT_ERROR") {
          router.replace({ name: "RootNotFound" });
        } else {
          router.replace({ name: "EntityNotFound" });
        }
        return false;
      }
    },
    async resetValues({ commit, dispatch }, payload) {
      commit("modifyEntityGroup/setLastCall", payload, { root: true });
      switch (payload.tabName) {
        case "HierarchyTree":
          commit("setEntities", []);
          commit("setNumberOfChildren", 0);
          break;
        case "ModifyEntity":
          commit("entityAttributes/setModifiedEntityAttributes", [], {
            root: true
          });
          await dispatch(
            "modifyEntityGroup/setGroupAccountsAsync",
            { callParams: payload, accounts: [] },
            { root: true }
          );
          break;
        case "FeeSchedules":
          commit("feeSchedule/setFees", [], { root: true });
          commit("feeSchedule/setVisibleFees", [], { root: true });
          break;
        case "AddGroup":
          await dispatch(
            "modifyEntityGroup/setGroupAccountsAsync",
            { callParams: payload, accounts: [] },
            { root: true }
          );
          break;
        default:
          console.log("Wrong tab name, there might be some errors!!!");
      }
    },
    async getEntityParent({ rootState, state, rootGetters, dispatch }, entityId) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/GetEntityParent?";

      if (entityId === state.rootId) {
        return entityId;
      }
      let response = await fetch(
        URL +
          new URLSearchParams({
            entity: entityId,
            relationshipType: rootState.hierarchyMode.hierarchyTag.val
          }),
        {
          headers: {
            Authorization: TOKEN
          }
        }
      );
      if (!response.ok) {
        return null;
      }
      let result = await response.json();
      return result;
    },
    async hierarchyTreeSearch({ rootState, commit, dispatch }, payload) {
      commit("searchEntities/cancelHierarchyTreeSearch", null, { root: true });
      commit(
        "searchEntities/setHierarchyTreeAbortController",
        new AbortController(),
        { root: true }
      );

      let filterOptions = [];
      try {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.hierarchyTreeFilters,
            searchParam: payload.searchParam,
            quickSearchParam: rootState.filters.quickSearchHierarchyTree
          },
          { root: true }
        );
      } catch (err) {
        console.error("Error: hierarchyTreeSearch -> getFilterOptions: " + err);
        return false;
      }

      let entityId = "";
      if (payload.entityId === "" || payload.entityId === "root") {
        entityId = await dispatch("getRootEntityId");
      } else {
        entityId = payload.entityId;
      }

      let urlParams = {
        entityId: entityId,
        relationshipType: rootState.hierarchyMode.hierarchyTag.val,
        page: payload.page,
        pageSize: payload.pageSize,
      };
      if (payload.sortBy !== null && payload.sortBy !== undefined) {
        urlParams = { ...urlParams, sortKey: payload.sortBy };
      }
      if (
        payload.sortDirection !== null &&
        payload.sortDirection !== undefined
      ) {
        urlParams = { ...urlParams, sortDirection: payload.sortDirection };
      }

      let result = await dispatch("getEntityChildrenFiltered", {
        urlParams: urlParams,
        filterOptions: filterOptions
      });
      return result;
    },
    async getEntityChildrenFiltered({ commit, dispatch, state, rootGetters, rootState }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/GetEntityChildrenFiltered?";
      const signal = rootState.searchEntities.hierarchyTreeAbortController.signal;
      commit("setLastCall", payload);

      try {
        let response = await fetch(
          URL + new URLSearchParams(payload.urlParams),
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            body: JSON.stringify(payload.filterOptions),
            signal: signal,
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (equalObjects(payload, state.lastCall)) {
            const entityurl =
              BASE_URL +
              VERSION +
              "/Entity/billingEntity/" +
              payload.urlParams.entityId;
            let entityresponse = await fetch(entityurl, {
              headers: {
                "Content-Type": "application/json",
                Authorization: TOKEN
              }
            });
            let entityData = await entityresponse.json();
            await commit("setParentEntity", entityData);
            commit(
              "setNumberOfChildren",
              result.data.metaData.totalRecordCount
            );
            await commit("setEntities", result.data.responseData);
            await dispatch("hierarchyTree/setHeaders", null, { root: true });
          }
          return true;
        } else {
          throw "Response not ok.";
        }
      } catch (err) {
        if (!(err instanceof DOMException)) {
          console.error("getEntityChildrenFiltered error: " + err);
          commit("setNumberOfChildren", 0);
          await commit("setEntities", []);
          return false;
        } else {
          return true;
        }
      }
    },
    async getRootEntity({ commit, dispatch, rootGetters, rootState }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/GetRootEntity?";

      let response;
      try {
        response = await fetch(
          URL +
            new URLSearchParams({
              relationshipType: rootState.hierarchyMode.hierarchyTag.val
            }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: TOKEN
            }
          }
        );
      } catch (err) {
        console.log(err);
        return false;
      }
      let result;
      try {
        result = await response.json();
      } catch (err) {
        console.log(err);
        return false;
      }
      const rootElem = result[0];
      try {
        rootState.directoryNavigation.navigation = [];
        commit(
          "directoryNavigation/addNavItem",
          {
            id: rootElem.id,
            text: rootElem.name,
            type: rootElem.type,
            disabled: false
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        return false;
      }
      try {
        let payload = {
          entityId: rootElem.id,
          page: 1,
          pageSize: rootState.hierarchyTree.pagination.itemsPerPage
        };
        await dispatch("hierarchyTreeSearch", payload);
      } catch (err) {
        console.log(err);
        return false;
      }
      return true;
    },
    async getRootEntityId({ commit, dispatch, rootGetters, rootState }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/GetRootEntity?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              relationshipType: rootState.hierarchyMode.hierarchyTag.val
            }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: TOKEN
            }
          }
        );
        if (response.ok) {
          let result = await response.json();
          const rootElem = result[0];
          commit("setRootId", rootElem.id);
          return rootElem.id;
        } else {
          throw "Response error";
        }
      } catch (err) {
        throw "getRootEntityId error - " + err;
      }
    }
  }
};
export default module;
