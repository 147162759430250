function initialState() {
  return {
    dealsModelSearchTypes: [
      "ADVISORY_FIRM",
      "ADVISORY_MANAGEMENT_FIRM",
      "ACCOUNT_GROUP",
      "ACCOUNT"
    ],
    dealsAccountSearchTypes: [
      "MANAGEMENT_FIRM",
      "ADVISORY_MANAGEMENT_FIRM",
      "MODEL"
    ]
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {}
};

export default module;
