function initialState() {
  return {
    accountMultiPeriodHeaders: [
      {
        text: "ID",
        value: "accountId",
        sortable: false,
        forExport: true,
        exportText: "Account ID",
      },
      {
        text: "Name",
        value: "accountName",
        sortable: false,
        forExport: true,
        exportText: "Account Name",
      },
      {
        text: "Billing Type",
        value: "billingType",
        sortable: false,
        forExport: true,
        exportText: "Billing Type",
      },
      {
        text: "Rep Code",
        value: "repCode",
        sortable: false,
        forExport: true,
        exportText: "Rep Code",
      },
      {
        text: "Billing Run Type",
        value: "billingRunType",
        sortable: false,
        forExport: true,
        exportText: "Billing Run Type",
      },
      {
        text: "Paid By",
        value: "paidBy",
        sortable: false,
        forExport: true,
        exportText: "Paid By",
        dontShow: true,
      },
      {
        text: "",
        value: "data-table-expand",
        align: "right",
        forExport: false,
      },
    ],
    accountInPeriodHeaders: [
      {
        text: "Period Key",
        value: "periodKey",
        sortable: false,
        forExport: false,
        exportText: "periodKey",
        dontShow: true,
      },
      {
        text: "Period",
        value: "billingPeriod",
        sortable: false,
        forExport: true,
        exportText: "Period",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        forExport: false,
        exportText: "Status",
      },
      {
        text: "AUM",
        value: "totalAumAndDate.aum",
        sortable: false,
        align: "end",
        forExport: true,
        exportText: "AUM",
      },
      {
        text: "AUM Date",
        value: "totalAumAndDate.aumDate",
        sortable: false,
        forExport: true,
        exportText: "AUM Date",
      },
      {
        text: "Period Start",
        value: "displayFromDate",
        sortable: false,
        forExport: true,
        exportText: "Period Start",
      },
      {
        text: "Period End",
        value: "displayToDate",
        sortable: false,
        forExport: true,
        exportText: "Period End",
      },
      {
        text: "Fee",
        value: "fee",
        sortable: false,
        forExport: true,
        exportText: "Fee",
      },
      {
        text: "",
        value: "data-table-expand",
        align: "right",
        forExport: false,
      },
    ],
    accountHeaders: [
      {
        text: "ID",
        value: "accountId",
        sortable: false,
        forExport: true,
        exportText: "Account ID",
      },
      {
        text: "Name",
        value: "accountName",
        sortable: false,
        forExport: true,
        exportText: "Account Name",
      },
      {
        text: "Billing Type",
        value: "billingType",
        sortable: false,
        forExport: true,
        exportText: "Billing Type",
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
        forExport: false,
        exportText: "Status",
      },
      {
        text: "AUM",
        value: "totalAumAndDate.aum",
        sortable: false,
        align: "end",
        forExport: true,
        exportText: "AUM",
      },
      {
        text: "AUM Date",
        value: "totalAumAndDate.aumDate",
        sortable: false,
        forExport: true,
        exportText: "AUM Date",
      },
      {
        text: "Period Start",
        value: "displayFromDate",
        sortable: false,
        forExport: true,
        exportText: "Period Start",
      },
      {
        text: "Period End",
        value: "displayToDate",
        sortable: false,
        forExport: true,
        exportText: "Period End",
      },
      {
        text: "Rep Code",
        value: "repCode",
        sortable: false,
        forExport: true,
        exportText: "Rep Code",
      },
      {
        text: "Paid By",
        value: "paidBy",
        sortable: false,
        forExport: true,
        exportText: "Paid By",
        dontShow: true,
      },
      {
        text: "Fee",
        value: "fee",
        sortable: false,
        forExport: true,
        exportText: "Fee",
      },
      {
        text: "Billing Run Type",
        value: "billingRunType",
        sortable: false,
        forExport: true,
        exportText: "Billing Run Type",
      },
      {
        text: "",
        value: "data-table-expand",
        align: "right",
        forExport: false,
      },
    ],
    feeHeaders: [
      {
        text: "Fee Type",
        value: "name",
        sortable: false,
        forExport: true,
        exportText: "Fee Type",
      },
      {
        text: "Effective Rate",
        value: "effectiveRate",
        sortable: false,
        forExport: true,
        exportText: "Effective Rate",
      },
      {
        text: "Top Tier Rate",
        value: "topTierRate",
        sortable: false,
        forExport: true,
        exportText: "Top Tier Rate",
      },
      {
        text: "AUM Basis",
        value: "aumBasis",
        sortable: false,
        align: "end",
        forExport: true,
        exportText: "AUM Basis",
      },
      {
        text: "AUM",
        value: "aum",
        sortable: false,
        align: "end",
        forExport: true,
        exportText: "AUM",
      },
      {
        text: "Fee",
        value: "fee",
        sortable: false,
        align: "end",
        forExport: true,
        exportText: "Fee",
      },
      {
        text: "",
        value: "data-table-expand",
        align: "right",
        forExport: false,
      },
    ],
    sleeveHeaders: [
      {
        text: "Sleeve",
        value: "sleeveName",
        sortable: false,
        forExport: true,
        exportText: "Sleeve",
      },
      {
        text: "",
        value: "data-table-expand",
        align: "right",
        forExport: false,
      },
    ],
    billingRecordHeaders: [
      {
        text: "Info",
        value: "feeDetails",
        sortable: false,
        forExport: false,
      },
      {
        text: "Fee Schedule Name",
        value: "schedule.name",
        sortable: false,
        forExport: true,
        exportText: "Fee Schedule Name",
      },
      {
        text: "Inherited from",
        value: "inheritedFrom",
        sortable: false,
        forExport: true,
        exportText: "Inherited from",
      },
      {
        text: "Billing Type",
        value: "billingRecord.billingType",
        sortable: false,
        forExport: true,
        exportText: "Billing Type",
      },
      {
        text: "AUM Basis",
        value: "entityAumBasis",
        sortable: false,
        align: "end",
        forExport: true,
        exportText: "AUM Basis",
      },
      {
        text: "Avg Fee Perc",
        value: "averageFeePercent",
        sortable: false,
        forExport: true,
        exportText: "Avg Fee Perc",
      },
      {
        text: "AUM",
        value: "entityAum",
        sortable: false,
        align: "end",
        forExport: true,
        exportText: "AUM",
      },
      {
        text: "AUM Date",
        value: "billingRecord.aumDate",
        sortable: false,
        forExport: true,
        exportText: "AUM Date",
      },
      {
        text: "Fee Amount",
        value: "thisPeriodNetFeeAmount",
        sortable: false,
        align: "end",
        forExport: true,
        exportText: "Fee Amount",
      },
      {
        text: "Adjustments",
        value: "adjustments",
        sortable: false,
        forExport: false,
      },
      {
        text: "Preferred Billing Record",
        value: "preferred",
        align: "center",
        sortable: false,
        forExport: true,
        exportText: "Preferred Billing Record",
      },
    ],
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {}
};

export default module;
