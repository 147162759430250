import { deepCopy } from "@/utils/object-operations.js";

function initialState() {
  return {
    hierarchyTreeFilters: [],
    quickSearchHierarchyTree: null,
    closeHierarchyTreeFiltersCard: false,
    feeReviewFilters: [],
    quickSearchFeeReview: null,
    inceptionFilters: [
      {
        column: "accountStatus",
        predicate: "is",
        text: "Account Status",
        value: "active"
      }
    ],
    defaultInceptionFilters: [
      {
        column: "accountStatus",
        predicate: "is",
        text: "Account Status",
        value: "active"
      }
    ],
    quickSearchInception: null,
    terminationFilters: [],
    quickSearchTermination: null,
  };
}
const module = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setHierarchyTreeFilters(state, value) {
      state.hierarchyTreeFilters = value;
    },
    resetHierarchyTreeFilters(state) {
      state.hierarchyTreeFilters = [];
    },
    setQuickSearchHierarchyTree(state, value) {
      state.quickSearchHierarchyTree = value;
    },
    resetQuickSearchHierarchyTree(state) {
      state.quickSearchHierarchyTree = null;
    },
    closeHierarchyTreeFilters(state) {
      state.closeHierarchyTreeFiltersCard = true;
      setTimeout(() => {
        state.closeHierarchyTreeFiltersCard = false;
      });
    },
    setFeeReviewFilters(state, value) {
      state.feeReviewFilters = value;
    },
    resetFeeReviewFilters(state) {
      state.feeReviewFilters = [];
    },
    setQuickSearchFeeReview(state, value) {
      state.quickSearchFeeReview = value;
    },
    resetQuickSearchFeeReview(state) {
      state.quickSearchFeeReview = null;
    },
    setInceptionFilters(state, value) {
      state.inceptionFilters = value;
    },
    resetInceptionFilters(state) {
      state.inceptionFilters = deepCopy(state.defaultInceptionFilters);
    },
    setQuickSearchInception(state, value) {
      state.quickSearchInception = value;
    },
    resetQuickSearchInception(state) {
      state.quickSearchInception = null;
    },
    setTerminationFilters(state, value) {
      state.terminationFilters = value;
    },
    resetTerminationFilters(state) {
      state.terminationFilters = [];
    },
    setQuickSearchTermination(state, value) {
      state.quickSearchTermination = value;
    },
    resetQuickSearchTermination(state) {
      state.quickSearchTermination = null;
    },
  },
  actions: {
    async getFilterOptions(context, { predicates, searchParam, quickSearchParam }) {
      const appliedFilter = predicates;
      let searchPredicates = [];
      appliedFilter.forEach((filter) => {
        if (searchPredicates.find((x) => x.column === filter.column) === undefined) {
          searchPredicates.push({
            column: filter.column,
            is: [],
            contains: [],
            inGroup: [],
            has: [],
            doesNotHave: [],
          });
        }
        const predicate = searchPredicates.find(
          x => x.column === filter.column
        );

        if (Array.isArray(filter.value)) {
          // In case of multi-select options, set the whole list
          predicate.is = predicate.is.concat(filter.value);
        } else {
          if (filter.predicate === "is") {
            predicate.is.push(filter.value);
          } else if (filter.predicate === "contains") {
            predicate.contains.push(filter.value);
          } else if (filter.predicate === "in group") {
            predicate.inGroup.push(filter.value);
          } else if (filter.predicate === "has") {
            predicate.has.push(filter.value);
          } else if (filter.predicate === "does not have") {
            predicate.doesNotHave.push(filter.value);
          }
        }
      });

      let quickSearch = "";
      if (searchParam !== null && searchParam !== undefined) {
        quickSearch = searchParam;
      } else if (quickSearchParam !== null && quickSearchParam !== undefined) {
        quickSearch = quickSearchParam;
      }

      return {
        searchPredicates: searchPredicates,
        quickSearch: quickSearch
      };
    },
  }
};
export default module;
