<template>
  <div class="card-style">
    <!-- Daily Import Activity -->
    <div class="daily-import">
      <!-- first row -->
      <div class="daily-import-row  mb-1">
        <div style="color:black" class="pl-2 pt-2">
          <p class="mb-0" style="font-weight: bold;">Daily Import</p>
        </div>
        <div
          v-if="isCompleted"
          class="completed mt-1"
          style="background: #009688;">
          <p class="mb-0 mt-0 pl-1 pr-1">Complete</p>
        </div>
        <div v-else class="completed mt-1" style="background:  #C9C9C9;">
          <p class="mb-0 mt-0 pl-1 pr-1">Incomplete</p>
        </div>
      </div>
      <!-- second row -->
      <div class="daily-import-row">
        <div class="daily-import-row_date pl-2 pb-2">
          <p class="mb-0">{{ importDate }}</p>
        </div>
        <div v-if="isCompleted" class="daily-import-row_date pr-2 pb-2">
          <p class="mb-0">
            {{ formatUtcDateTimeToLocalTime(this.latestActivity.finishedAt) }}
          </p>
        </div>
        <div v-else class="pr-2 pb-2 incomplete">
          <p class="mb-0">{{ timeLeftText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatUtcDateTimeToLocalTime, formatUtcDateTimeToLocalDate } from "@/utils/format-util.js";

export default {
  computed: {
    ...mapState("backgroundMessages", ["latestActivity", "timeLeft"]),
    isCompleted() {
      return (
        this.timeLeft == "COMPLETED" &&
        this.latestActivity &&
        this.latestActivity.status === "Succeeded"
      );
    },
    importDate() {
      if (this.isCompleted) {
        return formatUtcDateTimeToLocalDate(this.latestActivity.finishedAt);
      } else {
        let currentTime = new Date();
        return formatUtcDateTimeToLocalDate(currentTime);
      }
    },
    timeLeftText() {
      if (!this.timeLeft || this.timeLeft == "COMPLETED") {
        return "";
      }
      let hoursLeft = this.timeLeft.hours;
      let minutesLeft = this.timeLeft.minutes;
      if (hoursLeft == 0 && minutesLeft == 0) {
        return `Starting soon.`;
      }
      if (hoursLeft == 0) {
        return `Starting in ${minutesLeft}min.`;
      }
      return `Starting in ${hoursLeft}h ${minutesLeft}min.`;
    },
  },
  methods: {
    formatUtcDateTimeToLocalTime(date) {
      return formatUtcDateTimeToLocalTime(date);
    },
  }
};
</script>

<style>
.card-style {
  width: 255px;
  position: absolute;
  background: white;
  right: 66px;
  top: 50px;
}
.daily-import {
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.daily-import-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.daily-import-row_date {
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
}
.completed {
  border-radius: 4px;
  color: white;
  font-size: 14px;
  margin-right: 8px;
  align-items: center;
  display: flex;
}
.incomplete {
  color: #006B5B;
  font-weight: 700;
  font-size: 14px;
}
</style>
