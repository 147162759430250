import cookies from "vue-cookies";

function initialState() {
  return {
    accountGroupsSelect: [],
    parents: [],
    groupPickerComponentKey: 0
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setAccountGroupsSelect(state, value) {
      state.accountGroupsSelect = value;
    },
    setParents(state, value) {
      state.parents = value;
    },
    incrementGroupPickerComponentKey(state) {
      state.groupPickerComponentKey = state.groupPickerComponentKey + 1;
    }
  },
  actions: {
    async getAccountGroupsLevel({ commit, rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];

      if (
        payload == undefined ||
        payload == null ||
        payload.id == undefined ||
        payload.id == null
      ) {
        const URL = BASE_URL + VERSION + "/Entity/GetRootEntity?";
        let response = "";
        try {
          response = await fetch(
            URL + new URLSearchParams({ relationshipType: "ACCOUNT_GROUP" }),
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: TOKEN
              }
            }
          );

          let result = "";
          result = await response.json();

          const rootElem = result[0];
          var newGroupSelect = [];
          newGroupSelect.push(rootElem);
          commit("setAccountGroupsSelect", newGroupSelect);
        } catch (err) {
          console.log("error: " + err);
        }
      } else {
        const URL = BASE_URL + VERSION + "/Entity/GetEntityChildren?";
        let response;
        try {
          response = await fetch(
            URL +
              new URLSearchParams({
                entity: payload.id,
                relationshipType: "ACCOUNT_GROUP"
              }),
            {
              headers: {
                Authorization: TOKEN
              }
            }
          );

          let result = await response.json();
          let numberOfChildren = result.children.length;
          if (numberOfChildren == 0) {
            commit("setAccountGroupsSelect", []);
          } else {
            await commit("setAccountGroupsSelect", result.children);
          }
        } catch (err) {
          console.log("error: " + err);
        }
      }
    },
    async linkAccountToGroup({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/AddAccountsToGroup?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              groupId: payload.groupId
            }),
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "PATCH",
            body: JSON.stringify(payload.accountIds)
          }
        );
        if (response.ok) {
          return await response.json();
        } else {
          return null;
        }
      } catch (err) {
        console.log("error: " + err);
        return null;
      }
    }
  }
};
export default module;
