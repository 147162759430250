import { Auth } from "aws-amplify";
import cookies from "vue-cookies";
import router from "@/router";
function initialState() {
  return {
    user: null,
    userChanged: false,
    numOfFails: 0,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    incrementNumOfFails(state) {
      state.numOfFails += 1;
    },
    resetNumOfFails(state) {
      state.numOfFails = 0;
    },
    setUser(state, u) {
      state.user = u;
    },
    setUserChanged(state, value) {
      state.userChanged = value;
    }
  },
  actions: {
    async resetAll({ commit, dispatch }) {
      dispatch("clearCookies");
      dispatch("clearAllStates");
      dispatch("loading/resetAllLoadings", null, { root: true });
      commit("dialogs/setProductionNotice", false, { root: true });
    },
    async clearAll({ dispatch }) {
      console.error("Token error!");
      await dispatch("signOut");
    },
    async setUser({ commit }, user) {
      commit("setUser", user);
    },
    async signOut({ dispatch }) {
      try {
        await Auth.signOut();
        //clear all states
        dispatch("clearCookies");
        dispatch("backgroundMessages/stopBackgroundActivityTimer", null, { root: true });
        dispatch("clearAllStates");
        router.replace({ name: "Authentication" });
      } catch (error) {
        console.log("Error signing out: ", error);
      }
    },
    async cancelSignIn() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log("Error on clearing session: ", error);
      }
    },
    clearCookies() {
      cookies.remove("username");
      cookies.remove("token");
      cookies.remove("organization");
      cookies.remove("email");
    },
    clearAllStates({ commit }) {
      let options = { root: true };

      commit("addUpdateAccounts/resetState", null, options);
      commit("modifyEntityGroup/resetState", null, options);
      commit("appNavigation/resetState", null, options);
      commit("auth/resetState", null, options);
      commit("endpoints/resetState", null, options);
      commit("exclusions/resetState", null, options);
      commit("exports/resetState", null, options);
      commit("loading/resetState", null, options);
      commit("searchEntities/resetState", null, options);

      commit("accountStatus/resetState", null, options);
      commit("fullStatusHistory/resetState", null, options);
      commit("invoiceScreen/resetState", null, options);
      commit("payoutScreen/resetState", null, options);

      commit("modifyEntity/resetState", null, options);
      commit("entityAttributes/resetState", null, options);

      commit("browseEntities/resetState", null, options);
      commit("databaseSearch/resetState", null, options);
      commit("directoryNavigation/resetState", null, options);
      commit("entityActions/resetState", null, options);
      commit("hierarchyMode/resetState", null, options);
      commit("hierarchyTree/resetState", null, options);

      commit("feeResults/resetState", null, options);
      commit("feeResultsAccounts/resetState", null, options);
      commit("feeResultsRecords/resetState", null, options);

      commit("feeSchedule/resetState", null, options);
      commit("modifySchedule/resetState", null, options);
      commit("feeTiers/resetState", null, options);
      commit("pickDealEntity/resetState", null, options);

      commit("inceptions/resetState", null, options);
      commit("inceptionAccounts/resetState", null, options);
      commit("inceptionGroupPicker/resetState", null, options);
      commit("inceptionInAdvanceAccounts/resetState", null, options);
      commit("inceptionRecords/resetState", null, options);

      commit("terminations/resetState", null, options);
      commit("terminationAccounts/resetState", null, options);
      commit("terminationRecords/resetState", null, options);

      commit("prepopulatedData/resetState", null, options);
      commit("filters/resetState", null, options);
      commit("backgroundMessages/resetState", null, options);
    }
  },
  getters: {
    /*Token(state) {
      return state.user.token;
    },
    getUser(state) {
      return state.user;
    },
    getAuthorization(state) {
      if (state) {
        if (state.user) {
          if (state.user.signInUserSession) {
            if (state.user.signInUserSession.accessToken) {
              if (state.user.signInUserSession.accessToken.jwtToken) {
                return `Bearer ${state.user.signInUserSession.accessToken.jwtToken}`;
              }
            }
          }
        }
      }
      return "";
    }*/
  }
};

export default module;
