function initialState() {
  return {
    searchString: null,
    valueLimit: 50,
    minSearchLength: 2,
    minSearchLengthMain: 3,
    databaseSearchComponentKey: 0
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setSearchString(state, value) {
      state.searchString = value;
    },
    incrementDatabaseSearchComponentKey(state) {
      state.databaseSearchComponentKey = state.databaseSearchComponentKey + 1;
    }
  },
  actions: {}
};
export default module;
