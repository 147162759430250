function initialState() {
  return {
    allowedValuesError: false,
    feeSchedulesError: false,
    calcStatusError: false,
    calcStatusWarning: false,
    custodianExportStatusError: false,
    payoutExportStatusError: false,
    productionNotice: false,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setAllowedValuesError(state, value) {
      state.allowedValuesError = value;
    },
    setFeeSchedulesError(state, value) {
      state.feeSchedulesError = value;
    },
    setCalcStatusError(state, value) {
      state.calcStatusError = value;
    },
    setCalcStatusWarning(state, value) {
      state.calcStatusWarning = value;
    },
    setCustodianExportStatusError(state, value) {
      state.custodianExportStatusError = value;
    },
    setPayoutExportStatusError(state, value) {
      state.payoutExportStatusError = value;
    },
    setProductionNotice(state, value) {
      state.productionNotice = value;
    },
  },
  actions: {}
};
export default module;
