import cookies from "vue-cookies";

function initialState() {
  return {
    terminationAccounts: [],
    totalTerminationAccounts: 0,
    totalDistinctTerminationAccounts: 0,
    selectedTerminationAccounts: [],
    totalSelectedTerminationAccounts: 0,
    terminationAllSelected: false,
    maxItems: 2000,
    lastCall: null,

    pagination: {
      page: 1,
      itemsPerPage: 25,
      sortBy: [],
      sortDesc: []
    },
  };
}
const module = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setTerminationAccounts(state, value) {
      value.forEach(element => {
        element.tableId = element.id + element.billingRunType;
      });
      state.terminationAccounts = value;
    },
    setTotalTerminationAccounts(state, value) {
      state.totalTerminationAccounts = value;
    },
    setTotalDistinctTerminationAccounts(state, value) {
      state.totalDistinctTerminationAccounts = value;
    },
    setSelectedTerminationAccounts(state, value) {
      state.selectedTerminationAccounts = value;
    },
    resetSelectedTerminationAccounts(state) {
      state.selectedTerminationAccounts = [];
    },
    setTotalSelectedTerminationAccounts(state, value) {
      state.totalSelectedTerminationAccounts = value;
    },
    resetTotalSelectedTerminationAccounts(state) {
      state.totalSelectedTerminationAccounts = 0;
    },
    setTerminationAllSelected(state, value) {
      state.terminationAllSelected = value;
    },
    setLastCall(state, value) {
      state.lastCall = value;
    },

    setPagination(state, value) {
      state.pagination = value;
    },
    setPage(state, value) {
      let temp = state.pagination;
      temp.page = value;
      state.pagination = temp;
    },
    setPageSize(state, value) {
      let temp = state.pagination;
      temp.itemsPerPage = value;
      state.pagination = temp;
    },
    resetPagination(state) {
      state.pagination = initialState().pagination;
    },
    resetPage(state) {
      let temp = state.pagination;
      temp.page = initialState().pagination.page;
      state.pagination = temp;
    },
    resetPageSize(state) {
      let temp = state.pagination;
      temp.itemsPerPage = initialState().pagination.itemsPerPage;
      state.pagination = temp;
    },
  },
  actions: {
    async terminationSearch({ rootState, commit, dispatch }, payload) {
      commit("searchEntities/cancelTerminationSearch", null, { root: true });
      commit(
        "searchEntities/setTerminationAbortController",
        new AbortController(),
        { root: true }
      );

      let filterOptions = [];
      try {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.terminationFilters,
            searchParam: payload.searchParam,
            quickSearchParam: rootState.filters.quickSearchTermination
          },
          { root: true }
        );
      } catch (err) {
        console.error("Error: terminationSearch -> getFilterOptions: " + err);
        return false;
      }

      let urlParams = {
        page: payload.page,
        pageSize: payload.pageSize
      };
      if (payload.sortBy !== null && payload.sortBy !== undefined) {
        urlParams = { ...urlParams, sortKey: payload.sortBy };
      }
      if (
        payload.sortDirection !== null &&
        payload.sortDirection !== undefined
      ) {
        urlParams = { ...urlParams, sortDirection: payload.sortDirection };
      }

      let result = await dispatch("getTerminationAccounts", {
        urlParams: urlParams,
        filterOptions: filterOptions
      });
      return result;
    },
    async getTerminationAccounts({ state, rootState, commit, rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/GetTerminationAccounts?";
      const signal = rootState.searchEntities.terminationAbortController.signal;
      commit("setLastCall", payload);

      try {
        let response = await fetch(
          URL + new URLSearchParams(payload.urlParams),
          {
            method: "PATCH",
            headers: {
              Authorization: TOKEN,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(payload.filterOptions),
            signal: signal,
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (payload == state.lastCall) {
            await commit("setTerminationAccounts", result.data.responseData);
            await commit(
              "setTotalTerminationAccounts",
              result.data.metaData.totalRecordCount
            );
            await commit(
              "setTotalDistinctTerminationAccounts",
              result.data.metaData.totalDistinctRecordsCount
            );
          }
          return true;
        } else {
          throw "Response not ok.";
        }
      } catch (err) {
        if (!(err instanceof DOMException)) {
          console.error("getTerminationAccounts error: " + err);
          await commit("setTerminationAccounts", []);
          await commit("setTotalTerminationAccounts", 0);
          await commit("setTotalDistinctTerminationAccounts", 0);
          return false;
        } else {
          return true;
        }
      }
    },
    async getTerminationMissingAccounts({ rootState, commit, dispatch }) {
      commit("searchEntities/cancelMissingAccountsSearch", null, {
        root: true
      });
      commit(
        "searchEntities/setMissingAccountsAbortController",
        new AbortController(),
        { root: true }
      );

      let filterOptions = [];
      try {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.terminationFilters,
            quickSearchParam: rootState.filters.quickSearchTermination
          },
          { root: true }
        );
      } catch (err) {
        console.error("Error: getTerminationMissingAccounts -> getFilterOptions: " + err);
        return false;
      }

      let urlParams = {
        billingRunType: "Termination"
      };

      let result = await dispatch(
        "accountsBilling/getMissingAccounts",
        { urlParams: urlParams, filterOptions: filterOptions },
        { root: true }
      );
      return result;
    },
  }
};
export default module;
