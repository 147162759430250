function removeUnnecessaryCookies() {
  if (!this.$cookies.isKey("userSettings") || !this.$cookies.isKey("username")) {
    return;
  }
  let settings = JSON.parse(this.$cookies.get("userSettings"));
  let settingsToDelete = [];
  for (let i = 0; i < settings.length; i++) {
    settingsToDelete.push(settings[i]);
  }
  for (let i = 0; i < settingsToDelete.length; i++) {
    let setting = settingsToDelete[i];
    if (this.$cookies.isKey(setting.username + "-" + setting.nameOfSetting)) {
      this.$cookies.remove(setting.username + "-" + setting.nameOfSetting);
    }
  }
  this.$cookies.remove("userSettings");
}

module.exports = {
  removeUnnecessaryCookies,
};
