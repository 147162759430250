import cookies from "vue-cookies";

function initialState() {
  return {
    payoutExportResults: "",
    custodianExportResults: "",
    latestCustodianExportStatus: null,
    latestPayoutExportStatus: null,
    exportTimeout: 25000,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setPayoutExportResults(state, value) {
      state.payoutExportResults = value;
    },
    setCustodianExportResults(state, value) {
      state.custodianExportResults = value;
    },
    setLatestCustodianExportStatus(state, value) {
      state.latestCustodianExportStatus = value;
    },
    setLatestPayoutExportStatus(state, value) {
      state.latestPayoutExportStatus = value;
    },
  },
  actions: {
    async getLatestCustodianExportStatus({ dispatch, commit, rootGetters }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL_CALC = rootGetters["endpoints/BaseUrlCalc"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL_CALC +
        VERSION +
        "/BillingStatus/GetLatestCustodianExportStatusForUser";

      try {
        let response = await fetch(URL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
        });

        if (response.ok) {
          let res = await response.json();
          commit("setLatestCustodianExportStatus", res);
          return res;
        } else {
          commit("dialogs/setCustodianExportStatusError", true, { root: true });
          return false;
        }
      } catch (err) {
        console.error(err);
        commit("dialogs/setCustodianExportStatusError", true, { root: true });
        return false;
      }
    },
    async getLatestPayoutExportStatus({ dispatch, commit, rootGetters }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL_CALC = rootGetters["endpoints/BaseUrlCalc"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL_CALC +
        VERSION +
        "/BillingStatus/GetLatestPayoutExportStatusForUser";

      try {
        let response = await fetch(URL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
        });

        if (response.ok) {
          let res = await response.json();
          commit("setLatestPayoutExportStatus", res);
          return res;
        } else {
          commit("dialogs/setPayoutExportStatusError", true, { root: true });
          return false;
        }
      } catch (err) {
        console.error(err);
        commit("dialogs/setPayoutExportStatusError", true, { root: true });
        return false;
      }
    },
    async filterPreviewExportFile({ rootState, dispatch }, payload) {
      var params = {
        runType: payload.runType,
        period: payload.period
      };
      if (payload.exportType === "Custodian") {
        params = { ...params, endpoint: "PreviewCustodianFile" };
      } else if (payload.exportType === "Payout") {
        params = { ...params, endpoint: "PreviewPayoutFile" };
      }

      if (payload.all) {
        let filterOptions = {};
        if (payload.runType === "Regular") {
          filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: rootState.filters.feeReviewFilters,
              searchParam: null,
              quickSearchParam: rootState.filters.quickSearchFeeReview
            },
            { root: true }
          );
        } else if (payload.runType === "Termination") {
          filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: rootState.filters.terminationFilters,
              searchParam: null,
              quickSearchParam: rootState.filters.quickSearchTermination
            },
            { root: true }
          );
        }
        // Add another else-if for Inception if it has "all"
        params = {
          ...params,
          all: true,
          filterOptions: filterOptions
        };
      } else {
        let itemsForExport = [];
        payload.selectedAccounts.forEach(account => {
          let accountWithStatus = {
            id: account.id,
            billingRunType: account.billingRunType,
            status: account.status
          };
          itemsForExport.push(accountWithStatus);
        });
        params = { ...params, accountsForExport: itemsForExport };
      }
      let result = await dispatch("previewExportFile", params);
      return result;
    },
    async previewExportFile({ rootGetters, dispatch }, { endpoint, accountsForExport, runType, all, period, filterOptions }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + `/BillingStatus/${endpoint}?`;

      let returnObject = {
        status: "",
        data: null
      };

      try {
        let payload = { runTypeForFiltering: runType, billingPeriod: period };
        let urlParams = {};
        if (all) {
          payload = { ...payload, ...filterOptions };
          urlParams = { all: true };
        } else {
          payload = { ...payload, accountsWithStatuses: accountsForExport };
          urlParams = { all: false };
        }

        let response = await fetch(URL + new URLSearchParams(urlParams), {
          method: "PATCH",
          headers: {
            Authorization: TOKEN,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        });

        if (response.ok) {
          if (response.status === 204) {
            returnObject.status = "EMPTY";
          } else {
            returnObject.status = "SUCCESS";
            returnObject.data = await response.blob();
          }
        } else if (response.status == 504) {
          returnObject.status = "TIMEOUT";
        } else {
          returnObject.status = "ERROR";
        }
      } catch (err) {
        console.error(err);
        returnObject.status = "ERROR";
      }

      return returnObject;
    },
    async filterExport({ state, rootState, commit, dispatch }, payload) {
      commit("setPayoutExportResults", "");
      commit("setCustodianExportResults", "");
      var params = {
        runType: payload.runType,
        period: payload.period,
        timeout: state.exportTimeout
      };
      if (payload.exportType === "Custodian") {
        params = { ...params, endpoint: "CreateCustodianInstructionFiles" };
      } else if (payload.exportType === "Payout") {
        params = { ...params, endpoint: "CreateXtivaFile" };
      }

      if (payload.all) {
        let filterOptions = {};
        if (payload.runType === "Regular") {
          filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: rootState.filters.feeReviewFilters,
              searchParam: null,
              quickSearchParam: rootState.filters.quickSearchFeeReview
            },
            { root: true }
          );
        } else if (payload.runType === "Termination") {
          filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: rootState.filters.terminationFilters,
              searchParam: null,
              quickSearchParam: rootState.filters.quickSearchTermination
            },
            { root: true }
          );
        }
        // Add another else-if for Inception if it has "all"
        params = {
          ...params,
          all: true,
          filterOptions: filterOptions
        };
      } else {
        let itemsForExport = [];
        if (payload.fromAccountStatus) {
          payload.selectedItems.forEach(item => {
            let accountWithStatus = {
              id: item.sourceAccountId,
              billingRunType: item.billingRunType,
              status: item.status
            };
            itemsForExport.push(accountWithStatus);
          });
        } else {
          payload.selectedAccounts.forEach(account => {
            let accountWithStatus = {
              id: account.id,
              billingRunType: account.billingRunType,
              status: account.status
            };
            itemsForExport.push(accountWithStatus);
          });
        }
        params = { ...params, accountsForExport: itemsForExport };
      }
      let result = await dispatch("exportResults", params);
      if (payload.exportType === "Custodian") {
        if (result.status == "SUCCESS") {
          await commit("setCustodianExportResults", result.exportFiles);
        } else if (result.status == "TIMEOUT") {
          await dispatch("getLatestCustodianExportStatus");
        }
      } else if (payload.exportType === "Payout") {
        if (result.status == "SUCCESS") {
          await commit("setPayoutExportResults", result.exportFiles);
        } else if (result.status == "TIMEOUT") {
          await dispatch("getLatestPayoutExportStatus");
        }
      }
      return result;
    },
    async exportResults({ rootGetters, commit, dispatch }, { endpoint, accountsForExport, runType, all, period, filterOptions, timeout }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL_CALC = rootGetters["endpoints/BaseUrlCalc"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL_CALC + VERSION + `/BillingStatus/${endpoint}?`;

      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      let returnObject = {
        status: "",
        message: "",
        exportFiles: null
      };

      try {
        let signal = null;
        let id = null;
        const controller = new AbortController();
        if (timeout >= 0) {
          id = setTimeout(() => controller.abort(), timeout);
          signal = controller.signal;
        }

        let payload = { runTypeForFiltering: runType, billingPeriod: period };
        let urlParams = {};
        if (all) {
          payload = { ...payload, ...filterOptions };
          urlParams = { all: true };
        } else {
          payload = { ...payload, accountsWithStatuses: accountsForExport };
          urlParams = { all: false };
        }
        let response = await fetch(URL + new URLSearchParams(urlParams), {
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "PATCH",
          body: JSON.stringify(payload),
          signal: signal
        });
        if (id) {
          clearTimeout(id);
        }

        if (response.ok) {
          let result = await response.json();
          let exportFiles = result.exportFiles;
          let exportMessage = result.exportMessage;
          if (exportFiles.length > 0) {
            returnObject.status = "SUCCESS";
            returnObject.exportFiles = exportFiles;
          } else if (exportMessage) { // No accounts with proper status
            returnObject.status = "INFO";
            returnObject.message = exportMessage;
          } else { // No export files generated
            returnObject.status = "EMPTY";
          }
        } else if (response.status == 400) { //There is export running in the moment
          returnObject.status = "WARNING";
          returnObject.message = await response.text();
        } else {
          console.error(response.statusText);
          returnObject.status = "ERROR";
        }
      } catch (err) {
        if (err instanceof DOMException && timeout >= 0) {
          returnObject.status = "TIMEOUT";
        } else {
          console.error("error: " + err);
          returnObject.status = "ERROR";
        }
      }

      dispatch("loading/endLoading", null, { root: true });
      finished = true;

      return returnObject;
    },
    // not used anymore
    async previewPayoutFile({ rootGetters, rootState, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/BillingStatus/PreviewPayoutFile?";

      try {
        const billingPeriod = rootGetters["feeResultsAccounts/getPreviousBillingPeriod"];
        let urlParams = { billingPeriod: billingPeriod };
        let bodyPayload;
        if (payload.all) {
          urlParams = { ...urlParams, all: true };
          let filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: rootState.filters.feeReviewFilters,
              searchParam: payload.searchParam,
              quickSearchParam: rootState.filters.quickSearchFeeReview
            },
            { root: true }
          );
          bodyPayload = {
            billingRunType: payload.billingRunType,
            ...filterOptions
          };
        } else {
          urlParams = { ...urlParams, all: false };
          let selectedAccounts = payload.selectedAccounts.map(acc => acc.id);
          bodyPayload = { selectedAccounts: selectedAccounts };
        }

        let response = await fetch(URL + new URLSearchParams(urlParams), {
          method: "PATCH",
          headers: {
            Authorization: TOKEN,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(bodyPayload)
        });

        if (response.ok) {
          if (response.status === 204) {
            return false;
          } else {
            return await response.blob();
          }
        } else {
          return null;
        }
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    // not used anymore
    async previewCustodianFile({ rootGetters, rootState, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/BillingStatus/PreviewCustodianFile?";

      try {
        const billingPeriod = rootGetters["feeResultsAccounts/getPreviousBillingPeriod"];
        let urlParams = { billingPeriod: billingPeriod };
        let bodyPayload;
        if (payload.all) {
          urlParams = { ...urlParams, all: true };
          let filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: rootState.filters.feeReviewFilters,
              searchParam: payload.searchParam,
              quickSearchParam: rootState.filters.quickSearchFeeReview
            },
            { root: true }
          );
          bodyPayload = {
            billingRunType: payload.billingRunType,
            ...filterOptions
          };
        } else {
          urlParams = { ...urlParams, all: false };
          let selectedAccounts = payload.selectedAccounts.map(acc => acc.id);
          bodyPayload = { selectedAccounts: selectedAccounts };
        }

        let response = await fetch(URL + new URLSearchParams(urlParams), {
          method: "PATCH",
          headers: {
            Authorization: TOKEN,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(bodyPayload)
        });

        if (response.ok) {
          if (response.status === 204) {
            return false;
          } else {
            return await response.blob();
          }
        } else {
          return null;
        }
      } catch (err) {
        console.error(err);
        return null;
      }
    },
  }
};
export default module;
