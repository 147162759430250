import cookies from "vue-cookies";

function initialState() {
  return {
    filteredInceptionAccounts: [],
    totalFilteredInceptionAccounts: 0,
    lastCall: null,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setFilteredInceptionAccounts(state, value) {
      state.filteredInceptionAccounts = value;
    },
    resetFilteredInceptionAccounts(state) {
      state.filteredInceptionAccounts = [];
    },
    setTotalFilteredInceptionAccounts(state, value) {
      state.totalFilteredInceptionAccounts = value;
    },
    setLastCall(state, value) {
      state.lastCall = value;
    },
  },
  actions: {
    reset({ rootState, commit }) {
      let itemsPerPage = rootState.inceptions.pagination.itemsPerPage;
      commit("inceptions/resetPagination", null, { root: true });
      commit("inceptions/setPageSize", itemsPerPage, { root: true });
    },
    async inceptionInAdvanceSearch({ rootState, commit, dispatch }, accountIds) {
      dispatch("reset");
      commit("searchEntities/cancelInceptionSearch", null, { root: true });
      commit(
        "searchEntities/setInceptionAbortController",
        new AbortController(),
        { root: true }
      );

      let filterOptions = [];
      try {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.inceptionFilters,
            searchParam: null,
            quickSearchParam: rootState.filters.quickSearchInception
          },
          { root: true }
        );
        filterOptions = { ...filterOptions, accountIds: accountIds };
      } catch (err) {
        console.error("Error: inceptionInAdvanceSearch -> getFilterOptions: " + err);
        return false;
      }

      let urlParams = {
        page: 1,
        pageSize: rootState.inceptionAccounts.totalInceptionAccounts
      };

      let result = await dispatch("getInceptionInAdvanceAccounts", {
        urlParams: urlParams,
        filterOptions: filterOptions
      });
      return result;
    },
    async getInceptionInAdvanceAccounts({ state, rootState, rootGetters, dispatch, commit }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/GetInceptionInAdvanceAccounts?";
      const signal = rootState.searchEntities.inceptionAbortController.signal;
      commit("setLastCall", payload);

      try {
        let response = await fetch(
          URL + new URLSearchParams(payload.urlParams),
          {
            method: "PATCH",
            headers: {
              Authorization: TOKEN,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(payload.filterOptions),
            signal: signal,
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (payload == state.lastCall) {
            await commit("setFilteredInceptionAccounts", result.data.responseData);
            await commit(
              "setTotalFilteredInceptionAccounts",
              result.data.metaData.totalRecordCount
            );
          }
          return true;
        } else {
          throw "Response not ok.";
        }
      } catch (err) {
        if (!(err instanceof DOMException)) {
          console.error("getInceptionInAdvanceAccounts error: " + err);
          await commit("setFilteredInceptionAccounts", []);
          await commit("setTotalFilteredInceptionAccounts", 0);
          return false;
        } else {
          return true;
        }
      }
    },
  }
};
export default module;
