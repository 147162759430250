import cookies from "vue-cookies";

function initialState() {
  return {
    feeTiers: [],
    selectedFee: null
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setFeeTiers(state, value) {
      state.feeTiers = value;
    },
    setSelectedFee(state, fee) {
      state.selectedFee = fee;
    }
  },
  actions: {
    selectFee({ commit, state }, payload) {
      if (payload.value) {
        commit("setSelectedFee", payload.item);
        if (state.selectedFee !== undefined && state.selectedFee !== null) {
          let feeTiers = state.selectedFee.billingFeeTiers.sort(function (a, b) {
            return a.activationLevel - b.activationLevel;
          });
          commit("setFeeTiers", feeTiers);
        } else {
          commit("setSelectedFee", null);
          commit("setFeeTiers", []);
        }
      } else {
        commit("setSelectedFee", null);
        commit("setFeeTiers", []);
      }
      if (payload.inherited !== undefined)
        commit("feeSchedule/setInherited", payload.inherited, { root: true });
    },
    async addFeeTier({ commit, state, rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/AddFeeTier?";

      let response = await fetch(
        URL +
          new URLSearchParams({
            userName: payload.userName
          }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "POST",
          body: JSON.stringify({
            billingFeeDefinitionsId: state.selectedFee.id,
            billingTierType: payload.billingTierType,
            activationLevel: payload.activationLevel,
            rateOrAmount: payload.rateOrAmount
          })
        }
      );
      let result = "";
      try {
        result = await response.json();

        //Add newly created Tier to visibleTiers
        const newTierActivationLevel = result.activationLevel;

        let i = 0;
        if (state.feeTiers.length > 0) {
          while (
            state.feeTiers.length > i &&
            newTierActivationLevel > state.feeTiers[i].activationLevel
          ) {
            i++;
          }
        }
        state.selectedFee.billingFeeTiers.splice(i, 0, result);
        commit("setFeeTiers", state.selectedFee.billingFeeTiers);
      } catch (err) {
        return "Error: " + err;
      }

      return result;
    },
    async modifyFeeTier({ commit, state, rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/ModifyFeeTier?";

      let response = await fetch(
        URL +
          new URLSearchParams({
            id: payload.id,
            userName: payload.userName
          }),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "PUT",
          body: JSON.stringify({
            billingFeeDefinitionsId: payload.billingFeeDefinitionsId,
            billingTierType: payload.billingTierType,
            activationLevel: payload.activationLevel,
            rateOrAmount: payload.rateOrAmount
          })
        }
      );
      let result = await response.json();

      // Remove Tier
      let i = 0;
      if (state.feeTiers.length > 0) {
        while (payload.id != state.feeTiers[i].id) {
          i++;
        }
      }
      state.selectedFee.billingFeeTiers.splice(i, 1);

      // Add modified Tier back to the List
      const tierActivationLevel = result.activationLevel;
      i = 0;
      if (state.feeTiers.length > 0) {
        while (
          state.feeTiers.length > i &&
          tierActivationLevel > state.feeTiers[i].activationLevel
        ) {
          i++;
        }
      }
      state.selectedFee.billingFeeTiers.splice(i, 0, result);

      commit("setFeeTiers", state.selectedFee.billingFeeTiers);

      return result;
    },
    async deleteFeeTier({ commit, state, rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/DeleteFeeTier?";

      let response = await fetch(
        URL +
          new URLSearchParams({
            id: payload.id
          }),
        {
          headers: {
            Authorization: TOKEN
          },
          method: "DELETE"
        }
      );
      let result = response.json();

      // Remove Tier from selectedFee's Tiers List
      let i = 0;
      if (state.feeTiers.length > 0) {
        while (payload.id != state.feeTiers[i].id) {
          i++;
        }
      }
      state.selectedFee.billingFeeTiers.splice(i, 1);
      commit("setFeeTiers", state.selectedFee.billingFeeTiers);

      return result;
    }
  }
};

export default module;
