import router from "@/router";
import amplitude from "amplitude-js";
function initialState() {
  return {
    showTermination: false,
  };
}
const module = {
  namespaced: true,
  state: initialState,
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setShowTermination(state, value) {
      state.showTermination = value;
    },
  },
  actions: {
    showTerminationWizard({ commit, rootState }) {
      router.push({ name: "TerminationWizard" });
      commit("setShowTermination", true);
      rootState.appNavigation.mainMenuActive = false;
      amplitude.getInstance().logEvent("Tab Opened [Termination]");
    },
    closeTerminationWizard({ commit }, newAddress) {
      let options = { root: true };
      commit("resetState", null);
      commit("terminationAccounts/resetState", null, options);
      commit("terminationRecords/resetState", null, options);
      commit("filters/resetTerminationFilters", null, options);
      commit("filters/resetQuickSearchTermination", null, options);
      router.push({ name: newAddress });
    }
  }
};
export default module;
