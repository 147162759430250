import cookies from "vue-cookies";

function initialState() {
  return {};
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
  },
  actions: {
    async addAdjustment({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/ComputeEngine/AddOrUpdateAdjustment?";

      try {
        let response = await fetch(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "PUT",
          body: JSON.stringify(payload)
        });

        if (response.ok) {
          let result = await response.json();
          return result;
        } else if (response.status == 409) {
          return { errorMessage: await response.text() };
        } else {
          return false;
        }
      } catch (err) {
        console.log("addAdjustment error: " + err);
        return false;
      }
    },
    async updateAdjustment({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/ComputeEngine/AddOrUpdateAdjustment?";

      try {
        let response = await fetch(
          URL + new URLSearchParams({ id: payload.id }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "PUT",
            body: JSON.stringify(payload)
          }
        );

        if (response.ok) {
          let result = await response.json();
          return result;
        } else if (response.status == 409) {
          return { errorMessage: await response.text() };
        } else {
          return false;
        }
      } catch (err) {
        console.log("updateAdjustment error: " + err);
        return false;
      }
    },
    async deleteAdjustment({ rootGetters, dispatch }, id) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/ComputeEngine/DeleteAdjustment?";

      try {
        let response = await fetch(URL + new URLSearchParams({ id: id }), {
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "DELETE",
        });

        if (response.ok) {
          let result = await response.json();
          return result;
        } else if (response.status == 409) {
          return { errorMessage: await response.text() };
        } else {
          return false;
        }
      } catch (err) {
        console.log("deleteAdjustment error: " + err);
        return false;
      }
    },
  }
};

export default module;
