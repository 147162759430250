import cookies from "vue-cookies";

function initialState() {
  return {
    inceptionAccounts: [],
    totalInceptionAccounts: 0,
    totalDistinctInceptionAccounts: 0,
    selectedInceptionAccounts: [],
    totalSelectedInceptionAccounts: 0,
    inceptionAllSelected: false,
    maxItems: 2000,
    lastCall: null,
    accountStatusChipValue: "active",
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setInceptionAccounts(state, value) {
      value.forEach(element => {
        element.tableId = element.id + element.billingRunType;
      });
      state.inceptionAccounts = value;
    },
    setTotalInceptionAccounts(state, value) {
      state.totalInceptionAccounts = value;
    },
    setTotalDistinctInceptionAccounts(state, value) {
      state.totalDistinctInceptionAccounts = value;
    },
    setSelectedInceptionAccounts(state, value) {
      state.selectedInceptionAccounts = value;
    },
    resetSelectedInceptionAccounts(state) {
      state.selectedInceptionAccounts = [];
    },
    setTotalSelectedInceptionAccounts(state, value) {
      state.totalSelectedInceptionAccounts = value;
    },
    resetTotalSelectedInceptionAccounts(state) {
      state.totalSelectedInceptionAccounts = 0;
    },
    setInceptionAllSelected(state, value) {
      state.inceptionAllSelected = value;
    },
    setLastCall(state, value) {
      state.lastCall = value;
    },
    setAccountStatusChipValue(state, value) {
      state.accountStatusChipValue = value;
    },
    resetAccountStatusChipValue(state) {
      state.accountStatusChipValue = null;
    }
  },
  actions: {
    async inceptionSearch({ rootState, commit, dispatch }, payload) {
      commit("searchEntities/cancelInceptionSearch", null, { root: true });
      commit(
        "searchEntities/setInceptionAbortController",
        new AbortController(),
        { root: true }
      );

      let filterOptions = [];
      try {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.inceptionFilters,
            searchParam: payload.searchParam,
            quickSearchParam: rootState.filters.quickSearchInception
          },
          { root: true }
        );
      } catch (err) {
        console.error("Error: inceptionSearch -> getFilterOptions: " + err);
        return false;
      }

      let urlParams = {
        page: payload.page,
        pageSize: payload.pageSize
      };
      if (payload.sortBy !== null && payload.sortBy !== undefined) {
        urlParams = { ...urlParams, sortKey: payload.sortBy };
      }
      if (
        payload.sortDirection !== null &&
        payload.sortDirection !== undefined
      ) {
        urlParams = { ...urlParams, sortDirection: payload.sortDirection };
      }

      let result = await dispatch("getInceptionAccounts", {
        urlParams: urlParams,
        filterOptions: filterOptions
      });
      return result;
    },
    async getInceptionAccounts({ state, rootState, rootGetters, dispatch, commit }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/GetInceptionAccounts?";
      const signal = rootState.searchEntities.inceptionAbortController.signal;
      commit("setLastCall", payload);

      try {
        let response = await fetch(
          URL + new URLSearchParams(payload.urlParams),
          {
            method: "PATCH",
            headers: {
              Authorization: TOKEN,
              "Content-Type": "application/json"
            },
            body: JSON.stringify(payload.filterOptions),
            signal: signal,
          }
        );

        if (response.ok) {
          let result = await response.json();
          if (payload == state.lastCall) {
            await commit("setInceptionAccounts", result.data.responseData);
            await commit(
              "setTotalInceptionAccounts",
              result.data.metaData.totalRecordCount
            );
            await commit(
              "setTotalDistinctInceptionAccounts",
              result.data.metaData.totalDistinctRecordsCount
            );
          }
          return true;
        } else {
          throw "Response not ok.";
        }
      } catch (err) {
        if (!(err instanceof DOMException)) {
          console.error("getInceptionAccounts error: " + err);
          await commit("setInceptionAccounts", []);
          await commit("setTotalInceptionAccounts", 0);
          await commit("setTotalDistinctInceptionAccounts", 0);
          return false;
        } else {
          return true;
        }
      }
    },
    async getInceptionMissingAccounts({ rootState, commit, dispatch }) {
      commit("searchEntities/cancelMissingAccountsSearch", null, {
        root: true
      });
      commit(
        "searchEntities/setMissingAccountsAbortController",
        new AbortController(),
        { root: true }
      );

      let filterOptions = [];
      try {
        filterOptions = await dispatch(
          "filters/getFilterOptions",
          {
            predicates: rootState.filters.inceptionFilters,
            quickSearchParam: rootState.filters.quickSearchInception
          },
          { root: true }
        );
      } catch (err) {
        console.error("Error: getInceptionMissingAccounts -> getFilterOptions: " + err);
        return false;
      }

      let urlParams = {
        billingRunType: "Inception"
      };

      let result = await dispatch(
        "accountsBilling/getMissingAccounts",
        { urlParams: urlParams, filterOptions: filterOptions },
        { root: true }
      );
      return result;
    },
    /*async getAllInceptionAccounts({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/GetInceptionAccounts?";

      try {
        let response = "";
        let search = "";
        if (payload.search != null) {
          search = payload.search;
        }
        response = await fetch(
          URL +
            new URLSearchParams({
              page: payload.page,
              pageSize: payload.pageSize,
              name: search
            }),
          {
            headers: {
              Authorization: TOKEN
            }
          }
        );

        if (response.ok) {
          let result = await response.json();
          let responseData = result.data.responseData;
          return responseData;
        } else {
          return false;
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    }*/
  }
};
export default module;
