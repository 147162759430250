import cookies from "vue-cookies";

function initialState() {
  return {
    accountStatusFilters: [],
    quickSearchParam: null,
    lineItems: [],
    totalLineItems: 0,
    totalSelectedLineItems: 0,
    lineItemsAllSelected: false,
    selectedLineItems: [],
    billingStatusTypes: [],
    noteMaxCharacters: 150,

    pagination: {
      page: 1,
      itemsPerPage: 25
    },

    statusesError: false
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setAccountStatusFilters(state, value) {
      state.accountStatusFilters = value;
    },
    resetAccountStatusFilters(state) {
      state.accountStatusFilters = [];
    },
    setQuickSearchParam(state, value) {
      state.quickSearchParam = value;
    },
    resetQuickSearchParam(state) {
      state.quickSearchParam = null;
    },
    setLineItems(state, value) {
      state.lineItems = value;
    },
    setTotalLineItems(state, value) {
      state.totalLineItems = value;
    },
    setTotalSelectedLineItems(state, value) {
      state.totalSelectedLineItems = value;
    },
    setLineItemsAllSelected(state, value) {
      state.lineItemsAllSelected = value;
    },
    setSelectedLineItems(state, value) {
      state.selectedLineItems = value;
    },
    setBillingStatusTypes(state, value) {
      let sorted = value.sort((a, b) => (a.displayName > b.displayName ? 1 : -1));
      state.billingStatusTypes = sorted;
    },

    setPagination(state, value) {
      state.pagination = value;
    },
    setPage(state, value) {
      let temp = state.pagination;
      temp.page = value;
      state.pagination = temp;
    },
    setPageSize(state, value) {
      let temp = state.pagination;
      temp.itemsPerPage = value;
      state.pagination = temp;
    },
    resetAccountStatusPagination(state) {
      state.pagination = initialState().pagination;
    },
    resetPage(state) {
      let temp = state.pagination;
      temp.page = initialState().pagination.page;
      state.pagination = temp;
    },
    resetPageSize(state) {
      let temp = state.pagination;
      temp.itemsPerPage = initialState().pagination.itemsPerPage;
      state.pagination = temp;
    },

    setStatusesError(state, value) {
      state.statusesError = value;
    }
  },
  actions: {
    async getAccountInvoiceLineItemsByAccountIdsAggregate({ state, commit, dispatch, rootGetters, rootState }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/BillingStatus/GetAccountInvoiceLineItemsByAccountIdsAggregate?";

      try {
        const prevBillingPeriod =
          rootGetters["feeResultsAccounts/getPreviousBillingPeriod"];
        let urlParams = {
          page: payload.page,
          pageSize: payload.pageSize
        };
        let bodyPayload = {
          billingPeriod: prevBillingPeriod,
        };
        if (payload.feeResultsAll) {
          // Modify if called from Inception or Termination
          let filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: state.accountStatusFilters,
              searchParam: payload.searchParam,
              quickSearchParam: state.quickSearchParam
            },
            { root: true }
          );
          bodyPayload = { ...bodyPayload, ...filterOptions };
          urlParams = { ...urlParams, all: true };
        } else {
          let accountIds = rootState.feeResultsRecords.accountsForCalculation.map(
            acc => acc.id
          );
          bodyPayload = {
            ...bodyPayload,
            accountIds: accountIds
          };
          urlParams = { ...urlParams, all: false };
        }
        let response = await fetch(URL + new URLSearchParams(urlParams), {
          method: "PATCH",
          headers: {
            Authorization: TOKEN,
            "Content-Type": "application/json"
          },
          body: JSON.stringify(bodyPayload)
        });

        if (response.ok) {
          let result = await response.json();
          commit("setLineItems", result.data.responseData);
          commit("setTotalLineItems", result.data.metaData.totalRecordCount);
        } else {
          throw response.statusText;
        }
      } catch (err) {
        commit("setLineItems", []);
        commit("setTotalLineItems", 0);
        commit("setStatusesError", true);
        console.error(err);
      }
    },
    async getLineItems({ commit, dispatch, rootGetters, rootState }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/BillingStatus/GetAccountInvoiceLineItemsByAccountIds?";

      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      try {
        let accountIds = rootState.feeResultsRecords.accountsForCalculation.map(
          acc => acc.id
        );
        let response = await fetch(
          URL +
            new URLSearchParams({
              page: payload.page,
              pageSize: payload.pageSize
            }),
          {
            headers: {
              Authorization: TOKEN,
              "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({ accountIds: accountIds })
          }
        );

        let result = await response.json();

        commit("setLineItems", result.resultSet);
        commit("setTotalLineItems", result.total);
      } catch (error) {
        commit("setStatusesError", true);
        console.log(error);
      }

      dispatch("loading/endLoading", null, { root: true });
      finished = true;
    },
    async updateStatus({ dispatch, rootGetters }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL + VERSION + "/BillingStatus/UpdateAccountInvoiceItemStatus?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              id: payload.id,
              newStatus: payload.newStatus,
              note: payload.note
            }),
          {
            headers: {
              Authorization: TOKEN
            },
            method: "PUT"
          }
        );
        if (response.ok) {
          let result = await response.json();
          return result;
        } else {
          throw "updateStatus error: Response not ok.";
        }
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async updateManyBillingStatuses({ rootGetters, state, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL + VERSION + "/BillingStatus/UpdateManyBillingStatuses?";

      var returnObject = {
        updatedInvoicesIds: [],
        notUpdatedInvoicesIds: [],
        error: false
      };

      try {
        var urlParams = { all: false };

        var bodyPayload = { newStatus: payload.newStatus, note: payload.note };

        if (payload.all) {
          let filterOptions = await dispatch(
            "filters/getFilterOptions",
            {
              predicates: state.accountStatusFilters,
              searchParam: payload.searchParam,
              quickSearchParam: state.quickSearchParam
            },
            { root: true }
          );
          const period = rootGetters["feeResultsAccounts/getPreviousBillingPeriod"];
          bodyPayload = {
            ...bodyPayload,
            ...filterOptions,
            billingPeriod: period
          };
          if (payload.selectedAccounts) {
            var accountIds = payload.selectedAccounts.map(a => a.id);
            bodyPayload = { ...bodyPayload, accountIds: accountIds };
          }
          urlParams = { ...urlParams, all: true };
        } else {
          var invoiceIds = payload.selectedItems.map(a => a.id);
          bodyPayload = { ...bodyPayload, invoiceIds: invoiceIds };
        }

        let response = await fetch(URL + new URLSearchParams(urlParams), {
          headers: {
            Authorization: TOKEN,
            "Content-Type": "application/json",
          },
          method: "PATCH",
          body: JSON.stringify(bodyPayload)
        });

        if (response.ok) {
          let result = await response.json();
          returnObject = result;
        } else {
          returnObject.error = true;
        }
      } catch (error) {
        console.log(error);
        returnObject.error = true;
      }
      return returnObject;
    },
    async updateBillingStatusNote({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/BillingStatus/billingStatusNote/" +
        payload.note.id +
        "/update?";

      try {
        let response = await fetch(URL, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "PUT",
          body: JSON.stringify({
            note: payload.newNoteText,
            noteOperation: payload.note.noteOperation,
            invoiceId: payload.note.invoiceId
          })
        });
        if (response.ok) {
          let result = await response.json();
          return result;
        } else {
          throw response.statusText;
        }
      } catch (err) {
        console.log("updateBillingStatusNote error: " + err);
        return false;
      }
    },
    async getBillingStatusTypes({ commit, dispatch, rootGetters }) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/BillingStatus/GetBillingStatusTypes";
      let response = await fetch(URL, {
        headers: {
          Authorization: TOKEN
        },
        method: "GET"
      });
      let result = await response.json();
      commit("setBillingStatusTypes", result);
      return result;
    }
  }
};

export default module;
