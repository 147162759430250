<template>
  <v-navigation-drawer clipped temporary app v-model="menuActive">
    <v-list height="100%" :style="menuStyle" class="parentList">
      <v-list-item
        v-for="(item, index) in mainMenuListTop"
        :key="index"
        @click="item.action"
      >
        <div v-html="item.icon" class="mr-3 mt-1"></div>
        <v-list-item-title :style="listItemStyle">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>

      <div class="list">
        <v-list-item
          v-for="(item, index) in mainMenuListBottom"
          :key="index"
          @click="item.action"
        >
          <div v-html="item.icon" class="mr-3 mt-1"></div>
          <v-list-item-title :style="listItemStyle">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import aws from "aws-sdk";
import { mapActions, mapMutations, mapState } from "vuex";
import amplitude from "amplitude-js"
import menuIcons from "@/assets/menuIcons/icons.js";
export default {
  data: () => ({
    bucket: "billing-app-documentation",
    key: "SMArtX-Billing-for-Advisors-and-Home-Offices-Users-Guide.pdf"
  }),
  computed: {
    ...mapState("appNavigation", ["mainMenuActive"]),
    ...mapState("userConfig", ["colorTheme"]),
    menuStyle() {
      return {
        "background-color": this.colorTheme.menuBackground
      };
    },
    listItemStyle() {
      return {
        color: this.colorTheme.menuLetters
      };
    },
    mainMenuListTop() {
      let menu = [
        {
          title: "Fee Assignment",
          name: "feeAssignment",
          icon: menuIcons.feeAssignment,
          action: () => this.showFeeAssignment()
        },
        {
          title: "Fee Review",
          name: "feeResults",
          icon: menuIcons.feeReview,
          action: () => this.showFeeResults()
        },
        {
          title: "Inception",
          name: "inceptionBilling",
          icon: menuIcons.inception,
          action: () => this.showInceptionWizard()
        },
        {
          title: "Termination",
          name: "terminationBilling",
          icon: menuIcons.termination,
          action: () => this.showTerminationWizard()
        },
        {
          title: "Download Portal",
          name: "downloadPortal",
          icon: menuIcons.downloadPortal,
          action: () => this.showDownloadPortal()
        },
      ];
      return menu;
    },
    mainMenuListBottom() {
      let menu = [
        {
          title: "Profile",
          name: "profile",
          icon: menuIcons.profile,
          action: () => this.showAccount()
        },
        {
          title: "User's Guide",
          name: "usersGuide",
          icon: menuIcons.usersGuide,
          action: () => this.showUserGuide()
        }
      ];
      return menu;
    },
    menuActive: {
      get() {
        return this.mainMenuActive;
      },
      set(newName) {
        this.setMainMenuActive(newName);
        return newName;
      }
    }
  },
  methods: {
    ...mapMutations("appNavigation", ["setMainMenuActive"]),
    ...mapActions("inceptions", ["showInceptionWizard"]),
    ...mapActions("terminations", ["showTerminationWizard"]),

    showFeeAssignment() {
      if (this.$route.matched[0].name !== "FeeAssignment") {
        this.$router.push({ name: "FeeAssignment" });
        amplitude.getInstance().logEvent("Tab Opened [Fee Assignment]");
      }
      this.closeMenu();
    },
    showFeeResults() {
      if (
        this.$route.matched[0].name !== "FeeResults" &&
        this.$route.matched[0].name !== "AccountStatuses"
      ) {
        this.$router.push({ name: "FeeResults" });
        amplitude.getInstance().logEvent("Tab Opened [Fee Review]");
      }
      this.closeMenu();
    },
    showDownloadPortal() {
      if (this.$route.matched[0].name !== "DownloadPortal") {
        this.$router.push({ name: "DownloadPortal" });
        amplitude.getInstance().logEvent("Tab Opened [Download Portal]");
      }
      this.closeMenu();
    },
    showAccount() {
      if (this.$route.matched[0].name !== "Profile") {
        this.$router.push({ name: "Profile" });
        amplitude.getInstance().logEvent("Tab Opened [Account]");
      }
      this.closeMenu();
    },
    showUserGuide() {
      const s3 = new aws.S3();
      const params = { Bucket: this.bucket, Key: this.key };
      const URL = s3.getSignedUrl("getObject", params);
      const link = document.createElement("a");
      link.href = URL;
      link.target = "_blank";
      link.click();
      amplitude.getInstance().logEvent("App Menu [Show File]", {
           "File": this.key
      });
    },
    closeMenu() {
      this.setMainMenuActive(false);
    }
  },
  mounted() {
    aws.config.setPromisesDependency();
    aws.config.update({
      accessKeyId: process.env.VUE_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.VUE_APP_SECRET_ACCESS_KEY,
      region: process.env.VUE_APP_REGION,
    });
  }
};
</script>
<style>
.parentList {
  position: relative;
}
.list {
  position: absolute;
  width: 100%;
  bottom: 65px;
  border-top: 2px solid #ffffff;
}
</style>
