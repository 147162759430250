import cookies from "vue-cookies";

function initialState() {
  return {
    invoiceItems: [],
    totalInvoiceItems: 0,
    selectedInvoices: [],
    pagination: {
      page: 1,
      itemsPerPage: 25
    }
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setInvoiceItems(state, value) {
      state.invoiceItems = value;
    },
    setTotalInvoiceItems(state, value) {
      state.totalInvoiceItems = value;
    },
    setSelectedInvoices(state, value) {
      state.selectedInvoices = value;
    },
    setPagination(state, value) {
      state.pagination = value;
    },
    setPage(state, value) {
      let temp = state.pagination;
      temp.page = value;
      state.pagination = temp;
    },
    setPageSize(state, value) {
      let temp = state.pagination;
      temp.itemsPerPage = value;
      state.pagination = temp;
    },
    resetInvoicePagination(state) {
      state.pagination = initialState().pagination;
    },
    resetPage(state) {
      let temp = state.pagination;
      temp.page = initialState().pagination.page;
      state.pagination = temp;
    },
    resetPageSize(state) {
      let temp = state.pagination;
      temp.itemsPerPage = initialState().pagination.itemsPerPage;
      state.pagination = temp;
    }
  },
  actions: {
    async getInvoices({ commit, rootGetters, rootState, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL +
        VERSION +
        "/BillingStatus/GetAccountInvoiceLineItemsDetailedView?";

      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              page: payload.page,
              pageSize: payload.pageSize,
              accountId: rootState.accountStatus.selectedLineItems[0].sourceAccountId
            }),
          {
            headers: {
              Authorization: TOKEN
            }
          }
        );

        let result = await response.json();

        commit("setInvoiceItems", result.resultSet);
        commit("setTotalInvoiceItems", result.total);
      } catch (error) {
        commit("accountStatus/setStatusesError", true, { root: true });
        console.log(error);
      }

      dispatch("loading/endLoading", null, { root: true });
      finished = true;
    },
  }
};
export default module;
