function initialState() {
  return {
    unsavedInputValue: false,
    unsavedInputValueDialog: false,
    nextRoute: "",
    nextHierarchyTag: "",
    nextDirectoryNavigation: null,
    resetFormValue: false
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setUnsavedInputValue(state, value) {
      state.unsavedInputValue = value;
    },
    setUnsavedInputValueDialog(state, value) {
      state.unsavedInputValueDialog = value;
    },
    setNextRoute(state, value) {
      state.nextRoute = value;
    },
    setNextHierarchyTag(state, value) {
      state.nextHierarchyTag = value;
    },
    setNextDirectoryNavigation(state, value) {
      state.nextDirectoryNavigation = value;
    },
    setResetFormValue(state, value) {
      state.resetFormValue = value;
    }
  },
  actions: {}
};
export default module;
