import cookies from "vue-cookies";

function initialState() {
  return {
    securityEntities: [],
    abortController: null,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setSecurityEntities(state, entities) {
      state.securityEntities = entities;
    },
    setAbortController(state, value) {
      state.abortController = value;
    },
    cancelSearch(state) {
      if (state.abortController) {
        state.abortController.abort();
      }
    },
  },
  actions: {
    async findBillingSecurityEntity({ state, rootGetters, commit, dispatch }, payload) {
      commit("cancelSearch");
      commit("setAbortController", new AbortController());

      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/FindBillingSecurityEntity";

      try {
        const signal = state.abortController.signal;
        let response = await fetch(URL, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "POST",
          body: JSON.stringify({
            SecurityEntityList: payload.ids.length > 0 ? payload.ids : null,
            cusip: payload.cusip != "" ? payload.cusip : null,
            ticker: payload.ticker != "" ? payload.ticker : null,
            securityName:
              payload.securityName != "" ? payload.securityName : null
          }),
          signal: signal
        });

        if (response.ok) {
          let result = await response.json();
          commit("setSecurityEntities", result);
        }
      } catch (err) {
        console.log("Failed to execute findBillingSecurityEntity " + err);
      }
    }
  }
};
export default module;
