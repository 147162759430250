<template>
  <div class="complete-banner-style">
    <div class="complete-banner-icon">
      <v-icon class="pr-3" style="color: #006B5B;">mdi-tray-arrow-down</v-icon>
      <p class="mb-0 complete-banner-text">
        Daily Import for {{ completedDailyImportDate() }} complete at
        {{ completedDailyImportTime() }}
      </p>
    </div>
    <v-icon class="pr-1" style="color: #00000099" @click="close"
      >mdi-close</v-icon
    >
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { formatUtcDateTimeToLocalDate, formatUtcDateTimeToLocalTime } from "@/utils/format-util.js";

export default {
  computed: {
    ...mapState("backgroundMessages", ["latestActivity"])
  },
  methods: {
    ...mapMutations("backgroundMessages", ["setInfoBanner"]),
    close() {
      this.setInfoBanner(false);
    },
    completedDailyImportDate() {
      if (this.latestActivity === null) {
        return "";
      } else {
        return formatUtcDateTimeToLocalDate(this.latestActivity.finishedAt);
      }
    },
    completedDailyImportTime() {
      if (this.latestActivity === null) {
        return "";
      } else {
        return formatUtcDateTimeToLocalTime(this.latestActivity.finishedAt);
      }
    },
  }
}
</script>
<style>
.complete-banner-icon {
  display: flex;
  align-items: center;
}
.complete-banner-text {
  font-size: 14px;
  color: #006B5B;
}
.complete-banner-style {
  padding: 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
