const module = {
  namespaced: true,
  state: () => ({
    primaryColor: "teal",
    colorTheme: {
      alert: "white",
      autocomplete: "teal",
      button: "teal",
      buttonText: "teal",
      buttonWhite: "white",
      card: "teal",
      cardWhite: "white",
      comboBox: "teal",
      datePicker: "teal",
      dropDown: "teal lighten-2",
      dropDownItem: "teal darken-2",
      error: "red",
      icon: "teal lighten-1",
      info: "teal",
      menuBackground: "teal",
      menuLetters: "white",
      progress: "teal",
      progressWhite: "white",
      stepper: "teal",
      success: "teal",
      switch: "teal",
      tabGroup: "teal",
      tabGroupBackground: "transparent",
      tabItem: "grey lighten-4",
      textColor: {
        color: "teal"
      },
      textField: "teal",
      timeline: "teal",
      timelineLighten: "teal lighten-3",
      toggle: "teal darken-2",
      warning: "orange darken-3",
      warningTextColor: {
        color: "orange"
      },
    },
    colorThemeAll: {
      alert: "white",
      autocomplete: "blue darken-3",
      button: "blue darken-3",
      buttonText: "blue darken-3",
      buttonWhite: "white",
      card: "blue darken-3",
      cardWhite: "white",
      comboBox: "blue darken-3",
      datePicker: "blue darken-3",
      dropDown: "blue darken-2",
      dropDownItem: "blue darken-2",
      error: "red",
      icon: "blue darken-2",
      info: "blue darken-3",
      menuBackground: "blue darken-3",
      menuLetters: "white",
      progress: "blue darken-3",
      progressWhite: "white",
      stepper: "blue darken-3",
      success: "blue darken-3",
      switch: "blue darken-3",
      tabGroup: "blue darken-3",
      tabGroupBackground: "transparent",
      tabItem: "grey lighten-4",
      textColor: {
        color: "#1565c0"
      },
      textField: "blue darken-3",
      timeline: "blue darken-3",
      timelineLighten: "blue darken-2",
      toggle: "blue darken-3",
      warning: "orange darken-3",
      warningTextColor: {
        color: "orange"
      },
    },
    calcStatusColors: {
      Active: "#c0c300",
      Completed: "green",
      CompletedWithErrors: "orange",
      Failed: "red"
    },
    exportColors: {
      CreatingFile: "#c0c300",
      UploadingFile: "#c0c300",
      UpdatingInvoices: "#c0c300",
      Completed: "green",
      Failed: "red"
    },
    statusColors: {
      initial: "blue",
      update: "green",
      delete: "black",
      transition: "green",
    },
    rowsPerPage: [5, 20, 25, 100, 500],
    minRowsPerPage: 25,
  }),
  mutations: {
    setPrimaryColor(state, newColor) {
      state.primaryColor = newColor;

      state.colorTheme.autocomplete = newColor;
      state.colorTheme.button = newColor;
      state.colorTheme.card = newColor;
      state.colorTheme.comboBox = newColor;
      state.colorTheme.datePicker = newColor;
      state.colorTheme.dropDown = `${newColor} lighten-2`;
      state.colorTheme.dropDownItem = `${newColor} darken-2`;
      state.colorTheme.icon = `${newColor} lighten-1`;
      state.colorTheme.progress = newColor;
      state.colorTheme.stepper = newColor;
      state.colorTheme.textField = newColor;
      state.colorTheme.toggle = `${newColor} darken-2`;
      state.colorTheme.tabGroup = newColor;
      state.colorTheme.timeline = newColor;
      state.colorTheme.timelineLighten = `${newColor} lighten-3`;
      state.colorTheme.menuBackground = newColor;
    }
  },
  actions: {}
};

export default module;
