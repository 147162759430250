function initialState() {
  return {
    inceptionAccountsForCalculation: [],
    inceptionCalculationResults: [],
    inceptionAccountsForReRun: [],
    totalInceptionCalculationResults: 0,
    defaultTimeout: 25000,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setInceptionAccountsForCalculation(state, value) {
      state.inceptionAccountsForCalculation = value;
    },
    setInceptionCalculationResults(state, value) {
      value.forEach(element => {
        element.runTypeKey = element.accountId + element.billingRunType;
      });
      state.inceptionCalculationResults = value;
    },
    setInceptionAccountsForReRun(state, value) {
      state.inceptionAccountsForReRun = value;
    },
    setTotalInceptionCalculationResults(state, value) {
      state.totalInceptionCalculationResults = value;
    },
  },
  actions: {
    async reRunInception({ commit, dispatch, state }, payload) {
      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: payload.accounts,
          processType: "InceptionStartOver",
          page: payload.page,
          pageSize: payload.pageSize,
          timeout: state.defaultTimeout
          // Add "ALL" + filterOptions if it is implemented in InceptionFilteredAccounts.vue
        },
        { root: true }
      );

      if (result.responseData) {
        let newResults = [...state.inceptionCalculationResults];
        result.responseData.forEach(item => {
          newResults.splice(
            newResults.findIndex(e => e.accountId === item.accountId),
            1,
            item
          );
        });
        commit("setInceptionCalculationResults", newResults);
        commit("setTotalInceptionCalculationResults", newResults.length);
      } else {
        await dispatch(
          "billingRecordsCalculations/getLatestCalculationStatus",
          null,
          { root: true }
        );
      }
      return result;
    },
    async getInceptionAccountRecords({ commit, dispatch, state }, payload) {
      let account = {
        id: payload.modifiedRecord.accountId,
        billingRunType: payload.modifiedRecord.billingRunType
      };

      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: [account],
          processType: "InceptionResume",
          page: 1,
          pageSize: 1,
        },
        { root: true }
      );

      if (result.responseData) {
        let newResults = [...state.inceptionCalculationResults];
        result.responseData.forEach(item => {
          newResults.splice(
            newResults.findIndex(e => e.accountId === item.accountId),
            1,
            item
          );
        });
        commit("setInceptionCalculationResults", newResults);
        return true;
      } else {
        return false;
      }
    },
    async createAndViewInceptionBillingRecords({ commit, dispatch, state }, payload) {
      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: state.inceptionAccountsForCalculation,
          processType: "InceptionResume",
          page: payload.page,
          pageSize: payload.pageSize,
          timeout: state.defaultTimeout
          // Add "ALL" + filterOptions if it is implemented in InceptionFilteredAccounts.vue
        },
        { root: true }
      );

      if (result.responseData) {
        commit("setInceptionCalculationResults", result.responseData);
        commit("setTotalInceptionCalculationResults", result.metaData.totalRecordCount);
      } else {
        commit("setInceptionCalculationResults", []);
        commit("setTotalInceptionCalculationResults", 0);
      }
      return result;
    },
    async getAllInceptionBillingRecordsPaged({ dispatch }, payload) {
      const result = await dispatch(
        "billingRecordsCalculations/getBillingRecordsByBillingRunType",
        {
          accounts: payload.accounts,
          processType: "InceptionResume",
          page: payload.page,
          pageSize: payload.pageSize
        },
        { root: true }
      );

      return result;
    },
  }
};

export default module;
