import cookies from "vue-cookies";

function initialState() {
  return {
    modifiedEntity: null,
    parentEntity: null
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setModifiedEntity(state, modifiedEntity) {
      state.modifiedEntity = modifiedEntity;
    },
    setParentEntity(state, parent) {
      state.parentEntity = parent;
    }
  },
  actions: {
    async refreshEntities({ state, commit, dispatch }, payload) {
      commit("modifyEntityGroup/setGroupModified", false, { root: true });
      if (payload.id === "root") {
        let rootId = await dispatch("browseEntities/getRootEntityId", null, {
          root: true
        });
        await dispatch("loadData", rootId);
      } else {
        await dispatch("loadData", payload.id);
      }
      if (state.modifiedEntity && state.modifiedEntity.type == "ACCOUNT_GROUP") {
        await dispatch("modifyEntityGroup/loadAccounts", state.modifiedEntity, {
          root: true
        });
      }
    },
    async loadData({ dispatch, commit }, id) {
      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);
      commit("entityAttributes/setDefaultBillingType", null, { root: true });
      try {
        await dispatch("getEntity", id);
        await dispatch("getParentEntity", id);
        await dispatch("entityAttributes/getAllowedValuesForAttributes", null, {
          root: true
        });
        await dispatch("entityAttributes/getBillingEntityAttributes", id, {
          root: true
        });
      } catch (err) {
        console.log(err);
      }

      dispatch("loading/endLoading", null, { root: true });
      finished = true;
    },
    async getParentEntity({ commit, dispatch }, id) {
      let parent = await dispatch("browseEntities/getEntityParent", id, {
        root: true
      });
      if (parent) {
        commit("setParentEntity", parent);
      }
    },
    async getEntity({ commit, rootGetters, dispatch }, id) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/billingEntity/" + id;

      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      if (id !== null && id !== "" && id !== undefined) {
        let response = await fetch(URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN
          }
        });
        let result = await response.json();
        commit("setModifiedEntity", result);
      }

      dispatch("loading/endLoading", null, { root: true });
      finished = true;
    },
    async updateEntity({ dispatch, rootGetters, state }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL =
        BASE_URL + VERSION + "/Entity/billingEntity/" + payload.id + "/update?";

      try {
        let response = await fetch(URL, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "PUT",
          body: JSON.stringify({
            name: payload.name,
            type: payload.type,
            xref: payload.xref,
            isNew: payload.isNew,
            children: payload.accounts
          })
        });
        if (response.ok) {
          await dispatch("refreshEntities", state.modifiedEntity);
          return await response.json();
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }
  }
};
export default module;
