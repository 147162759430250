const menuIcons = {
  feeAssignment: `<svg width="25" height="25" viewBox="0 0 25 25" fill="white" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_7346_13738" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="25">
    <rect x="0.408203" y="0.538818" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_7346_13738)">
    <path d="M5.7159 21.0388C5.21077 21.0388 4.7832 20.8638 4.4332 20.5138C4.0832 20.1638 3.9082 19.7362 3.9082 19.2311V5.84652C3.9082 5.34139 4.0832 4.91382 
    4.4332 4.56382C4.7832 4.21382 5.21077 4.03882 5.7159 4.03882H11.2159V5.53879H5.7159C5.63897 5.53879 5.56844 5.57084 5.50433 5.63494C5.44023 5.69906 5.40818 
    5.76959 5.40818 5.84652V19.2311C5.40818 19.308 5.44023 19.3785 5.50433 19.4426C5.56844 19.5067 5.63897 19.5388 5.7159 19.5388H19.1005C19.1774 19.5388 19.2479 
    19.5067 19.312 19.4426C19.3761 19.3785 19.4082 19.308 19.4082 19.2311V13.7311H20.9082V19.2311C20.9082 19.7362 20.7332 20.1638 20.3832 20.5138C20.0332 20.8638 
    19.6056 21.0388 19.1005 21.0388H5.7159ZM16.4082 11.5388V8.53879H13.4082V7.03882H16.4082V4.03882H17.9082V7.03882H20.9082V8.53879H17.9082V11.5388H16.4082Z" fill="#FFFFFF" fill-opacity="1"/>
    </g>
    </svg>`,
  feeReview: `<svg width="25" height="24" viewBox="0 0 25 24" fill="white" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_7345_13703" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
    <rect x="0.704102" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_7345_13703)">
    <path d="M5.70408 3.99998V10.1788V10.1538V20V3.99998ZM7.9541 13.75H11.5022C11.6265 13.4628 11.7714 13.1958 11.9368 12.949C12.1022 12.7022 12.2881 12.4692 12.4945 
    12.25H7.9541V13.75ZM7.9541 17.75H10.9849C10.9272 17.5 10.889 17.25 10.8704 17C10.8518 16.75 10.8528 16.5 10.8733 16.25H7.9541V17.75ZM6.0118 21.5C5.50667 21.5 
    5.0791 21.325 4.7291 20.975C4.3791 20.625 4.2041 20.1974 4.2041 19.6923V4.3077C4.2041 3.80257 4.3791 3.375 4.7291 3.025C5.0791 2.675 5.50667 2.5 6.0118 
    2.5H13.9541L19.2041 7.74995V10.5096C18.9643 10.4147 18.7194 10.3426 18.4695 10.2933C18.2195 10.2439 17.9643 10.2058 17.7041 10.1788V8.49995H13.2041V3.99998H6.0118C5.93487 
    3.99998 5.86434 4.03203 5.80023 4.09613C5.73613 4.16024 5.70408 4.23077 5.70408 4.3077V19.6923C5.70408 19.7692 5.73613 19.8397 5.80023 19.9038C5.86434 19.9679 
    5.93487 20 6.0118 20H11.9118C12.1079 20.2974 12.3278 20.5721 12.5714 20.824C12.815 21.0759 13.0791 21.3012 13.3637 21.5H6.0118ZM17.2041 19.1538C17.949 19.1538 
    18.5775 18.8977 19.0897 18.3856C19.6018 17.8734 19.8579 17.2449 19.8579 16.5C19.8579 15.7551 19.6018 15.1266 19.0897 14.6144C18.5775 14.1022 17.949 13.8461 17.2041 
    13.8461C16.4592 13.8461 15.8307 14.1022 15.3185 14.6144C14.8063 15.1266 14.5502 15.7551 14.5502 16.5C14.5502 17.2449 14.8063 17.8734 15.3185 18.3856C15.8307 18.8977 
    16.4592 19.1538 17.2041 19.1538ZM22.3041 22.6442L19.5656 19.9057C19.2284 20.1519 18.8589 20.3381 18.457 20.4644C18.055 20.5907 17.6374 20.6538 17.2041 20.6538C16.0502 
    20.6538 15.0695 20.25 14.2618 19.4423C13.4541 18.6346 13.0503 17.6538 13.0503 16.5C13.0503 15.3461 13.4541 14.3654 14.2618 13.5577C15.0695 12.75 16.0502 12.3462 17.2041 
    12.3462C18.3579 12.3462 19.3387 12.75 20.1464 13.5577C20.9541 14.3654 21.3579 15.3461 21.3579 16.5C21.3579 16.9333 21.2948 17.3509 21.1685 17.7529C21.0422 18.1548 20.856 
    18.5243 20.6098 18.8615L23.3483 21.6L22.3041 22.6442Z" fill="#FFFFFF" fill-opacity="1"/>
    </g>
    </svg>`,
  inception: `<svg width="24" height="24" viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_7344_13381" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_7344_13381)">
    <path d="M8.00005 15.7116C9.02072 15.7116 9.89447 15.3481 10.6213 14.6213C11.3481 13.8945 11.7116 13.0207 11.7116 12C11.7116 10.9794 11.3481 10.1056 10.6213 9.3788C9.89447 
    8.65195 9.02072 8.28852 8.00005 8.28852C6.97938 8.28852 6.10563 8.65195 5.3788 9.3788C4.65195 10.1056 4.28852 10.9794 4.28852 12C4.28852 13.0207 4.65195 13.8945 5.3788 
    14.6213C6.10563 15.3481 6.97938 15.7116 8.00005 15.7116ZM8.00005 17.2115C6.55242 17.2115 5.32192 16.7047 4.30857 15.6912C3.29524 14.6776 2.78857 13.4469 2.78857 
    11.9989C2.78857 10.551 3.29524 9.32059 4.30857 8.30777C5.32192 7.29497 6.55242 6.78857 8.00005 6.78857C9.32697 6.78857 10.4741 7.21389 11.4413 8.06452C12.4087 
    8.91516 12.9801 9.97701 13.1558 11.2501H21.4615V12.75H13.1558C12.9801 14.0231 12.4087 15.0849 11.4413 15.9356C10.4741 16.7862 9.32697 17.2115 8.00005 17.2115Z" fill="#FFFFFF" fill-opacity="1"/>
    </g>
    </svg>`,
  termination: `<svg width="24" height="24" viewBox="0 0 24 24" fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_7336_13021" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_7336_13021)">
    <path d="M19.048 19.1173L16.923 21.2269L15.8692 20.1731L17.9789 18.0481L15.8692 15.9231L16.923 14.8692L19.048 16.9789L21.173 14.8692L22.2269 15.9231L20.1172 18.0481L22.2269 
    20.1731L21.173 21.2269L19.048 19.1173ZM5.99997 21.5C5.30128 21.5 4.70993 21.258 4.22595 20.774C3.74198 20.29 3.5 19.6987 3.5 19V16.1154H6.5V2.5H20.5V12.3365C20.2602 12.2583
     20.0153 12.2013 19.7654 12.1654C19.5154 12.1295 19.2602 12.1115 19 12.1115V3.99998H7.99997V16.1154H13.4365C13.3519 16.3551 13.2852 16.5984 13.2365 16.8452C13.1878 17.092
      13.157 17.3487 13.1442 17.6153H4.99997V19C4.99997 19.2833 5.09581 19.5208 5.28747 19.7125C5.47914 19.9041 5.71664 20 5.99997 20H13.4365C13.5314 20.2871 13.6516 20.5541 
      13.7971 20.8009C13.9426 21.0477 14.107 21.2807 14.2903 21.5H5.99997ZM9.1923 8.69225V7.1923H17.8077V8.69225H9.1923ZM9.1923 11.5769V10.0769H17.8077V11.5769H9.1923ZM13.4365 
      20H4.99997H13.1442H13.123H13.4365Z" fill="#FFFFFF" fill-opacity="1"/>
    </g>
    </svg>`,
  downloadPortal:`<svg color="white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_7335_12711" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
    <rect width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_7335_12711)">
    <path d="M12 15.7884L7.7308 11.5193L8.78462 10.4347L11.25 12.9V4.5H12.7499V12.9L15.2153 10.4347L16.2692 11.5193L12 15.7884ZM6.3077 19.5C5.80257 19.5 5.375 19.325 5.025 
    18.975C4.675 18.625 4.5 18.1974 4.5 17.6923V14.9808H5.99997V17.6923C5.99997 17.7692 6.03202 17.8397 6.09612 17.9038C6.16024 17.9679 6.23077 18 6.3077 18H17.6922C17.7692 
    18 17.8397 17.9679 17.9038 17.9038C17.9679 17.8397 18 17.7692 18 17.6923V14.9808H19.5V17.6923C19.5 18.1974 19.325 18.625 18.975 18.975C18.625 19.325 18.1974 19.5 17.6922 
    19.5H6.3077Z" fill="#FFFFFF" fill-opacity="1"/>
    </g>
    </svg>
    `,
  profile:`<svg width="24" height="25" viewBox="0 0 24 25" fill="FFFFFF" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1488_49276" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
    <rect y="0.989502" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_1488_49276)">
    <path d="M5.21053 18.0684C6.10526 17.4018 7.07895 16.8755 8.13158 16.4895C9.18421 16.1035 10.307 15.9106 11.5 15.9106C12.693 15.9106 13.8158 16.1035 14.8684 16.4895C15.9211 
    16.8755 16.8947 17.4018 17.7895 18.0684C18.4386 17.3492 18.9561 16.5158 19.3421 15.5684C19.7281 14.6211 19.9211 13.5948 19.9211 12.4895C19.9211 10.1562 19.1011 8.16915 
    17.4611 6.52845C15.8204 4.88845 13.8333 4.06845 11.5 4.06845C9.16667 4.06845 7.18 4.88845 5.54 6.52845C3.8993 8.16915 3.07895 10.1562 3.07895 12.4895C3.07895 13.5948 
    3.27193 14.6211 3.6579 15.5684C4.04386 16.5158 4.5614 17.3492 5.21053 18.0684ZM11.5 13.279C10.5351 13.279 9.72386 12.9499 9.06632 12.2916C8.40807 11.6341 8.07895 10.8228 
    8.07895 9.85792C8.07895 8.89301 8.40807 8.08178 9.06632 7.42424C9.72386 6.76599 10.5351 6.43687 11.5 6.43687C12.4649 6.43687 13.2761 6.76599 13.9337 7.42424C14.5919 8.08178 
    14.9211 8.89301 14.9211 9.85792C14.9211 10.8228 14.5919 11.6341 13.9337 12.2916C13.2761 12.9499 12.4649 13.279 11.5 13.279ZM11.5 22.4895C10.114 22.4895 8.81158 22.2263
    7.59263 21.7C6.37298 21.1737 5.31579 20.4632 4.42105 19.5685C3.52632 18.6737 2.81579 17.6165 2.28947 16.3969C1.76316 15.1779 1.5 13.8755 1.5 12.4895C1.5 11.1035 1.76316
    9.80073 2.28947 8.58108C2.81579 7.36213 3.52632 6.30529 4.42105 5.41055C5.31579 4.51582 6.37298 3.80529 7.59263 3.27898C8.81158 2.75266 10.114 2.4895 11.5 2.4895C12.886 
    2.4895 14.1888 2.75266 15.4084 3.27898C16.6274 3.80529 17.6842 4.51582 18.5789 5.41055C19.4737 6.30529 20.1842 7.36213 20.7105 8.58108C21.2368 9.80073 21.5 11.1035 21.5 
    12.4895C21.5 13.8755 21.2368 15.1779 20.7105 16.3969C20.1842 17.6165 19.4737 18.6737 18.5789 19.5685C17.6842 20.4632 16.6274 21.1737 15.4084 21.7C14.1888 22.2263 12.886 
    22.4895 11.5 22.4895ZM11.5 20.9106C12.4474 20.9106 13.3642 20.7569 14.2505 20.4495C15.1361 20.1428 15.9211 19.7176 16.6053 19.1737C15.9211 18.6474 15.1446 18.2351 14.2758 
    17.9369C13.4077 17.6386 12.4825 17.4895 11.5 17.4895C10.5175 17.4895 9.59228 17.6344 8.72421 17.9242C7.85544 18.2134 7.07895 18.6299 6.39474 19.1737C7.07895 19.7176 7.86386 
    20.1428 8.74947 20.4495C9.63579 20.7569 10.5526 20.9106 11.5 20.9106ZM11.5 11.7C12.0263 11.7 12.4649 11.5246 12.8158 11.1737C13.1667 10.8228 13.3421 10.3842 13.3421 
    9.85792C13.3421 9.33161 13.1667 8.89301 12.8158 8.54213C12.4649 8.19126 12.0263 8.01582 11.5 8.01582C10.9737 8.01582 10.5351 8.19126 10.1842 8.54213C9.83333 8.89301 
    9.6579 9.33161 9.6579 9.85792C9.6579 10.3842 9.83333 10.8228 10.1842 11.1737C10.5351 11.5246 10.9737 11.7 11.5 11.7Z" fill="#FFFFFF" fill-opacity="1"/>
    </g>
    </svg>`,
  usersGuide:`<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_2460_69944" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
    <rect y="0.841797" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_2460_69944)">
    <path d="M13.95 10.6668V9.34178C14.4833 9.09178 15.05 8.90412 15.65 8.77878C16.25 8.65412 16.8667 8.59178 17.5 8.59178C17.9 8.59178 18.2877 8.62078 18.663 8.67878C19.0377 
    8.73745 19.4167 8.80845 19.8 8.89178V10.1668C19.4333 10.0335 19.0627 9.93745 18.688 9.87878C18.3127 9.82078 17.9167 9.79178 17.5 9.79178C16.8667 9.79178 16.25 9.86679 15.65 
    10.0168C15.05 10.1668 14.4833 10.3835 13.95 10.6668ZM13.95 16.1418V14.8168C14.4833 14.5668 15.05 14.3751 15.65 14.2418C16.25 14.1085 16.8667 14.0418 17.5 14.0418C17.9 
    14.0418 18.2877 14.0708 18.663 14.1288C19.0377 14.1875 19.4167 14.2668 19.8 14.3668V15.6168C19.4333 15.4835 19.0627 15.3875 18.688 15.3288C18.3127 15.2708 17.9167 
    15.2418 17.5 15.2418C16.8667 15.2418 16.25 15.3168 15.65 15.4668C15.05 15.6168 14.4833 15.8418 13.95 16.1418ZM13.95 13.4168V12.0668C14.4833 11.8168 15.05 11.6291 15.65 
    11.5038C16.25 11.3791 16.8667 11.3168 17.5 11.3168C17.9 11.3168 18.2877 11.3458 18.663 11.4038C19.0377 11.4625 19.4167 11.5418 19.8 11.6418V12.8918C19.4333 12.7585 19.0627 
    12.6628 18.688 12.6048C18.3127 12.5461 17.9167 12.5168 17.5 12.5168C16.8667 12.5168 16.25 12.5958 15.65 12.7538C15.05 12.9125 14.4833 13.1335 13.95 13.4168ZM6.5 16.9418C7.33333 16.9418 8.14167 17.0335 8.925 17.2168C9.70833 17.4001 10.4833 17.6918 11.25 18.0918V8.26678C10.55 7.81678 9.79167 7.47512 8.975 7.24178C8.15833 7.00845 7.33333 6.89178 6.5 6.89178C5.9 6.89178 5.33733 6.94178 4.812 7.04178C4.28733 7.14178 3.75 7.29178 3.2 7.49178C3.13333 7.52512 3.08333 7.56678 3.05 7.61678C3.01667 7.66678 3 7.71678 3 7.76678V17.2168C3 17.3168 3.03333 17.3875 3.1 17.4288C3.16667 17.4708 3.23333 17.4751 3.3 17.4418C3.78333 17.2751 4.28733 17.1501 4.812 17.0668C5.33733 16.9835 5.9 16.9418 6.5 16.9418ZM12.75 18.0918C13.5167 17.6918 14.2917 17.4001 15.075 17.2168C15.8583 17.0335 16.6667 16.9418 17.5 16.9418C18.1 16.9418 18.6627 16.9835 19.188 17.0668C19.7127 17.1501 20.2167 17.2751 20.7 17.4418C20.7667 17.4751 20.8333 17.4708 20.9 17.4288C20.9667 17.3875 21 17.3168 21 17.2168V7.76678C21 7.71678 20.9833 7.66678 20.95 7.61678C20.9167 7.56678 20.8667 7.52512 20.8 7.49178C20.25 7.29178 19.7127 7.14178 19.188 7.04178C18.6627 6.94178 18.1 6.89178 17.5 6.89178C16.6667 6.89178 15.8417 7.00845 15.025 7.24178C14.2083 7.47512 13.45 7.81678 12.75 8.26678V18.0918ZM12 20.2918C11.2 19.7085 10.3333 19.2541 9.4 18.9288C8.46667 
    18.6041 7.5 18.4418 6.5 18.4418C5.88333 18.4418 5.28333 18.5085 4.7 18.6418C4.11667 18.7751 3.55 18.9751 3 19.2418C2.65 19.4085 2.31267 19.3835 1.988 19.1668C1.66267 
    18.9501 1.5 18.6418 1.5 18.2418V7.39178C1.5 7.17512 1.55433 6.97078 1.663 6.77878C1.771 6.58745 1.93333 6.45012 2.15 6.36678C2.81667 6.03345 3.521 5.78745 4.263 
    5.62878C5.00433 5.47078 5.75 5.39178 6.5 5.39178C7.46667 5.39178 8.41667 5.52512 9.35 5.79178C10.2833 6.05845 11.1667 6.45012 12 6.96678C12.8333 6.45012 13.7167 
    6.05845 14.65 5.79178C15.5833 5.52512 16.5333 5.39178 17.5 5.39178C18.25 5.39178 18.996 5.47078 19.738 5.62878C20.4793 5.78745 21.1833 6.03345 21.85 6.36678C22.0667 
    6.45012 22.229 6.58745 22.337 6.77878C22.4457 6.97078 22.5 7.17512 22.5 7.39178V18.2418C22.5 18.6418 22.3333 18.9461 22 19.1548C21.6667 19.3628 21.3167 19.3835 20.95 
    19.2168C20.4 18.9668 19.8377 18.7751 19.263 18.6418C18.6877 18.5085 18.1 18.4418 17.5 18.4418C16.5 18.4418 15.5333 18.6041 14.6 18.9288C13.6667 19.2541 12.8 19.7085 12 
    20.2918Z" fill="#FFFFFF" fill-opacity="1"/>
    </g>
    </svg>`
};

export default menuIcons;
