import cookies from "vue-cookies";
import { equalObjects } from "@/utils/object-operations.js";

function initialState() {
  return {
    groupModified: false,
    groupAccounts: [],
    lastCall: null,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setGroupModified(state, groupModified) {
      state.groupModified = groupModified;
    },
    setGroupAccounts(state, value) {
      state.groupAccounts = value;
    },
    setLastCall(state, value) {
      state.lastCall = value;
    }
  },
  actions: {
    async setGroupAccountsAsync({ state, commit }, { callParams, accounts }) {
      if (!callParams || equalObjects(state.lastCall, callParams)) {
        commit("setGroupAccounts", accounts);
      }
    },
    async loadAccounts({ commit, dispatch, rootState, rootGetters }, payload) {
      commit("setLastCall", payload);
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/GetEntityChildren?";

      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      try {
        let id;
        if (payload && payload.id) {
          id = payload.id;
        } else {
          id = await dispatch("directoryNavigation/getSelectionId", null, {
            root: true
          });
        }
        let response;
        response = await fetch(
          URL +
            new URLSearchParams({
              entity: id,
              relationshipType: rootState.hierarchyMode.hierarchyTag.val
            }),
          {
            headers: {
              Authorization: TOKEN
            }
          }
        );

        let result = await response.json();
        let accounts = result.children.filter(a => a.type === "ACCOUNT");
        let newAccounts = accounts.map((a) =>
          Object.assign({}, { name: a.name, id: a.id })
        );
        await dispatch("setGroupAccountsAsync", {
          callParams: payload,
          accounts: newAccounts
        });
      } catch (err) {
        console.log("error: " + err);
      }
      dispatch("loading/endLoading", null, { root: true });
      finished = true;
    },
    async insertEntity({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/billingEntity?";

      try {
        let response = await fetch(URL, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "POST",
          body: JSON.stringify({
            parentId: payload.parentId,
            name: payload.name,
            type: payload.type,
            xref: payload.xref,
            isNew: payload.isNew,
            children: payload.accounts
          })
        });
        if (response.ok) {
          console.log("Inserted account group " + payload.name);
        }
      } catch (err) {
        console.log("Error while inserting of account group. " + err);
      }
    },
    async deleteGroup({ rootGetters, dispatch }, id) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Entity/billingEntity/" + id;
      try {
        let response = await fetch(URL, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "DELETE"
        });
        if (response.ok) {
          let result = await response.json();
          return result;
        } else {
          return false;
        }
      } catch (err) {
        console.log(err);
        return false;
      }
    }
  }
};

export default module;
