import cookies from "vue-cookies";

function initialState() {
  return {
    selectedExclusion: null,
    pagination: {
      page: 1,
      itemsPerPage: 25,
      sortBy: [],
      sortDesc: []
    },
    resetExclusions: false,
    lastCall: null,
  };
}
const module = {
  namespaced: true,
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },

    setSelectedExclusion(state, selectedExclusion) {
      state.selectedExclusion = selectedExclusion;
    },
    setResetExclusions(state, value) {
      state.resetExclusions = value;
    },
    setPagination(state, value) {
      state.pagination = value;
    },
    setPage(state, value) {
      let temp = state.pagination;
      temp.page = value;
      state.pagination = temp;
    },
    setPageSize(state, value) {
      let temp = state.pagination;
      temp.itemsPerPage = value;
      state.pagination = temp;
    },
    resetPagination(state) {
      state.pagination = initialState().pagination;
    },
    resetPage(state) {
      let temp = state.pagination;
      temp.page = initialState().pagination.page;
      state.pagination = temp;
    },
    resetPageSize(state) {
      let temp = state.pagination;
      temp.itemsPerPage = initialState().pagination.itemsPerPage;
      state.pagination = temp;
    },
    setLastCall(state, value) {
      state.lastCall = value;
    },
  },
  actions: {
    async loadExclusions({ state, commit, dispatch }, payload) {
      commit("setLastCall", payload);
      try {
        let id = await dispatch("directoryNavigation/getSelectionId", null, {
          root: true
        });
        let parameters = {
          entity: id,
          searchKeyword: payload.searchKeyword,
          sortKey: payload.sortKey,
          sortDirection: payload.sortDirection,
          page: payload.page,
          pageSize: payload.pageSize
        };
        let result = await dispatch("getExclusions", parameters);
        if (result !== "ERROR") {
          if (payload == state.lastCall) {
            return result;
          }
        } else {
          return "ERROR";
        }
      } catch (err) {
        console.log(err);
        return "ERROR";
      }
    },
    async addExclusion({ rootGetters, rootState, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/AddBillingExclusion?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              userName: payload.userName
            }),
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "POST",
            body: JSON.stringify({
              billingSecurityEntityId: payload.billingSecurityEntityId,
              exclusionPercentage:
                payload.exclusionPercentage != null
                  ? Number.parseFloat(payload.exclusionPercentage) / 100
                  : null,
              exclusionAmount:
                payload.exclusionAmount != null
                  ? Number.parseFloat(payload.exclusionAmount)
                  : null,
              expirationDate: payload.expirationDate,
              billingEntityId: rootState.feeSchedule.billingEntity.id,
              billingFeeTypes: payload.billingFeeTypes
            })
          }
        );

        if (response.ok) {
          console.log("Exclusion inserted");
          return true;
        } else {
          console.log("Failed to insert exclusion");
          return false;
        }
      } catch (err) {
        console.log("Failed to insert exclusion " + err);
        return false;
      }
    },
    async updateExclusion({ rootGetters, rootState, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/ModifyBillingExclusion?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              id: payload.id,
              userName: payload.userName
            }),
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "PUT",
            body: JSON.stringify({
              billingSecurityEntityId: payload.billingSecurityEntityId,
              exclusionPercentage:
                payload.exclusionPercentage != null
                  ? Number.parseFloat(payload.exclusionPercentage) / 100
                  : null,
              exclusionAmount:
                payload.exclusionAmount != null
                  ? Number.parseFloat(payload.exclusionAmount)
                  : null,
              expirationDate: payload.expirationDate,
              billingEntityId: rootState.feeSchedule.billingEntity.id,
              billingFeeType: payload.billingFeeType
            })
          }
        );

        if (response.ok) {
          console.log("Exclusion updated");
          return true;
        } else {
          console.log("Failed to update exclusion");
          return false;
        }
      } catch (err) {
        console.log("Failed to update exclusion " + err);
        return false;
      }
    },
    async deleteExclusion({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/DeleteBillingExclusion?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              id: payload.id
            }),
          {
            headers: {
              Authorization: TOKEN
            },
            method: "DELETE"
          }
        );
        if (response.ok) {
          console.log("Exclusion deleted");
        } else {
          console.log("Failed to delete exclusion");
        }
      } catch (err) {
        console.log("Failed to delete exclusion " + err);
      }
    }, /*
    async findBillingExclusions({ commit, rootGetters, rootState }, payload) {
      var finished = false;
      commit("loading/incrementNumberOfLoadings", null, { root: true });
      setTimeout(() => {
        if (!finished) {
          commit("loading/setElementsLoading", true, { root: true });
        }
      }, 1000);

      try {
        const BASE_URL = rootGetters["endpoints/BaseUrl"];
        const VERSION = rootGetters["endpoints/ApiVersion"];
        const URL = BASE_URL + VERSION + "/Fee/FindEffectiveExclusion?";
        let response = await fetch(
          url +
            new URLSearchParams({
              entity: payload,
              completeList: true
            }),
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "GET"
          }
        );

        if (response.ok) {
          let result = await response.json();
          const urlSecurityEntity =
            BASE_URL + VERSION + "/Fee/FindBillingSecurityEntity";
          let entityIds = result.map((x) => x.billingSecurityEntityId);
          const responseSecurityEntity = await fetch(urlSecurityEntity, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "POST",
            body: JSON.stringify({
              SecurityEntityList: entityIds,
              cusip: payload.cusip,
              ticker: payload.ticker,
              securityName: payload.securityName
            })
          });

          if (responseSecurityEntity.ok) {
            let securityData = await responseSecurityEntity.json();
            let newExclusions = [];
            securityData.forEach(security => {
              let newExclusionResult = result.filter(
                e => e.billingSecurityEntityId == security.id
              );

              newExclusionResult.forEach(newExclusion => {
                if (newExclusions.filter(ne => ne.id == newExclusion.id).length == 0) {
                  newExclusion.securityName = security.securityName;
                  newExclusion.cusip = security.cusip;
                  newExclusion.ticker = security.ticker;
                  newExclusions.push(newExclusion);
                }
              });
            });
            let sortableExclusions = [];
            newExclusions.forEach(exclusion => {
              let newExclusion = exclusion;
              if (exclusion.entity1 === null || exclusion.entity1 === undefined) {
                newExclusion.inheritedFromName = "";
                newExclusion.inheritedFromId = null;
              } else if (exclusion.entity1.id == payload.id) {
                newExclusion.inheritedFromName = "";
                newExclusion.inheritedFromId = exclusion.entity1.id;
              } else {
                newExclusion.inheritedFromName = exclusion.entity1.name;
                newExclusion.inheritedFromId = exclusion.entity1.id;
              }
              sortableExclusions.push(newExclusion);
            });
            commit("setExclusions", sortableExclusions);
          }
        } else {
          console.log("Failed to execute findBillingExclusion");
        }
      } catch (err) {
        console.log("Failed to find exclusion " + err);
      }

      dispatch("loading/endLoading", null, { root: true });
      finished = true;
    },*/
    /* async findBillingExclusionsPaged({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/FindEffectiveExclusionPaged?";

      try {
        let response = await fetch(
          URL +
            new URLSearchParams({
              entity: payload.entity,
              completeList: true,
              page: payload.page,
              pageSize: payload.pageSize
            }),
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "GET"
          }
        );

        if (response.ok) {
          let result = await response.json();
          let exclusionsList = result.data.responseData;
          const urlSecurityEntity =
            BASE_URL + VERSION + "/Fee/FindBillingSecurityEntity";
          let entityIds = exclusionsList.map((x) => x.billingSecurityEntityId);
          const responseSecurityEntity = await fetch(urlSecurityEntity, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "POST",
            body: JSON.stringify({
              SecurityEntityList: entityIds,
              cusip: payload.cusip,
              ticker: payload.ticker,
              securityName: payload.securityName
            })
          });

          if (responseSecurityEntity.ok) {
            let securityData = await responseSecurityEntity.json();

            exclusionsList.forEach(exclusion => {
              securityData.forEach(security => {
                if (exclusion.billingSecurityEntityId == security.id) {
                  exclusion.securityName = security.securityName;
                  exclusion.cusip = security.cusip;
                  exclusion.ticker = security.ticker;
                }
              });
            });
          }
          let resultToReturn = {
            exclusions: exclusionsList,
            numberOfExclusions: result.data.metaData.totalRecordCount
          };
          return resultToReturn;
        } else {
          console.log("Failed to execute findBillingExclusion");
          return "ERROR";
        }
      } catch (err) {
        console.log("Failed to find exclusion " + err);
        return "ERROR";
      }
    }, */
    async getExclusions({ rootGetters, dispatch }, payload) {
      const TOKEN = cookies.get("token");
      if (!TOKEN) {
        await dispatch("auth/clearAll", null, { root: true });
        return;
      }
      const BASE_URL = rootGetters["endpoints/BaseUrl"];
      const VERSION = rootGetters["endpoints/ApiVersion"];
      const URL = BASE_URL + VERSION + "/Fee/QuickSearchEffectiveExclusion?";

      try {
        let urlParams = {
          entity: payload.entity,
          completeList: true,
          searchKeyword: payload.searchKeyword,
          sortDirection: payload.sortDirection,
          page: payload.page,
          pageSize: payload.pageSize
        };
        if (payload.sortKey !== null) {
          urlParams = { ...urlParams, sortKey: payload.sortKey };
        }
        let response = await fetch(URL + new URLSearchParams(urlParams), {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: TOKEN
          },
          method: "GET"
        });

        if (response.ok) {
          let result = await response.json();
          let exclusionsList = result.data.responseData;
          const urlSecurityEntity =
            BASE_URL + VERSION + "/Fee/FindBillingSecurityEntity";
          let entityIds = exclusionsList.map((x) => x.billingSecurityEntityId);
          const responseSecurityEntity = await fetch(urlSecurityEntity, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: TOKEN
            },
            method: "POST",
            body: JSON.stringify({
              SecurityEntityList: entityIds,
              cusip: payload.cusip,
              ticker: payload.ticker,
              securityName: payload.securityName
            })
          });

          if (responseSecurityEntity.ok) {
            let securityData = await responseSecurityEntity.json();

            exclusionsList.forEach(exclusion => {
              securityData.forEach(security => {
                if (exclusion.billingSecurityEntityId == security.id) {
                  exclusion.securityName = security.securityName;
                  exclusion.cusip = security.cusip;
                  exclusion.ticker = security.ticker;
                }
              });
            });
          }
          let resultToReturn = {
            exclusions: exclusionsList,
            numberOfExclusions: result.data.metaData.totalRecordCount
          };
          return resultToReturn;
        } else {
          console.log("Failed to execute findBillingExclusion");
          return "ERROR";
        }
      } catch (err) {
        console.log("Failed to find exclusion " + err);
        return "ERROR";
      }
    },
  }
};
export default module;
